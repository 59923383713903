<!-- <app-loader></app-loader> -->
<div class="loader-outermain">
    <div class="loadermain"></div>
</div>

<div [className]="currentTheme">
    <div class="mat-app-background">
        <router-outlet></router-outlet>

        <!-- <div class="loading-indicator" *ngIf="showLoader==true">
            <div class="center">
                <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
                </mat-progress-spinner>
            </div>
        </div> -->
    </div>
</div>

