import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AboutApiService } from '../../http/about-api.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-edit-header-pop',
  templateUrl: './edit-header-pop.component.html',
  styleUrls: ['./edit-header-pop.component.scss']
})
export class EditHeaderPopComponent implements OnInit {

  header = "";
  themeValue;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private apiService: AboutApiService,
  public dialogRef: MatDialogRef<EditHeaderPopComponent>,
  private loader: LoaderService,
  private dataShare:DataShareService) {

}

  ngOnInit(): void {
    this.header = this.data.data.heading;

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });
  }
  validate(){
   if(this.header==""){
     return true;
   }else{
    return false;
   }
  }
  restore(){
    this.header = this.data.data.heading_v2;
    this.Save();
  }

  Save() {
    this.loader.show();
    let obj

      obj = {
        "action": "update",
        "placeholder_name": "about_us_feature",
        "title": "Why should you collaborate with us?",
        "heading": this.header,
        "body": "",
        "order": 1,
        "wwid": this.data.wwid,
        "id":this.data.data.id
      }
 
    this.apiService.getAboutCMS(obj).subscribe(res => {
      console.log(res);
      this.dialogRef.close({ status: "success", message: "Updated Successfully." });
    }, error => {
      this.dialogRef.close({ status: "error", message: error.message });
    })
  }


}
