<div class="container-fluid container-register">

    <div class="row">

        <div class="col-3">
            &nbsp;
        </div>

        <div class="col-6" style="padding: 0px;">
            <div class="card register-card">

                <div class="card-header text-center">
                    <span>
                        <img src="../../../../assets/images/cummins-logo.png" class="logo-header">
                    </span>
                    <span class="semibold larger-font">
                        &nbsp;
                        {{'appName' | transloco}}
                    </span>
                </div>

                <!--Registration form-->
                <div class="card-body" *ngIf="displayRegister">
                    <br>
                    <h2 class="larger-font text-center semibold">
                        {{'register.register' | transloco}}
                    </h2>
                    <br>
                    <div class="row">
                        <div class="col-8 offset-2">
                            <mat-form-field appearance="outline" style="width: 100%;" class="dis-bold">
                                <mat-label>{{ 'register.wwid' | transloco}}
                                </mat-label>
                                <input matInput [disabled]=true value="{{userDetails['tokenUserInfo']['nickname']}}">
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 100%;" class="dis-bold">
                                <mat-label>{{ 'register.first' | transloco}}
                                </mat-label>
                                <input matInput [disabled]=true [value]="userDetails['tokenUserInfo']['givenName']">
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 100%;" class="dis-bold">
                                <mat-label>{{ 'register.last' | transloco}}
                                </mat-label>
                                <input matInput [disabled]=true [value]="userDetails['tokenUserInfo']['familyName']">
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 100%;" class="dis-bold">
                                <mat-label>{{ 'register.email' | transloco}}
                                </mat-label>
                                <input matInput [disabled]=true [value]="userDetails['tokenUserInfo']['email']">
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>{{ 'register.role' | transloco}}</mat-label>
                                <mat-select name="role" (selectionChange)="toClear()" [(value)]="selectedRoleId" [disabled]="true">
                                    <mat-option *ngFor="let role of roles" [value]="role.role_id">
                                        {{role.role_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!--<mat-form-field *ngIf="selectedRole==2" appearance="outline" style="width: 100%;">
                                <mat-label>{{ 'register.bu' | transloco}}</mat-label>
                                <mat-select name="bu" [(ngModel)]="selectedSingleBu">
                                    <mat-option *ngFor="let unit of bu" [value]="unit.group_id">
                                        {{unit.group_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>-->

                            <mat-form-field *ngIf="selectedRole!=2" appearance="outline" style="width: 100%;">
                                <mat-label>{{ 'register.bu' | transloco}}</mat-label>
                                <mat-select multiple name="bu" [(ngModel)]="selectedMultipleBu">
                                    <mat-option *ngFor="let unit of bu" [value]="unit.group_id">
                                        {{unit.group_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Enter the Business Justification</mat-label>
                                <textarea matInput [(ngModel)]="enteredJustification" required rows="3" cols="59"
                                style="resize: none;" maxlength="150"></textarea>
                            </mat-form-field>

                            <button class="reg-btn btn" (click)="register()" [disabled]="formCheck()">
                                {{ 'register.submit' | transloco}}
                            </button>
                            <br><br>

                            <button class="text-center reg-large-font mont-med borderClass-reg btn"
                                (click)="goToHome()">
                                {{ 'register.continue2' | transloco}}
                            </button>
                            <br><br>
                            <div *ngIf="errorFlag" style="color: #D1312B; font-size: 16px;">
                                {{ 'register.failed' | transloco}}
                            </div>

                            <div class="text-center">
                            <div style="color: #D1312B; font-size: 15px;" class="mont-bold">
                                {{ 'register.limited' | transloco}}
                            </div>
                        </div>
                        </div>
                    </div>

                </div>

                <!--If registartion was succesful-->
                <div class="card-body" *ngIf="successFlag">
                    <div class="row">
                        <div class="col-8 offset-2">
                            <br><br><br>

                            <div class="text-center">
                                <svg-icon [svgStyle]="{ 'width.px':120}" class="over-svg"
                                    src="assets/images/approved-svg.svg">
                                </svg-icon>

                                <br><br>
                                <h2 class="success-msg">
                                    {{ 'register.success' | transloco}}
                                </h2>
                            </div>

                            <button class="text-center reg-large-font mont-med borderClass-reg btn"
                                (click)="goToHome()">
                                {{ 'register.continue' | transloco}}
                            </button>
                            <br><br>
                        </div>
                    </div>
                </div>

                <!--pending status-->
                <div class="card-body" *ngIf="currentStatus==3">
                    <div class="row">
                        <div class="col-8 offset-2">
                            <br><br><br>

                            <div class="text-center">
                                <svg-icon [svgStyle]="{ 'width.px':120}" class="over-svg"
                                    src="assets/images/pending-svg.svg">
                                </svg-icon>

                                <br><br>
                                <h2 class="success-msg">
                                    {{ 'register.pending' | transloco}}
                                </h2>
                            </div>

                            <button class="text-center reg-large-font mont-med borderClass-reg btn"
                                (click)="goToHome()">
                                {{ 'register.continue' | transloco}}
                            </button>
                            <br><br>
                        </div>
                    </div>
                </div>

                <!--deactivated status-->
                <div class="card-body" *ngIf="currentStatus==2">
                    <div class="row">
                        <div class="col-8 offset-2">
                            <br><br><br>

                            <div class="text-center">
                                <svg-icon [svgStyle]="{ 'width.px':120}" class="over-svg"
                                    src="assets/images/deactivate-svg.svg">
                                </svg-icon>

                                <br><br>
                                <h2 class="success-msg">
                                    {{ 'register.deactivated' | transloco}}
                                </h2>
                            </div>

                            <button class="text-center reg-large-font mont-med borderClass-reg btn"
                                (click)="goToHome()">
                                {{ 'register.continue' | transloco}}
                            </button>
                            <br><br>
                        </div>
                    </div>
                </div>

                <!--user is activated-->
                <div class="card-body" *ngIf="currentStatus==1">
                    <div class="row">
                        <div class="col-8 offset-2">
                            <br><br><br>

                            <div class="text-center">
                                <svg-icon [svgStyle]="{ 'width.px':240}" class="over-svg"
                                    src="assets/images/registered.svg">
                                </svg-icon>

                                <br><br>
                                <h2 class="success-msg">
                                    {{ 'register.registered' | transloco}}
                                </h2>
                            </div>

                            <button class="text-center reg-large-font mont-med borderClass-reg btn"
                                (click)="goToHome()">
                                {{ 'register.continue' | transloco}}
                            </button>
                            <br><br>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="col-3">
            &nbsp;
        </div>

    </div>

</div>