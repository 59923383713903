import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {
  mainCaptcha:any;
  mainCaptchaWithoutspace:any;
  txtInput:any = "";
  @Output() onValidCaptcha = new EventEmitter<any>();
  constructor(private dataShare: DataShareService) { }

  ngOnInit(): void {
    this.Captcha();
    this.dataShare.getCaptchaRefresh().subscribe(res=>{
      if(res == 'true')
      {
        this.Captcha();
      }
    })
  }

   Captcha(){
    var alpha = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z', 
        '0','1','2','3','4','5','6','7','8','9');
    var i;
    for (i=0;i<6;i++){
        var a = alpha[Math.floor(Math.random() * alpha.length)];
        var b = alpha[Math.floor(Math.random() * alpha.length)];
        var c = alpha[Math.floor(Math.random() * alpha.length)];
        var d = alpha[Math.floor(Math.random() * alpha.length)];
        var e = alpha[Math.floor(Math.random() * alpha.length)];
        var f = alpha[Math.floor(Math.random() * alpha.length)];
        //var g = alpha[Math.floor(Math.random() * alpha.length)];
                     }
        var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' '+ f; //+ ' ' + g;
        this.mainCaptcha = code
        this.mainCaptchaWithoutspace = a+b+c+d+e+f;//+g;
        this.txtInput = "";
      }

 ValidCaptcha(){

    if (this.mainCaptchaWithoutspace == this.txtInput){
            //console.log("true")
        this.onValidCaptcha.emit("true");
    }else{        
      this.onValidCaptcha.emit("false");
         }
}


}
