<div class="cls-settings {{themeValue}}">

    <div class="breadcrumb-list">
        <a class="breadcrumb-item" [routerLink]="['/web/home']">Home</a>
        <div class="breadcrumb-connector">></div>
        <div class="breadcrumb-last">Preference Settings</div>
    </div>

    <br><br>

    <span class="settingsHeader">
        {{ 'preferences.title' | transloco}}
    </span>

    <div class="card card-settings">

        <div class="row" *ngIf="(displayEmail && !externalUser)">
            <div class="col-10">
                <p class="mont-semi heading">
                    {{ 'preferences.emailTitle' | transloco}}
                </p>
                <span>
                    {{ 'preferences.emailSubtitle' | transloco}}
                </span>
            </div>

            <div class="col-2" style="transform: translateY(33%);" *ngIf="mainEmailControl.show">
                <span>
                    <span style="float: right;">
                        <mat-slide-toggle [checked]="mainEmailControl.value" (change)="toggleAllEmail()">
                        </mat-slide-toggle>
                    </span>
                </span>
            </div>
        </div>

        <!-- <hr class="hr-class" *ngIf="!(roleList.length == 1 && (roleList[0] == 2 || roleList[0] == 3)) && !externalUser"> -->
        
        <hr class="hr-class" *ngIf="(displayEmail && mainEmailControl.show && !externalUser)">
        
        <ng-container *ngIf="(displayEmail && mainEmailControl.show && !externalUser)">
            <ng-container *ngFor="let pref of preferenceList">
                <ng-container *ngIf="pref.type=='Email'">
                    <div class="row" *ngIf="pref.show==true">
                        <div class="col-8">
                            <p class="mont-medium subHeading">{{pref.name}}</p>
                        </div>
                        <div class="col-4">
                            <span style="float: right;">
                                <mat-slide-toggle [checked]="pref.value" (change)="toggleValue(pref.name)">
                                </mat-slide-toggle>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>


        <!-- <hr class="settings-hr" *ngIf="!(roleList.length == 1 && (roleList[0] == 2 || roleList[0] == 3))"> -->
        <hr class="hr-class" *ngIf="(displayEmail && mainEmailControl.show && !externalUser)">
        <span>
            <p class="mont-semi heading padding-top">
                {{ 'preferences.appearanceTitle' | transloco}}
            </p>
            <span>
                {{ 'preferences.appearanceSubtitle' | transloco}}
            </span>
            <hr class="hr-class">

            <div *ngFor="let pref of preferenceList">
                <div *ngIf="pref.type=='Appearance' && pref.name!='Default Theme'" class="row">
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12 app-titles">
                        <p class="mont-medium subHeading">{{pref.name}}</p>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <!-- <div *ngIf="pref.name=='Default Theme'" class="app-settings">
                            <svg-icon src="assets/images/light_theme.svg" class="selected" *ngIf="!pref.value"
                                [svgStyle]="{ 'width.px':24}">
                            </svg-icon>
                            <svg-icon src="assets/images/light_theme.svg" class="deselected" *ngIf="pref.value"
                                [svgStyle]="{ 'width.px':24}">
                            </svg-icon>

                            <span class="slide-class">
                                <mat-slide-toggle disabled [(ngModel)]="pref.value" (change)="setTheme()"
                                    class="toggle">
                                </mat-slide-toggle>
                            </span>

                            <svg-icon src="assets/images/dark_theme.svg" class="deselected" *ngIf="!pref.value"
                                [svgStyle]="{ 'width.px':24}">
                            </svg-icon>
                            <svg-icon src="assets/images/dark_theme.svg" class="selected" *ngIf="pref.value"
                                [svgStyle]="{ 'width.px':24}">
                            </svg-icon>
                        </div> -->

                        <div *ngIf="pref.name=='Default API View'" class="app-settings">
                            <span class="label view-deselected cursorClass" *ngIf="pref.value == 'List'"
                                (click)="changeView('Grid')">
                                <svg-icon [svgStyle]="{ 'width.px':36, 'fill':'#a9a9a9'}" src="assets/images/grid.svg">
                                </svg-icon>
                            </span>

                            <span class="label view-selected cursorClass" *ngIf="pref.value == 'Grid'"
                                (click)="changeView('Grid')">
                                <svg-icon [svgStyle]="{ 'width.px':36, 'fill':'#ffffff'}" src="assets/images/grid.svg">
                                </svg-icon>
                            </span>
                            &nbsp;&nbsp;

                            <span class="label view-deselected cursorClass" *ngIf="pref.value == 'Grid'"
                                (click)="changeView('List')">
                                <svg-icon [svgStyle]="{ 'width.px':36, 'fill':'#a9a9a9'}" src="assets/images/list.svg">
                                </svg-icon>
                            </span>

                            <span class="label view-selected cursorClass" *ngIf="pref.value == 'List'"
                                (click)="changeView('List')">
                                <svg-icon [svgStyle]="{ 'width.px':36, 'fill':'#ffffff'}" src="assets/images/list.svg">
                                </svg-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="settings-hr">
            
            <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12 app-titles">
                            <p class="mont-medium subHeading">Default Theme</p>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div class="switch-toggle switch-3 switch-candy">
                                        
                                        <input id="on" name="state-d" type="radio" checked="{{(themeValuedefpre=='light-theme')?'checked':''}}" />
                                        <label for="on" class="lt1" (click)="themeSwitch('light-theme')">Light</label>
                                      
                                        <input id="na" name="state-d" type="radio" checked="{{(themeValuedefpre=='default-theme')?'checked':''}}" />
                                        <label for="na" class="lt2" (click)="themeSwitch('default-theme')">Default</label>
                                      
                                        <input id="off" name="state-d" type="radio" checked="{{(themeValuedefpre=='dark-theme')?'checked':''}}" />
                                        <label for="off" class="lt3" (click)="themeSwitch('dark-theme')">Dark</label>
                                      
                                      </div>
                        </div>
            </div>
           
            <hr class="settings-hr">
        </span>
    </div>
</div>