import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, param: string, screen: any): any {
    
    if(!param || param == "")
      return value;
    
    if(screen == "faq"){
      return value.filter(s => s.heading.toLowerCase().includes(param.toLowerCase()) 
      || s.body.toLowerCase().includes(param.toLowerCase()) );
    }
  }

}
