<div class="upper-footer {{themeValue}}">
    <div class="custom-container">
<div class="row" style="width: 100%; margin: 0px">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
        <div class="logoName">
            <span>
                <img src="assets/images/{{(themeValue=='dark-theme')?'cummins-logo.png':'cummins-logo-light.png'}}" alt="cummins-logo" class="logo-header">
            </span>
            <span  *ngIf= "!isSupplierPortal" class="app-title">API Portal</span>
            <span  *ngIf= "isSupplierPortal" class="app-title">Supplier EDI Onboarding</span>
        </div>

        <br>
        <span *ngIf= "!isSupplierPortal" > <strong>Power</strong> your business applications with Cummins APIs </span>
        <span *ngIf= "isSupplierPortal" > An application to onboard Supplier for EDI Invoicing </span>

        <br>
    </div>

    <!-- <div class="col-xl-1 col-lg-1 col-md-1 col-sm-0 col-0"></div> -->

    <div *ngIf= "!isSupplierPortal" class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
        <div class="app-title">Quick Links:</div>
        <br>

        <div class="links">
            <a *ngIf="islogin && !externalUser" href="https://cummins365.sharepoint.com/sites/CS17106/SitePages/Governance-Standards.aspx" target="_blank" >API Guidelines and Standards</a>
            <a [routerLink]="['/web/api-usecases']">API Use Cases</a>
            <a [routerLink]="['/web/getting-started']">Getting Started</a>
            <a [routerLink]="['/web/support/faq']"  [queryParams]="{scto:'faq-containerid'}" >FAQs</a>
        </div>
    </div>
</div>
</div>
</div>

<footer class="footer-style">
    <div class="custom-container">
<div class="row" style="width: 100%; margin: 0px">
    <div class="footer-left col-lg-6 col-md-6 col-sm-12 col-12">
        <p>© {{currentYear}} Cummins Inc. |
            <a href="https://gdc.cummins.com" target="_blank" class="leftlink"> Powered by Cummins GDC </a>
        </p>
    </div>
    <div class="footer-right col-lg-6 col-md-6 col-sm-12 col-12">
        <a href="https://www.cummins.com/privacy-and-legal" target="_blank">PRIVACY POLICY</a> |
        <a href="https://www.cummins.com/privacy-and-legal" target="_blank"> TERMS & CONDITIONS</a>
    </div>
</div>
</div>
</footer>