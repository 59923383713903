import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  themeValue;
  constructor(private dataShare:DataShareService) { }

  ngOnInit(): void {
    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });
  }
 
}
