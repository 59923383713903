import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataShareService } from '../../../../core/services/data-share.service';
import { LoginService } from '../../../../account/http/login.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NotificationComponent implements OnInit {

  isLoggedIn: boolean;
  notiList = [];
  readList = [];
  unreadList = [];
  themeValue;
  @Output() notificationEvent = new EventEmitter<string>(); 
  showEmptyState = false;
  opts = { screen: "notification" };
  @Input() notifications 

  constructor(private dialog: MatDialog, private serv: ApiInformationService,
    private login: LoginService, private dataShare: DataShareService, private loader: LoaderService) { }
  isAnimate: true;
  user;

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });
    //get logged in user details
    this.isLoggedIn = this.login.isAuthenticated();
    this.user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    this.initNotifications();
    //this.getNotifications();
    this.dataShare.getNotificationUpdate().subscribe(res => {      
      //this.getNotifications();
    })
  }

  getNotifications() {
    console.log("notification call")
    if (this.isLoggedIn) {
    
      //get notifications from backend
      this.showEmptyState = true;

      let reqObj = {
        action: "get_list",
        email_id: this.user.tokenUserInfo.email//"",
      }

      this.serv.getNotifications(reqObj).subscribe(res => {
        let now = new Date();
        if (res.message.includes("success") && res.notification_list.length > 0) {
          this.notiList = res.notification_list;

          this.notiList.forEach((notif) => {
            let p = new Date(notif.created_date);

            //calculate notification created time based on today's date and time
            let diff = (Math.abs((now.getTime() - p.getTime()) / (60 * 1000)));

            if (diff <= 60) {
              if (Math.floor(diff) == 1)
                notif.time_ago = Math.floor(diff) + " min"
              else
                notif.time_ago = Math.floor(diff) + " mins"
            }
            else if (diff > 60) {
              diff = diff / 60;
              notif.time_ago = Math.floor(diff) + " hours"

              if (diff > 24) {
                diff = diff / 24;
                notif.time_ago = Math.floor(diff) + " days"

                if (diff > 30) {
                  diff = diff / 30;
                  notif.time_ago = Math.floor(diff) + " month(s)"
                }
              }

            }
          });

          this.readList = []; this.unreadList = [];
          
          //separate notifications in read and unread lists
          this.notiList.forEach(notif => {
            if (notif.mark_read == 0)
              this.unreadList.push(notif)
            else if (notif.mark_read == 1)
              this.readList.push(notif);
          });
          this.notificationEvent.emit("true");
        }
      }).add( () => this.showEmptyState = false
      //this.loader.hide()
      );
    }
    //else this.loader.hide();
  }

  initNotifications() {
    //console.log("notification call")
    if (this.isLoggedIn) {
    
      //get notifications from backend
      this.showEmptyState = true;

      let reqObj = {
        action: "get_list",
        email_id: this.user.tokenUserInfo.email//"",
      }

     
        let now = new Date();
        if (this.notifications.length > 0) {
          this.notiList = this.notifications;

          this.notiList.forEach((notif) => {
            let p = new Date(notif.created_date);

            //calculate notification created time based on today's date and time
            let diff = (Math.abs((now.getTime() - p.getTime()) / (60 * 1000)));

            if (diff <= 60) {
              if (Math.floor(diff) == 1)
                notif.time_ago = Math.floor(diff) + " min"
              else
                notif.time_ago = Math.floor(diff) + " mins"
            }
            else if (diff > 60) {
              diff = diff / 60;
              notif.time_ago = Math.floor(diff) + " hours"

              if (diff > 24) {
                diff = diff / 24;
                notif.time_ago = Math.floor(diff) + " days"

                if (diff > 30) {
                  diff = diff / 30;
                  notif.time_ago = Math.floor(diff) + " month(s)"
                }
              }

            }
          });

          this.readList = []; this.unreadList = [];
          
          //separate notifications in read and unread lists
          this.notiList.forEach(notif => {
            if (notif.mark_read == 0)
              this.unreadList.push(notif)
            else if (notif.mark_read == 1)
              this.readList.push(notif);
          });
          this.notificationEvent.emit("true");
        }
        this.showEmptyState = false
    }
    //else this.loader.hide();
  }

  markRead(notifId) {
    let req = {
      id: notifId, action: "mark_read",
      email_id: this.user.tokenUserInfo.email,//"",
      mark_read: true
    }

    //this.loader.show();
    this.serv.getNotifications(req).subscribe(res => {
      this.getNotifications();
    }).add( () => {
      //this.loader.hide();
    });


  }

  markAllRead() {
    let req = {
      action: "mark_all_read",
      email_id: this.user.tokenUserInfo.email//"",
    }

    //this.loader.show();
    this.serv.getNotifications(req).subscribe(res => {
      this.getNotifications();
    }).add( () => {
      //this.loader.hide();
    });
  }

  close() {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialog.closeAll(); }, 1000);
  }
}
