
<div class="newFaqCont {{themeValue}}">

    <div class="disp-title">
      <!-- Are you sure want to delete this Item from FAQs ? -->
      {{confirmmsg}}
    </div>
  
    <br>
  
    <div class="btn-wrapper">
        
      <button *ngIf="!(this.data.type && this.data.type=='alert')" class="btn cumminsWhite" (click)="close()">
        Cancel
      </button>
  
      <button class="btn cumminsRed" (click)="ok()">
        OK
      </button>
    </div>
  
  </div>