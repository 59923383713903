import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-preference-settings',
  templateUrl: './preference-settings.component.html',
  styleUrls: ['./preference-settings.component.scss']
})
export class PreferenceSettingsComponent implements OnInit {

  //list all possible preferences,with initial value true (ON)
  preferenceList = [
    { "type": "Appearance", "name": "Default Theme", "id": "DefaultTheme", "value": false, "show": true },
    { "type": "Appearance", "name": "Default API View", "id": "DefaultAPIView", "value": "Grid", "show": true },
    { "type": "Email", "name": "API Access Request Submission", "value": true, "id": "RequestAPIAccess", "show": false },
    { "type": "Email", "name": "API Access Request Reminder", "value": true, "id": "RequestAPIAccessReminder", "show": false },
    { "type": "Email", "name": "API Access Request Cancellation", "value": true, "id": "CancelAPIAccess", "show": false },
    { "type": "Email", "name": "New User Registration", "value": true, "id": "SubmitUserRegistration", "show": false }
  ]

  user;
  displayEmail;
  roleList;
  themeValuedefpre;
  mainEmailControl = { show: false, value: false }

  constructor(private serv: ApiInformationService, private loader: LoaderService,
    private dataShare:DataShareService) {
  }
  externalUser: boolean = false;
  themeValue;

  ngOnInit() {
    this.dataShare.getTheme().subscribe((res:any)=>{
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    if(this.user.tokenUserInfo['nickname'].includes("@")){
      this.externalUser= true;
    }
    this.roleList = this.user.roleId;

    //hide and show preferences based on user role
    // if (this.roleList.length == 1 && (this.roleList[0] == 2 || this.roleList[0] == 3)) {
    //   this.displayEmail = false;
    //   this.mainEmailControl['show'] = false;
    //   this.preferenceList.forEach(pref => {
    //     if (pref.type == 'Email') pref.show = false;
    //   });
    // }
    // else {
    //   if (this.roleList.length == 1 && this.roleList[0] == 4) this.mainEmailControl['show'] = false;
    //   else if (this.roleList.indexOf(2) > -1 && this.roleList.indexOf(4) > -1) this.mainEmailControl['show'] = false;
    //   else this.mainEmailControl['show'] = true;
    //   this.displayEmail = true;
    //   this.roleList.forEach(role => {
    //     this.preferenceList.forEach(pref => {
    //       if (role == 4 && pref.name == "New User Registration")
    //         pref.show = true;

    //       if (role == 1 && (pref.name == 'API Access Request Submission' || pref.name == 'API Access Request Reminder' || pref.name == 'API Access Request Cancellation'))
    //         pref.show = true;
    //     });
    //   });
    // }
    if(this.roleList.length > 0 && (this.roleList.indexOf(4) > -1 || this.roleList.indexOf(1) > -1)){
      this.displayEmail = true;
      this.mainEmailControl['show'] = true;
      this.roleList.forEach(role => {
            this.preferenceList.forEach(pref => {
              if (role == 4 && pref.name == "New User Registration")
                pref.show = true;
    
              if (role == 1 && (pref.name == 'API Access Request Submission' || pref.name == 'API Access Request Reminder' || pref.name == 'API Access Request Cancellation'))
                pref.show = true;
            });
          });
    }else if(this.roleList.length > 0 && (this.roleList.indexOf(4) == -1 && this.roleList.indexOf(1) == -1)){
      this.displayEmail = false;
      this.mainEmailControl['show'] = false;
    }
    this.getPreferences();
  }

  getPreferences() {
    let obj = { "action": "get_data", "user_id": this.user['tokenUserInfo']['nickname'] }

    this.loader.show();

    //get preference values of logged user from backend
    this.serv.getPreferences(obj).subscribe((res) => {
      this.handleGet(res);
    }).add( () => {
      this.loader.hide();
    });
  }


  handleGet(res) {
    // Iterate on response values
    for (let index = 0; index < this.preferenceList.length; index++) {
      let resElement = this.preferenceList[index];
      //this.preferenceList[index].value = false;

      // Match DB item key with local item key     
      let filterIndex = res['preference_list'].findIndex(s => s.preference_key == resElement.id);

      // If value found 
      if (filterIndex >= 0) {
        let filterItem = res['preference_list'][filterIndex]

        // Set default theme      
        if (filterItem.preference_key == "DefaultTheme") {
          this.preferenceList[index].value = filterItem.preference_value;
          this.themeValuedefpre = filterItem.preference_value;
        }

        // Set Grid View        
        if (filterItem.preference_key == "DefaultAPIView") {
          this.preferenceList[index].value = filterItem.preference_value;
        }

        // Set Email        
        if (filterItem.preference_type == "Email") {
          this.preferenceList[index].value = filterItem.preference_value == "true" ? true : false;
        }
      }
    }

    // Set Main Checkbox    
    // let allEmailFilterList = res['preference_list'].filter(s => s.preference_type == "Email");
    // let checkedEmailFilterList = res['preference_list'].filter(s => s.preference_type == "Email" && s.preference_value == "true");
    // //this.mainEmailControl.value = allEmailFilterList.length == checkedEmailFilterList.length ? true : false;
    // if(allEmailFilterList.length==0) this.mainEmailControl.value = false;
    // else this.mainEmailControl.value = allEmailFilterList.length == checkedEmailFilterList.length ? true : false;
    
    // if (res['preference_list'].length > 0) {
    //   res['preference_list'].forEach(pref => {
    //     alert('Pref key:' + pref.preference_key);
    //     this.preferenceList.forEach(element => {
    //       alert('elment:' + element.id);
    //       if (element.id == pref.preference_key) {
    //         if (element.id == "DefaultTheme") {
    //           if (pref.preference_value == "Light") element.value = false;
    //           else element.value = true;
    //         }
    //         else if (element.type == "Email") {
    //           console.log("email");
    //           console.log(pref.preference_value);
    //           alert('Email settings value:' + pref.preference_value)
    //           element.value = eval(pref.preference_value);
    //         }
    //         else
    //           element.value = pref.preference_value;
    //       }
    //     });
    //   });
    // }

    let flag = 1; let emailList = []
    this.preferenceList.forEach(pref => {
      if (pref.type == 'Email') {
        if (pref.show == true) emailList.push(pref);
      }
    });
    emailList.forEach(pref => {
      if (pref.value == true) flag = 0;
    });

    //show main switch of "ALL Preferences" based on values of preferences visible for that role
    if (flag == 0) this.mainEmailControl.value = true;
    else this.mainEmailControl.value = false;
  }

  updatePreferences(edited) {
    let prefList = [];

    //update in local array
    for (let index = 0; index < this.preferenceList.length; index++) {
      const element = this.preferenceList[index];
      if (edited.indexOf(element.name) > -1) {
        let temp = { id: "", name: "", type: "", value: '' };
        temp.type = element.type;
        temp.name = element.name;
        temp.id = element.id;
        if (element.name == "Default Theme") {
          if (element.value == false) temp.value = "Light";
          else temp.value = "Dark";
        }
        else if (element.type == "Email") { temp.value = String(element.value); }
        else
          temp.value = element.value.toString();
        prefList.push(temp);
      }
    }

    // this.preferenceList.forEach(element => {
    //   if (edited.indexOf(element.name) > -1) {
    //     let temp = { id: "", name: "", type: "", value: "" };
    //     temp.type = element.type;
    //     temp.name = element.name;
    //     temp.id = element.id;
    //     if (element.name == "Default Theme") {
    //       if (element.value == false) temp.value = "Light";
    //       else temp.value = "Dark";
    //     }
    //     else if (element.type == "Email") { temp.value = String(element.value); }
    //     else
    //       temp.value = element.value;
    //     prefList.push(temp);
    //   }
    // });

    // let prefList = [];
    // this.preferenceList.forEach(element => {
    //   if (edited.indexOf(element.name) > -1) {
    //     let temp = { id: "", name: "", type: "", value: "" };
    //     temp.type = element.type;
    //     temp.name = element.name;
    //     temp.id = element.id;
    //     if (element.name == "Default Theme") {
    //       if (element.value == false) temp.value = "Light";
    //       else temp.value = "Dark";
    //     }
    //     else if (element.type == "Email") { temp.value = String(element.value); }
    //     else
    //       temp.value = element.value;
    //     prefList.push(temp);
    //   }
    // });

    //send updated values to backend
    let obj = {
      "wwid": this.user['tokenUserInfo']['nickname'],
      "obj_list": prefList,
      "action": "update",
    }

    //this.loader.show();
    this.serv.getPreferences(obj).subscribe((res) => {
      if (res.message.indexOf("success") > -1)
        this.handleGet(res);
    }).add( () => {
      //this.loader.hide();
    });
  }

  changeView(view) {
    this.preferenceList.forEach(element => {
      if (element.name == 'Default API View') element.value = view;
    });
    this.updatePreferences(["Default API View"]);
  }

  toggleValue(preferenceName) {
    this.preferenceList.forEach(element => {
      if (element.name == preferenceName) {
        if (element.value == true) element.value = false;
        else element.value = true;
      }
    });
    this.updatePreferences([preferenceName]);
  }

  toggleAllEmail() {
    this.mainEmailControl.value = !this.mainEmailControl.value
    let obj = []
    this.preferenceList.forEach(element => {
      if (element.type == 'Email') {
        if (element.show == true) {
          element.value = this.mainEmailControl.value;
          obj.push(element.name);
        }
      }
    });
    this.updatePreferences(obj);
  }

  themeSwitch(val){
       this.callThemeAPI(val);
       this.dataShare.setTheme(val);
  }

  callThemeAPI(val){

    let obj = {
      "wwid": this.user['tokenUserInfo']['nickname'],
      "obj_list": [{ "id": "DefaultTheme", "name": "Default Theme", "type": "Appearance", "value": val }],
      "action": "update",
    }

    //this.loader.show();
    this.serv.getPreferences(obj).subscribe((res) => {
      if (res.message.indexOf("success") > -1)
        this.handleGet(res);
    }).add( () => {
      //this.loader.hide();
    });
  }


}
