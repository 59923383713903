<div class="about-us-wapper {{themeValue}}">
<div class="container-support">
  <div class="custom-container con-pad">
      <div class="row h-in">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="head-title">
                {{'aboutUsDetails.aboutUs' | transloco}}
            </div>
           
            <p class="head-subtitle" *ngIf="contents0"> 
                <span>{{contents0.heading}}</span>
                <span *ngIf="isLoggedIn == true && isAdmin == true" (click)="openHeaderDialog(contents0)"  style="margin: 0px 10px;" class="edit-wrapper2">
                    <div class="cursorClass">
                      <svg-icon  src="assets/images/edit.svg">
                      </svg-icon>
                    </div>
                    <div class="edit-style cursorClass">&nbsp;Edit</div>
                  </span>
            </p>
           
          </div>
        </div>
  </div>
    
  </div>
  
  <div class="custom-container con-pad">
    <span class="label back-to-top-start cursorClass" (click)="backToTop()">
      <svg-icon [svgStyle]="{ 'width.px':36}" src="assets/images/scroll-top.svg">
      </svg-icon>
    </span>
  
    <span class="list-desc">
      <div class="breadcrumb-list">
        <a class="breadcrumb-item" [routerLink]="['/web/home']">Home</a>
        <div class="breadcrumb-connector">></div>
        <div class="breadcrumb-last">About Us</div>
      </div>
    </span>
  
  <br>
    <div class="row pb-3" *ngIf="contents1">
        <div class="col-lg-8">
          <div class="sec1-a-title">
            <span>Get to know us</span>         
            <span *ngIf="isLoggedIn == true && isAdmin == true" (click)="editSection1()"  style="margin: 0px 10px;" class="edit-wrapper2">
              <div class="cursorClass">
                <svg-icon  src="assets/images/edit.svg">
                </svg-icon>
              </div>
              <div class="edit-style cursorClass">&nbsp;Edit</div>
            </span></div>

            <div *ngIf="isEditClicked.sec1" class="edit-card">
                <div *ngIf="this.contents1.body_v2" class="default-cont">
                    <p class="bolderText">Previous Content</p>
                    <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                    <p [innerHTML]="sanitize(decodeHTML(this.contents1.body_v2))"></p>
                  </div>
                <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newData"
                  [style]="{'height':'120px'}">
                  <p-header>
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-size">
                        <option value="small">Small</option>
                        <option value="large">Large</option>
                        <option value="huge">Huge</option>
                      </select>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-color"></select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                      <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                      <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                      <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                      <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                    </span>
                  </p-header>
                </p-editor>
  
                <br><br>
  
                <div class="text-right allbtngrp">
                  <button class="cancel-edit-btn btn" (click)="cancelSection(1)">Cancel</button>
                  <button *ngIf="this.contents1.body_v2" class="cancel-edit-btn btn" (click)="restoreIntro(1)">Restore previous content</button>
                  <button class="access-btn-overview btn" [disabled]="!isEditClicked.newData"
                    (click)="updateSection(1)">Save</button>
                </div>
              </div>

              <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
         
                  <div *ngIf="!isEditClicked.sec1" [innerHTML]="sanitize(decodeHTML(contents1.body))"  >
                    
                  </div>
                </div>
   

        </div>
        <div class="col-lg-4 sec1-imgwap">
           <img class="sec1-sideimg" src="assets/images/Group 116536.png" alt="group">
         </div>
     </div>
    </div>

    <div class="sec2-bg-dark">
     <div class="row custom-container">
       <div class="col-lg-12">
         <div class="row">
           <div class="col-md-6">
               <div class="sec1-a-title">Why should you collaborate with us?</div>
           </div>
           <div class="col-md-6 text-right">
            <button *ngIf="isLoggedIn == true && isAdmin == true" (click)="openAddEditDialogSec2('add','')" class="btn btn-outline-dark" style="margin: 20px 0px;">Add New Feature</button>
         </div>
         </div>
        <div class="row">
           <div class="col-lg-4 sec1-imgwap">
               <img class="sec2-sideimg" src="assets/images/{{(themeValue=='dark-theme')?'sec4bgdark.svg':'Group 116535.png'}}" alt="group">
             </div>
             <div class="col-lg-8">
               <ng-container *ngFor="let data of contents2">
                  <div class="sec2-right-card">
                      <span class="sec2-right-card-count">
                           {{data.order}}.
                      </span>
                      <span class="sec2-desp-wap">
                        <div style="display: flex;"  >
                          <span class="sec2-right-card-title" [innerHTML]="sanitize(decodeHTML(data.heading))"></span>
                         <span *ngIf="isLoggedIn == true && isAdmin == true" (click)="openAddEditDialogSec2('edit',data)"  style="margin: 0px 10px;" class="edit-wrapper1">
                           <div class="cursorClass">
                             <svg-icon  src="assets/images/edit.svg">
                             </svg-icon>
                           </div>
                           <div class="edit-style cursorClass">&nbsp;Edit</div>
                         </span>
                        </div>
                        <p class="normal-desp">
                            {{data.body}}
                        </p>
                        </span>
                    </div>
               </ng-container>
               <ng-container *ngIf="contents2 && contents2.length==0 && !(isLoggedIn == true && isAdmin == true)">
                <div class="nocontent">
                    <img class="img-fluid" width="140" src="assets/images/undraw_updated_re_u4yh.svg" alt="">
                    <div class="nocond1">Coming soon with updates...</div>
                    <div class="nocond2">Be ready to catch up!</div>
                </div>
              </ng-container>
              <ng-container *ngIf="contents2 && contents2.length==0 && isLoggedIn == true && isAdmin == true">
                <div class="row">
                    <div class="col-lg-12">
                <div class="nocontent">
                    <img class="img-fluid" style="width: 140px!important;" src="assets/images/no-data-found.svg" alt="">
                    <div class="nocond1">No Features are available right now.</div>
                    <div class="nocond2">Please click on "Add New Feature" button to add a new Feature.</div>
                </div>
                </div>
              </div>
              </ng-container>
      
               </div>
        </div>
       </div>
     </div>
    </div>
      <div class="row custom-container">
      <div class="col-lg-12 pt-3">
          <div class="row">
          <div class="col-md-6">
          <div class="sec1-a-title">Stay Updated</div>
          </div>
          <div class="col-md-6 text-right">
              <button *ngIf="isLoggedIn == true && isAdmin == true" (click)="openAddEditDialog('add','')" class="btn btn-outline-dark" style="margin: 20px 0px;">Add New Update</button>
           </div>
        </div>
         
          <div>
            <ng-container *ngFor="let data of contents3loop;let i = index;" >
                <div class="row mt-2" *ngIf="!(isReadMore && i==readMoreIndex)">
                    <div class="col-lg-2 col-md-2">
  
                        <img class="sec3-icn-img" src="{{data.file_url?data.file_url:'assets/images/DSC_3325-236x167.jpg'}}" alt="Stay updated">
                        
                    </div>
                    <div class="col-lg-10 col-md-10">
                      <div class="edit-sec3"><span class="sec1-icn-title" style="padding: 6px 0px;">{{data.heading}}</span>
                        <span *ngIf="isLoggedIn == true && isAdmin == true" (click)="openAddEditDialog('edit',data)"  style="margin: 10px 10px;" class="edit-wrapper2">
                            <div class="cursorClass">
                              <svg-icon  src="assets/images/edit.svg">
                              </svg-icon>
                            </div>
                            <div class="edit-style cursorClass">&nbsp;Edit</div>
                          </span>
                        </div>
                      <p class="normal-desp">{{data.updated_date}}</p>
                      <p class="normal-desp updesc" *ngIf="data.body.length>250">
                        {{data.body.slice(0,250)}}..
                      </p>
                      <p class="normal-desp updesc" *ngIf="data.body.length<=250">
                        {{data.body}}
                      </p>
                      <p><span *ngIf="data.body.length>250" (click)="readMore(i)" class="sec3-read">Read More</span></p>
                      </div>
                  </div>
                                      <!--  -->
                <div class="row mt-2" *ngIf="isReadMore && i==readMoreIndex">
                  <div class="col-lg-12">
                      <img class="square img-fluid" src="{{data.file_url?data.file_url:'assets/images/DSC_3325-236x167.jpg'}}" width="100" height="140">
                      <div class="edit-sec3"><span class="sec1-icn-title" style="padding: 6px 0px;">{{data.heading}}</span>
                        <span *ngIf="isLoggedIn == true && isAdmin == true" (click)="openAddEditDialog('edit',data)" style="margin: 10px 10px;" class="edit-wrapper2">
                            <div class="cursorClass">
                              <svg-icon  src="assets/images/edit.svg">
                              </svg-icon>
                            </div>
                            <div class="edit-style cursorClass">&nbsp;Edit</div>
                          </span>
                        </div>
                        <p class="normal-desp">{{data.updated_date}}</p>
                      <p class="normal-desp updesc" >
                        {{data.body}}
                    </p>
                    <p><span (click)="readLess(i)" class="sec3-read rd-less">Read Less</span></p>
                  </div>
                </div>
                <!--  -->
                  <div class="row">
                    <div class="col-lg-12">
                      <hr>
                    </div>
                  </div>
            </ng-container>
            <ng-container *ngIf="contents3loop.length==0 && !(isLoggedIn == true && isAdmin == true)">
              <div class="nocontent">
                  <img class="img-fluid" width="140" src="assets/images/undraw_updated_re_u4yh.svg" alt="">
                  <div class="nocond1">Coming soon with updates...</div>
                  <div class="nocond2">Be ready to catch up!</div>
              </div>
            </ng-container>
            <ng-container *ngIf="contents3loop && contents3loop.length==0 && isLoggedIn == true && isAdmin == true">
              <div class="row">
                  <div class="col-lg-12">
              <div class="nocontent">
                  <img class="img-fluid" style="width: 140px!important;" src="assets/images/no-data-found.svg" alt="">
                  <div class="nocond1">No Updates are available right now.</div>
                  <div class="nocond2">Please click on "Add New Update" button to add a new Update.</div>
              </div>
              </div>
            </div>
            </ng-container>

        

          </div>
          <div class="sec3-btn-bottom mt-2"><button *ngIf="contents3 && contents3.length>3 && !isShowMore" (click)="showMore()" class="btn">Show more ({{contents3.length - 3}})</button></div>
      </div>
      </div>
      <div class="sec4bgd" >
     <div class="row custom-container pt-2 ">
         <div class="col-lg-6 p-4">
                 <img class="img-fluid" src="assets/images/collabrate.svg" alt="collabrate">
         </div>
         <div class="col-lg-6 au-ft-rt">
                 <h2><b>Lets<span class="about-foth"> Collaborate </span> and <span class="about-foth"> Innovate </span>the great things together</b></h2>
                 <a [routerLink]="['/web/getting-started']" class="btn btn-outline-dark about-fbtn" >Getting started</a>
             </div>
     </div>
    </div>
    <br>
  <br><br>
  <div class="custom-container con-pad" #container>
        <!--  -->
        <div #alert *ngIf="successFlag" class="alert alert-over alert-success alert-dismissible fade show"
        style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
        <mat-icon (click)="closeAlert()" class="closeBtn">cancel</mat-icon>
        {{alertMsg}}
        </div>
        <div #alert *ngIf="errorFlag" class="alert alert-over alert-danger alert-dismissible fade show"
        style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
        <mat-icon (click)="closeAlert()" class="closeBtn">cancel</mat-icon>
        {{alertMsg}}
        </div>
        <!--  -->
  </div>
</div>
