export const RoleConstants = {
    admin: 1,
    cumminsUser: 2,
    jointVenture: 3
};

export const DEFAULT_LANG_CODE = 'en';

export const LANG_CODE = 'LANG_CODE';

export enum CMSDataEnum{
    getting_started_intro= "gettingstarted_introduction",
    gettingstarted_steps="gettingstarted_howtouseportal",
    getting_started_use_apis="gettingstarted_howtouseapis",
    home_steps = "home_steps",
    home_banner = 'home_banner',
    home_category = 'home_category',
    get_access = 'apiaccess_processsteps',
    get_owner_access = 'owneraccess_processsteps',
    support_portal_faqs = 'support_portalfaqs',
    support_usage_faqs = 'support_usagefaqs',
    use_cases = 'overview_usecases'
};