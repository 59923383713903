import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CMSDataEnum } from '../constants/Constants';
import { CMSModel } from './cms.model';


@Injectable({
  providedIn: 'root'
})
export class CMSService {

  cmsData = [];
  loaded = false;
  timeVar;

  constructor(private http: HttpClient, private handler: HttpBackend) { }

  public getCms() {
    if (environment.cmsContent == 'drupal') {
      const url = environment.apiHostUrl + "/get-cms-data?action=get_content&rnd=" + Math.random().toString();

      return this.http.post(url, {})
        .toPromise().then((resp) => {
          this.setCMSData(resp);
        });
    }
    else if (environment.cmsContent == 'local') {
      const url = "assets/files/cms-data.json";
      return this.http.get(url)
        .toPromise().then((resp) => {
          this.setCMSData(resp);
        });
    }
    else if (environment.cmsContent == 'db') {
      const url = environment.apiHostUrl + "/get-cms-db-data";
      return this.http.post(url, { action: "get_cms_data" })
        .toPromise().then((resp) => {
          if(resp['cms_data']) this.setCMSData(resp['cms_data']);
        });
    }
  }

  getVirtualAssistanceData(): Observable<any> {
    const url = environment.apiHostUrl + "/get-cms-db-data";
    return this.http.post(url, {action: "get_cms_data",
    screen: "digital_hub" })
        .pipe(
          map(res => res['cms_data']),
          catchError(err => throwError(() => err))
        )
  }

  public setCMSData(data: any) {
    this.cmsData = data;
  }

  public getCMSDataByPlaceholderName(searchKey) {
    return this.cmsData.filter(s => s.placeholder_name == searchKey);
  }

  public getCMSDataByScreenName(searchKey) {
    if (environment.cmsContent == 'drupal') {
      const url = environment.apiHostUrl + "/get-cms-data?action=get_content&rnd=" + Math.random().toString();

      return this.http.post(url, {})
        .toPromise().then((resp) => {
          this.setCMSData(resp);
        });
    }
    else if (environment.cmsContent == 'local') {
      const url = "assets/files/cms-data.json";
      return this.http.get(url)
        .toPromise().then((resp) => {
          this.setCMSData(resp);
        });
    }
    else if (environment.cmsContent == 'db') {
      const url = environment.apiHostUrl + "/get-cms-db-data";
      return this.http.post(url, { action: "get_cms_data", screen: searchKey })
        .toPromise().then((resp) => {
          if(resp['cms_data']) this.setCMSData(resp['cms_data']);
        });
    }
  }

  public cleanHtmlTag(strHtml: string) {
    strHtml = strHtml.replace(/<\/p>/g, "");
    strHtml = strHtml.replace(/<p>/g, "");
    strHtml = strHtml.replace(/&lt;\/p&gt;/g, "");
    strHtml = strHtml.replace(/&lt;p&gt;/g, "");
    strHtml = strHtml.replace(/&lt;/g, "<");
    strHtml = strHtml.replace(/&gt;/g, ">");
    strHtml = strHtml.replace(/\u00a0/g, " ");
    //strHtml = strHtml.replace(/\/n/g, "");
    strHtml = strHtml.replace("<p> </p>", "<br>")
    return strHtml;
  }

  getUseCaseData(title): Observable<any>{
    const url = environment.apiHostUrl + "/get-cms-db-data";
    return this.http.post(url, { title: title, placeholder_name: "overview_usecases", action: "get_usecase" })
  }

  public addImage(request): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-data?action=save_image", request)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public addFile(request): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-data?action=save_file", request)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public announcementSingle(request): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-db-data", request)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public announcementSingleUpdate(request): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/announcement_notif_api", request)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public addImageDrupal(request): Observable<any> {
    let headers = new HttpHeaders();
    const DRUPAL_CLIENT_ID = 'gicadmin';
    const DRUPAL_CLIENT_SECRET = 'gicadmin';

    const token = `${DRUPAL_CLIENT_ID}:${DRUPAL_CLIENT_SECRET}`;
    const encodedToken = Buffer.from(token).toString('base64');

    headers.append('Authorization', 'Basic ' + encodedToken);
    headers.append('Content-Type', 'application/octet-stream');
    headers.append('Content-Disposition', 'file; filename="filename.jpeg"');
    let httpOption = { headers: headers }

    let http2 = new HttpClient(this.handler)

    return http2.post("https://developer-api-dev.cummins.com/blueprint-pwa/file/upload/node/apiportal_application/field_image?_format=hal_json",
      request, httpOption)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public deleteContent(id): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-data?action=delete_content&id=" + id, {})
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  public updateContent(id, body): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-data?action=update_content&id=" + id, body)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }
  
  public updateContentIntroGettingStarted(body): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-db-data?action=editContent", body)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  

  public addContent(request): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-data?action=add_content", request)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

}

export class CMSSaveImageReqModel {
  public data: Array<CMSValueModel>;
}

export class CMSValueModel {
  public value: string
}

export class CMSSaveImageResModel {
  public fid: Array<CMSValueModel>;
}

export class CMSContentAddReqModel {
  _links: CMSLinksModel
  title: CMSValueModel
  field_heading: CMSValueModel
  body: CMSValueModel
  field_placeholder_name: CMSValueModel
  field_image: Array<CMSFieldImageModel>
}

export class CMSLinksModel {
  type: CMSTypeModel
}

export class CMSTypeModel {
  href: string
}
export class CMSFieldImageModel {
  target_id: number
}