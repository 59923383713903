<div class="wapper-aboutpop {{themeValue}}" >
        <div class="row">
      
          <div class="col-12">
            <div class="title-pop" >
               <strong style="font-size: 16px;
               font-family: 'cmi-bold' , Segoe UI, Arial ;">{{title}}</strong>
                <span class="cancel-div float-right">
                    <mat-icon mat-dialog-close class="closeBtn">cancel</mat-icon>
                  </span>
                </div>
          </div>
      
        </div>
        <ng-container *ngIf="this.data.section==2">
        <div class="row step-body mt-3" >
                <div class="col-md-12 " >
                    <div class="row" *ngIf="this.data.action == 'edit' && this.data.data.heading_v2">
                        <div class="col-md-12">
                    <div class="default-cont">
                        <p class="bolderText">Previous Content</p>
                        <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                        <div><b>Heading: </b> <p>{{this.data.data.heading_v2?this.data.data.heading_v2:''}}</p></div>
                        <div><b>Description:</b> <p>{{this.data.data.body_v2?this.data.data.body_v2:''}}</p></div>
                        <div><b>Image:</b> {{this.data.data.file_name_v2?this.data.data.file_name_v2:''}}</div>
                      </div>
                    </div>
                  </div>
              <div class="row">
                    <div class="col-md-12">
                       <mat-form-field appearance="outline" style="width: 100%;">
                          <mat-label>Enter Heading *</mat-label>
                          <input matInput [(ngModel)]="section3.title" maxlength="100">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                        <div class="col-md-12">
                           <mat-form-field appearance="outline" style="width: 100%;">
                              <mat-label>Enter Description *</mat-label>
                              <textarea rows="7" maxlength="1200" matInput [(ngModel)]="section3.description" ></textarea>
                          </mat-form-field>
                        </div>
                      </div>

                      <!-- <div class="row ">
                            <div class="col-md-12">
                                <div class="imgdiv-bg">
                                    <div class="row">
                                            <div class="col-md-12 img-up-title">
                                                Add Image Thumbnail *
                                            </div>
                                             </div>
                                             <div class="row" >
                                                    <div class="col-md-12">
                                                      <div class="up-text">Image Size Should be <span>Width: 236px / Height: 167px</span> in <span>Png/Jpeg</span> format within 2MB</div>
                                                      <mat-form-field appearance="outline" class="imgwap">
                                                         <label class="upload-label" (click)="file.click()" >{{(this.section3.file_name && this.section3.file_name!='')?this.section3.file_name:'Add Images'}}</label>
                                                         <input  matInput  (click)="file.click()">
                                                         <input #file accept="image/*" (change)="uploadfile($event)" style="display: none;"  type="file" class="w-100" >
                                                         <mat-icon (click)="file.click()" matSuffix class="upicn">file_upload</mat-icon>
                                                       </mat-form-field>
                                                       <mat-error *ngIf="this.errmss!=''">{{this.errmss}}</mat-error>
                                                    </div>
                                                  </div>
                                    </div>
                            </div>
                          </div> -->

                          <div class="row imgdiv-bg">
                            <div class="col-md-9">
                                <div class="row">
                               <div class="col-md-12 img-up-title">
                                  Add Image Thumbnail *
                               </div>
                                </div>
                               <div class="row">
                                  <div class="col-md-12 radioingc">
                                    <mat-radio-group [(ngModel)]="radioimg"  aria-label="Select an option">
                                      <mat-radio-button value="r1">Select Default Image</mat-radio-button>&nbsp;&nbsp;
                                      <mat-radio-button value="r2">Upload Custom Image</mat-radio-button>&nbsp;&nbsp;
                                    </mat-radio-group>
                                
                                 </div>
                               </div>
                               <div class="row" *ngIf="radioimg == 'r1'">
                                <ng-container *ngFor="let img of defaultImages;let j = index">
                                  <div class="col-md-4">
                                      <div class="cardimg"  [ngClass]="this['imgf'+(j+1)]" (click)="selectDefaultimg(j+1,img)"
                                      style="background-image:url({{img.file_url}});
                                      background-repeat:no-repeat;
                                      -webkit-background-size:cover;
                                      -moz-background-size:cover;
                                      -o-background-size:cover;
                                      background-size:cover;
                                      background-position:center;
                                      "
                                      >
                                       
                                      </div>
                                      </div>
                               </ng-container>
                               </div>
                               <div class="row" *ngIf="radioimg == 'r2'">
                                 <div class="col-md-12">
                                   <div class="up-text">Image Size Should be <span>Width: 236px / Height: 167px</span> in <span>Png/Jpeg</span> format within 2MB</div>
                                   <mat-form-field appearance="outline" class="imgwap">
                                    <label class="upload-label" (click)="file.click()" >{{(this.section3.file_name && this.section3.file_name!='')?this.section3.file_name:'Click browse to add the Images'}}</label>
                                    <input  matInput  (click)="file.click()">
                                    <input #file accept="image/*" (change)="uploadfile($event)" style="display: none;"  type="file" class="w-100" >
                                    <mat-icon (click)="file.click()" matSuffix class="upicn">file_upload</mat-icon>
                                  </mat-form-field>
                                  <mat-error *ngIf="this.errmss!=''">{{this.errmss}}</mat-error>
                                 </div>

                               </div>
                            </div>
                            <div class="col-md-3">

                            </div>
                          </div>

              </div>
         </div>
         <div class="row pt-3">
                <div class="col-md-3 delbtn" >
                        <a *ngIf="data.action=='edit'" (click)="delete(2)" style="cursor: pointer;">Delete</a>
                </div>
                <div class="col-md-9 text-right allbtngrp" >
                 
                  <button mat-dialog-close #wapper  class="btn btn-outline-dark">Cancel</button>
                  <button *ngIf="this.data.action == 'edit' && this.data.data.heading_v2" class="cancel-edit-btn btn" (click)="restoreCon(2)">Restore previous content</button>
                  <button  [disabled]="validate(2)" (click)="Save2()" class="btn btn-act">Save</button>
                </div>
              </div>
            </ng-container>
            <!-- ------------------------------------------------------ -->
            <ng-container *ngIf="this.data.section==1">
              <div class="row step-body mt-3" >
                <div class="col-md-12 " >
                    <div class="row" *ngIf="this.data.action == 'edit' && this.data.data.heading_v2">
                        <div class="col-md-12">
                    <div class="default-cont">
                        <p class="bolderText">Previous Content</p>
                        <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                        <div><b>Heading: </b> <span  [innerHTML]="this.decodeHTML(this.data.data.heading_v2?this.data.data.heading_v2:'')"></span></div>
                        <div><b>Description:</b> <p>{{this.data.data.body_v2?this.data.data.body_v2:''}}</p></div>
                        <div><b>Order:</b> {{this.data.data.order_v2?this.data.data.order_v2:1}}</div>
                      </div>
                    </div>
                  </div>
              <div class="row">
                    <div class="col-md-12 pb-2">
                       <!-- <mat-form-field appearance="outline" style="width: 100%;">
                          <mat-label>Enter Heading</mat-label>
                          <input matInput >
                      </mat-form-field> -->
                      <mat-label>Enter Heading *</mat-label><br>
                      <p-editor name="custom" aria-required="true"  [(ngModel)]="section2.title"
                      [style]="{'height':'50px'}">
                      <p-header>
                        <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-size">
                            <option value="small">Small</option>
                            <option value="large">Large</option>
                            <option value="huge">Huge</option>
                          </select>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-color"></select>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                          <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                          <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                          <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                          <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                        </span>
                      </p-header>
                    </p-editor>
                    </div>
                  </div>

                  <div class="row">
                        <div class="col-md-12">
                           <mat-form-field appearance="outline" style="width: 100%;">
                              <mat-label>Enter Description *</mat-label>
                              <textarea rows="7" matInput [(ngModel)]="section2.description" ></textarea>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                           <mat-form-field appearance="outline" style="width: 100%;">
                              <mat-label>Enter Order Value *</mat-label>
                              <input matInput (keypress)="numberOnly($event)" maxlength="2" [(ngModel)]="section2.order" >
                          </mat-form-field>
                        </div>
                      </div>

              </div>
         </div>
         <div class="row pt-3">
                <div class="col-md-3 delbtn" >
                        <a *ngIf="data.action=='edit'" style="cursor: pointer;" (click)="delete(1)">Delete</a>
                </div>
                <div class="col-md-9 text-right allbtngrp" >
                 
                  <button mat-dialog-close #wapper  class="btn btn-outline-dark">Cancel</button>
                  <button *ngIf="this.data.action == 'edit' && this.data.data.heading_v2" class="cancel-edit-btn btn" (click)="restoreCon(1)">Restore previous content</button>
                  <button  [disabled]="validate(1)" (click)="Save1()" class="btn btn-act">Save</button>
                </div>
              </div>
            </ng-container>
</div>