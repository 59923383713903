<div *ngIf="currentRoute != '/web/api/register' && currentRoute != '/web/api/browser-not-supported'">
<app-page-header class = "contentHeader" (selectedTheme)="onSetTheme($event)"></app-page-header>
     <div class="main-container" id="mainCont">
       <router-outlet></router-outlet>
       
     </div>
<app-page-footer></app-page-footer>
</div>

<div *ngIf="currentRoute == '/web/api/register' || currentRoute == '/web/api/browser-not-supported' ">
  <div class="reg-container">
    <!-- <router-outlet></router-outlet> -->
  </div>
</div>

