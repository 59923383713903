import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { CMSService } from 'src/app/core/cms/cms.service';

@Component({
  selector: 'app-useapitablepop',
  templateUrl: './useapitablepop.component.html',
  styleUrls: ['./useapitablepop.component.scss']
})
export class UseapitablepopComponent implements OnInit {

  apiinput = {code:"",message:"",response:""}
  errresponse = []
  errmsg = "";
  constructor(private loader: LoaderService,private dialogRef: MatDialogRef<UseapitablepopComponent>,@Inject(MAT_DIALOG_DATA) public data,private cmsService: CMSService,) { }


  ngOnInit(): void {
    console.log(this.data.table[0])
    if(this.data){
      this.errresponse = this.data.table;
      if(this.data.type=='edit'){
        this.apiinput.code = this.data.table[this.data.index].error_code?this.data.table[this.data.index].error_code:'';
        this.apiinput.message = this.data.table[this.data.index].message?this.data.table[this.data.index].message:'';
        this.apiinput.response = this.data.table[this.data.index].error_response?this.data.table[this.data.index].error_response:'';
      }
    }
  }

checkDisabled(){
  if(this.apiinput.code == "" || this.apiinput.message=="" || this.apiinput.response==""){
    return true;
  }
  else{
    return false;
  }
}
  update(){

    if(this.apiinput.code == "" || this.apiinput.message=="" || this.apiinput.response==""){
      this.errmsg = "Please enter value on all the fields.";
      return;
    }
    this.errresponse[this.data.index].error_code = this.apiinput.code
    this.errresponse[this.data.index].message = this.apiinput.message
    this.errresponse[this.data.index].error_response = this.apiinput.response
    let title = JSON.stringify(this.errresponse)
    let formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', this.data.body);
    formData.append('id', this.data.id);
    formData.append('heading', this.data.heading);
    formData.append('title', title);
    formData.append('wwid',  this.data.wwid);
    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
      if (result.Message == "Updated successfully") {
        console.log("success...");
        this.dialogRef.close('success');
      } 
      else {
       console.log("error...");
       this.dialogRef.close('error');
      }
    });
  }

  add(){

    if(this.apiinput.code == "" || this.apiinput.message=="" || this.apiinput.response==""){
      this.errmsg = "Please enter value on all the fields.";
      return;
    }
    this.errresponse.push({
      error_code:this.apiinput.code,
      message: this.apiinput.message,
      error_response:this.apiinput.response
    })
   
    let title = JSON.stringify(this.errresponse)
    let formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', this.data.body);
    formData.append('id', this.data.id);
    formData.append('heading', this.data.heading);
    formData.append('title', title);
    formData.append('wwid',  this.data.wwid);
    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
      if (result.Message == "Updated successfully") {
        console.log("success...");
        this.dialogRef.close('success');
      } 
      else {
       console.log("error...");
       this.dialogRef.close('error');
      }
    });
  }

}
