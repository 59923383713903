<div class="app-skeleton">
    <div class="{{themeValue}}">
    <div *ngIf="opts.screen=='carousel'">

        <p-carousel [value]="carouselData" [numVisible]="opts.numVisible" [numScroll]="opts.numScroll"
            [circular]="opts.circular" [responsiveOptions]="responsiveOptions">
            <ng-template let-griddata pTemplate="item">

                <div class="text-center card-carousel cursorClass" style="height: 250px;">
                    <div style="margin-bottom: 10px;">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ height: '100px',width: '100px'}">
                        </ngx-skeleton-loader>
                    </div>

                    <div>
                        <ngx-skeleton-loader [theme]="{ height: '24px', width: '60%'}"></ngx-skeleton-loader>
                    </div>

                    <div>
                        <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                    </div>
                </div>

            </ng-template>
        </p-carousel>
    </div>

    <div *ngIf="opts.screen=='grids'">
        <div *ngIf="opts.showDescription">
            <div class="bottom-margin">
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '30%'}"></ngx-skeleton-loader>
            </div>
            <div class="bottom-margin">
                <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
            </div>
        </div>

        <mat-grid-list [gutterSize]="responsiveOptions.gutter" [cols]="responsiveOptions.columns"
            [rowHeight]="responsiveOptions.height">
            <mat-grid-tile class="cursorClass" *ngFor="let card of carouselData">
                <div class="card-body">

                    <div>
                        <div style="text-align: -webkit-center; text-align: -moz-center" class="bottom-m2">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ height: '120px',width: '120px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="bottom-margin">
                            <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                        </div>

                        <div class="bottom-margin">
                            <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                        </div>

                        <div class="bottom-m2">
                            <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                        </div>

                        <div class="text-right">
                            <ngx-skeleton-loader [theme]="{ height: '36px', width: '100px'}"></ngx-skeleton-loader>
                        </div>

                    </div>
                </div>

            </mat-grid-tile>
        </mat-grid-list>

        <div *ngIf="opts.showDescription" style="margin-top: 20px;">
            <div class="text-right">
                <ngx-skeleton-loader [theme]="{ height: '40px', width: '180px'}"></ngx-skeleton-loader>
            </div>
        </div>
    </div>

    <div *ngIf="opts.screen=='api-list'">
        <div *ngIf="opts.showDescription">
            <div class="bottom-margin">
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '30%'}"></ngx-skeleton-loader>
            </div>
            <div class="bottom-margin">
                <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
            </div>
        </div>

        <div class="list-card" *ngFor="let api of carouselData">

            <div class="list-row">
                <div class="right-margin">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '55px',width: '55px'}">
                    </ngx-skeleton-loader>
                </div>

                <div style="width: 24%;" class="align-list right-m2">
                    <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                </div>

                <div style="width: 3%;" class="align-list right-m2">
                    <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                </div>

                <div style="width: 24%;" class="align-list right-m2">
                    <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                </div>

                <div style="width: 24%;" class="align-list right-m2">
                    <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                </div>

                <div style="width: 9%;" class="align-list">
                    <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="opts.screen=='table'">

        <mat-table #table [dataSource]="dataSource">

            <ng-container *ngFor="let name of responsiveOptions" matColumnDef="{{name}}">
                <mat-header-cell *matHeaderCellDef class="col-header">
                    {{name}}
                </mat-header-cell>

                <mat-cell *matCellDef="let element">
                    <span class="empty-mobile-label">{{name}}:</span>
                    <div class="table-width">
                        <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="responsiveOptions"></mat-header-row>
            <mat-row *matRowDef="let row; columns: responsiveOptions;"></mat-row>
        </mat-table>
    </div>

    <div *ngIf="opts.screen=='notification'">

        <div class="empty-noti-card" *ngFor="let notific of carouselData">
            <div class="row">
                <div class="col-1">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '12px',width: '12px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-8">
                    <div style="padding-right: 20px;">
                        <div class="mb-small">
                            <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                        </div>
                        <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="col-3">
                    <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="row">
                <div class="col-5" style="padding: 0px 55px;">
                    <ngx-skeleton-loader [theme]="{ height: '12px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="opts.screen=='filters'" class="empty-filter-card">
        <p class="mont-semi">Filter By:</p>

        <div *ngIf="responsiveOptions.filters==4">

            <div class="disp">
                <div class="empty-filter empty-first">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>

                <div class="empty-filter" style="width: 12%">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
            </div>

            <div class="resp-disp container-fluid" style="padding: 0">
                <div class="row">
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="text-right">
                            <ngx-skeleton-loader [theme]="{ height: '36px', width: '100px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="responsiveOptions.filters==3">

            <div class="disp">
                <div class="empty-3-filter empty-first">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-3-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-3-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-3-filter" style="width: 10%">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
            </div>

            <div class="resp-disp container-fluid" style="padding: 0">
                <div class="row">
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                        <div class="text-right">
                            <ngx-skeleton-loader [theme]="{ height: '36px', width: '100px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div *ngIf="responsiveOptions.filters==2">

            <div class="disp-for-two">
                <div class="empty-2-filter empty-first">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-2-filter">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
                <div class="empty-2-filter" style="width: 16%">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
            </div>

            <div class="resp-two container-fluid" style="padding: 0">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="text-right">
                            <ngx-skeleton-loader [theme]="{ height: '36px', width: '100px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div *ngIf="opts.screen=='searchinp'" class="empty-filter-card">

        <div *ngIf="responsiveOptions.filters==1">

            <div class="disp-for-two">
                <div class="empty-2-filter empty-first">
                    <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                </div>
            </div>

            <div class="resp-two container-fluid" style="padding: 0">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-6 col-sm-6 col-12">
                        <ngx-skeleton-loader [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="text-right">
                            <ngx-skeleton-loader [theme]="{ height: '36px', width: '100px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div *ngIf="opts.screen=='api-overview'">

        <br>
        <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div class="row">
                        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                <ngx-skeleton-loader appearance="circle" [theme]="{ height: '100px',width: '100px'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{ height: '20px',width: '100px'}"></ngx-skeleton-loader>
                    </div>
                    </div>
                    </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <ngx-skeleton-loader [theme]="{ height: '100px'}"></ngx-skeleton-loader>
                    </div>
        </div>
        <br><br>
        <ngx-skeleton-loader [theme]="{ height: '20px', width: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>

        <br><br>
        <ngx-skeleton-loader [theme]="{ height: '20px', width: '200px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '20px'}"></ngx-skeleton-loader>

        <br><br>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <ngx-skeleton-loader [theme]="{ height: '400px'}"></ngx-skeleton-loader>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <ngx-skeleton-loader [theme]="{ height: '400px'}"></ngx-skeleton-loader>
            </div>
        </div>

        <br><br>
        <ngx-skeleton-loader [theme]="{ height: '20px', width: '200px'}"></ngx-skeleton-loader>
        
        <div class="spec-card">
            <br>
            <div class="main-div">
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '200px'}"></ngx-skeleton-loader>
            </div>

            <hr>
            <div class="path-cards">
                <ngx-skeleton-loader [theme]="{ height: '36px', width: '200px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '36px', width: '150px'}"></ngx-skeleton-loader>
            </div>

            <div class="path-cards">
                <ngx-skeleton-loader [theme]="{ height: '36px', width: '200px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '36px', width: '150px'}"></ngx-skeleton-loader>
            </div>
        </div>

    </div>
</div>
</div>