<div class="{{themeValue}}" style="padding:5px; overflow-x: hidden;">
    <div class="row">
        <div class="col-6">
            <h5 mat-dialog-title style="font: normal normal bold 24px/29px 'cmi-regular' , Segoe UI, Arial;">Notifications</h5>
        </div>
        <div class="col-6">
            <label (click)="markAllRead()" class="mark-read-title-class unread-text cursorClass"
                *ngIf="unreadList.length>0">
                {{ 'notifications.markAllRead' | transloco}}
            </label>
            <label class="cursorClass read-text mark-read-title-class" *ngIf="unreadList.length == 0">
                {{ 'notifications.markAllRead' | transloco}}
            </label>
        </div>
    </div>
    <mat-dialog-content style="overflow:hidden" class="scroll-notif">
        <div class="tabs-card">
            <mat-tab-group animationDuration="0ms">

                <mat-tab label="All ({{notiList.length}})" #rla="routerLinkActive"
                    routerLinkActive="active-link active-path">

                    <div *ngIf="showEmptyState">
                        <app-skeleton [responsiveOptions]="" [opts]="opts"></app-skeleton>
                    </div>

                    <div *ngIf="showEmptyState==false">
                        <div class="noti-card" *ngFor="let notific of notiList">
                            <div class="row">
                                <div class="col-1 dot-col">
                                    <span *ngIf="notific.mark_read==0" class="dot-unread"></span>
                                    <span *ngIf="notific.mark_read!=0" class="dot-read"></span>
                                </div>
                                <div class="notif-title">
                                    {{notific.message}}
                                </div>
                                <div class="grey-text-notif">
                                    {{notific.time_ago}} ago
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="notific.mark_read==0" class="col-6 unread-text cursorClass"
                                    style="padding: 10px 20px 10px;font:normal normal 600 14px/18px 'cmi-regular' , Segoe UI, Arial;"
                                    (click)="markRead(notific.id)">
                                    {{ 'notifications.markAsRead' | transloco}}
                                </div>

                                <div *ngIf="notific.mark_read!=0" class="col-6 read-text cursorClass"
                                    style="padding: 10px 20px 10px;font:normal normal 600 14px/18px 'cmi-regular' , Segoe UI, Arial;">
                                    {{ 'notifications.markAsRead' | transloco}}
                                </div>
                            </div>

                        </div>
                        <div *ngIf="notiList.length==0" class="centered-disp">
                            <div>
                                <br><br>
                                <svg-icon [svgStyle]="{ 'width.px':120, 'height.px':120}"
                                    src="assets/images/no-data-found.svg">
                                </svg-icon>
                                <br>
                                {{ 'notifications.noMsgs' | transloco}}
                            </div>
                        </div>
                    </div>

                </mat-tab>

                <mat-tab label="Unread({{unreadList.length}})" #rla="routerLinkActive"
                    routerLinkActive="active-link active-path">

                    <div *ngIf="showEmptyState">
                        <app-skeleton [responsiveOptions]="" [opts]="opts"></app-skeleton>
                    </div>

                    <div *ngIf="showEmptyState==false">
                        <div class="noti-card" *ngFor="let notific of unreadList">
                            <div class="row">
                                <div class="col-1 dot-col">
                                    <span class="dot-unread"></span>
                                </div>
                                <div class="notif-title">
                                    {{notific.message}}
                                </div>
                                <div class="grey-text-notif">
                                    {{notific.time_ago}} ago
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 unread-text cursorClass"
                                    style="padding: 10px 20px 10px;font:normal normal 600 14px/18px 'cmi-regular' , Segoe UI, Arial;"
                                    (click)="markRead(notific.id)">
                                    {{ 'notifications.markAsRead' | transloco}}
                                </div>
                            </div>

                        </div>

                        <div *ngIf="unreadList.length==0" class="centered-disp">
                            <div>
                                <br><br>
                                <svg-icon [svgStyle]="{ 'width.px':120, 'height.px':120}"
                                    src="assets/images/no-data-found.svg">
                                </svg-icon>
                                <br>
                                {{ 'notifications.unreadMsg' | transloco}}
                            </div>
                        </div>
                    </div>

                </mat-tab>

                <mat-tab label="Read({{readList.length}})" #rla="routerLinkActive"
                    routerLinkActive="active-link active-path">

                    <div *ngIf="showEmptyState">
                        <app-skeleton [responsiveOptions]="" [opts]="opts"></app-skeleton>
                    </div>

                    <div *ngIf="showEmptyState==false">
                        <div class="noti-card" *ngFor="let notific of readList">
                            <div class="row">
                                <div class="col-1 dot-col">
                                    <span class="dot-read"></span>
                                </div>
                                <div class="notif-title">
                                    {{notific.message}}
                                </div>
                                <div class="grey-text-notif">
                                    {{notific.time_ago}} ago
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 read-text"
                                    style="padding: 10px 20px 10px;font:normal normal 600 14px/18px 'cmi-regular' , Segoe UI, Arial;">
                                    {{ 'notifications.markAsRead' | transloco}}
                                </div>
                            </div>

                        </div>

                        <div *ngIf="readList.length==0" class="centered-disp">
                            <div>
                                <br><br>
                                <svg-icon [svgStyle]="{ 'width.px':120, 'height.px':120}"
                                    src="assets/images/no-data-found.svg">
                                </svg-icon>
                                <br>
                                {{ 'notifications.readMsg' | transloco}}
                            </div>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>

        </div>
    </mat-dialog-content>
</div>

<!--
<mat-dialog-actions align="end">
	 <button mat-flat-button color="warn" (click)="close()" >Close</button> 
</mat-dialog-actions>-->