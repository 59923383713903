import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DataShareService } from '../core/services/data-share.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit  {

  currentTheme = '';
  currentRoute;
  
  @HostBinding('class') componentCssClass;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(private dataShare: DataShareService, private cdr: ChangeDetectorRef, public overlayContainer: OverlayContainer,
    private router: Router) { 
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
          this.currentRoute = event['url'];
          console.log("this.currentRoute",this.currentRoute)
      });
  }
  // TODO: Please cross check this code
  // ngAfterViewInit(): void {
  //   this.dataShare.getSideBarStatus().subscribe((checked) => {
  //     this.sidenav.toggle(checked)
  //   });
  //   this.cdr.detectChanges();
  // }
  
  ngOnInit(): void {
    this.setTheme('default');
  }

  onSetTheme(theme:string) {
    this.setTheme(theme);
  }

  setTheme(theme){
    this.currentTheme = theme;
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }
}
