<mat-toolbar class="header mat-elevation-z6" style="z-index: 1000;">
    <div class="row" style="width: inherit; margin:0!important">
        <div class="col-4 offset-4" style="text-align: center;">
            <span>
                <img src="../../../../assets/images/cummins-logo.png" class="logo-header-ie">
            </span>
            <span class="full-text mont-bold">
                {{'appName' | transloco}}
            </span>
        </div>
    </div>
</mat-toolbar>

<br><br><br>
<div class="container-unsup">
    <div class="row">
        <div class="col-4 offset-4" style="text-align: center;">
            <img src="assets/images/ai.svg" class="main-unsup">
        </div>

        <div class="col-6 offset-3" style="text-align: center;">
            <h1 style="padding-top: 9%;" class="mont-bold">
                {{'unsupported.title' | transloco}}
            </h1>
            <p class="large-font mont-bold">
                {{'unsupported.subtitle1' | transloco}}
            </p>
            <p class="large-font mont-bold">
                {{'unsupported.subtitle2' | transloco}}
            </p>
        </div>
    </div>

    <div class="row" style="margin-top:4%">
        <div class="col-8 offset-2">
            <div class="row">
                <div class="col-md-3 col-sm-6 text-center">
                    <img src="assets/images/chrome.svg">
                    <br><br>
                    <p class="large-font mont-bold">
                        {{'unsupported.chrome' | transloco}}
                    </p>
                    <p class="large-font mont-bold">
                        {{'unsupported.chrome-version' | transloco}}
                    </p>
                </div>
                
                <div class="col-md-3 col-sm-6 text-center">
                    <img src="assets/images/firefox.svg">
                    <br><br>
                    <p class="large-font mont-bold">
                        {{'unsupported.firefox' | transloco}}
                    </p>
                    <p class="large-font mont-bold">
                        {{'unsupported.firefox-version' | transloco}}
                    </p>
                </div>
                
                <div class="col-md-3 col-sm-6 text-center">
                    <img src="assets/images/edge.svg">
                    <br><br>
                    <p class="large-font mont-bold">
                        {{'unsupported.edge' | transloco}}
                    </p>
                    <p class="large-font mont-bold">
                        {{'unsupported.edge-version' | transloco}}
                    </p>
                </div>
                <div class="col-md-3 col-sm-6 text-center">
                    <img src="assets/images/5b3b46b69357a48ff2188195.png">
                    <br><br>
                    <p class="large-font mont-bold">
                        {{'unsupported.safari' | transloco}}
                    </p>
                    <p class="large-font mont-bold">
                        {{'unsupported.safari-version' | transloco}}
                    </p>
                </div>
            </div>
        </div>

        
    </div>
</div>

<br><br><br>
