import { Component, OnInit, Inject } from '@angular/core';
import { CMSService } from 'src/app/core/cms/cms.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoaderService } from 'src/app/core/components/loader/loader.service';

@Component({
  selector: 'app-delete-pop',
  templateUrl: './delete-pop.component.html',
  styleUrls: ['./delete-pop.component.scss']
})
export class DeletePopComponent implements OnInit {

  constructor(private loader: LoaderService,private dialogRef: MatDialogRef<DeletePopComponent>,@Inject(MAT_DIALOG_DATA) public data,private cmsService: CMSService,) { }
  text = {screen:"",mss:""}
  ngOnInit(): void {
    console.log("this.data",this.data)
    if(this.data.table.length==1)
    {
      this.text.screen = "a";
      this.text.mss = "Table requires more than one records to delete."
    }else{
      this.text.screen = "b";
      this.text.mss = "Are you sure want to delete this Item from Table ?"
    }
    
  }

  delete(){
     let delarr = this.data.table;
     delarr.splice(this.data.index, 1);
     let title = JSON.stringify(delarr)
     let formData = new FormData(); 
     formData.append('action', "editContent");
     formData.append('body', this.data.body);
     formData.append('id', this.data.id,);
     formData.append('heading', this.data.heading);
     formData.append('title', title);
     formData.append('wwid',  this.data.wwid);
     this.loader.show();
     this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
        console.log("result....",result)
       if (result.Message == "Updated successfully") {
         console.log("success...");
         this.dialogRef.close('success');
       } 
       else {
        console.log("error...");
        this.loader.hide();
        this.dialogRef.close('error');
       }
     });
  }
  close(){
    this.dialogRef.close();
  }
}
