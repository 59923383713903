<app-page-header></app-page-header>
<div class="message-container themeValue">
  <div class="row py-3">
    <div class="col-md-12 text-center">
      <br><br><br><br>
      <svg-icon [svgStyle]="{ 'width.px':150}" class="no-data-found" src="assets/images/no-data-found.svg">
      </svg-icon>
    </div>
  </div>
</div>

<div class="row py-3 text-center">
  <div class="col-md-12 text-center">
    <br>
    <b class="login">You are not logged in</b>
    <br><br>
    <b class="login">Please </b><a class="loginlink" (click)="forcelogin()">Login</a>
    <b class="login"> to continue with your activity.</b>
    <br>
  </div>
</div>