import { TokenUserInfo } from './TokenUserInfo';
export class User {
    id: string;
    name: string;
    wwid: string;
    email: string;
    roleId: any;
    role: any;

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.wwid = data.wwid;
        this.email = data.email;
        this.roleId = data.roleId;
        this.role = data.role;
    }

    tokenUserInfo: TokenUserInfo;

    setTokenUserInfo(tokenUserInfo: TokenUserInfo) {
        this.tokenUserInfo = tokenUserInfo;
    }

    getTokenUserInfo() {
        return this.tokenUserInfo;
    }
}
