import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutApiService {
  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
  constructor(private http: HttpClient) { }

  getAboutCMS(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/about_us",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

}
