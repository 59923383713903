import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    setObjectInStorage(key: string, value: object) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getObjectFromStorage(key: string) {
        const obj = localStorage.getItem(key);
        if (obj && obj != null) {
            return JSON.parse(obj);
        }
        return null;
    }

    setInStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getFromStorage(key) {
        return localStorage.getItem(key);
    }

    removeFromStorage(key) {
        localStorage.removeItem(key);
    }

    clearFullStorage() {
        localStorage.clear();
    }
}
