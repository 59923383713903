<div class="{{themeValue}}">
  <div class="container-support">
      <div class="custom-container">
          <div class="row start-cont h-in">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="head-left-wapper head-text">
                  <div class="head-main-text">
                    {{'gettingStartedDetails.head_banner_title' | transloco}}
                  </div>
                  <p class="head-sub-text">
                    {{'gettingStartedDetails.head_banner_sub_title' | transloco}}
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="position: relative;">
                <img class="image-head" src="assets/images/getting started image.svg" />
              </div>
            </div>
      </div>
      
    </div>
    
    <div class="start-cont custom-container">
      <span class="label back-to-top-start cursorClass" (click)="backToTop()">
        <svg-icon [svgStyle]="{ 'width.px':36}" src="assets/images/scroll-top.svg">
        </svg-icon>
      </span>
    
      <span class="list-desc">
        <div class="breadcrumb-list">
          <a class="breadcrumb-item" [routerLink]="['/web/home']">Home</a>
          <div class="breadcrumb-connector">></div>
          <div class="breadcrumb-last">Getting Started</div>
        </div>
      </span>
    
      <br><br>
    
      <div class="row">
    
        <div #container class="col-12 started-intro-wrapper">
          <!-- <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Dropdown button
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </div> -->
          <div class="dropdown">
            
              <div style="display: flex; margin-bottom: 12px;">
                  <div class="summ-font bolderText">
                     <h2 style="font-family: 'cmi-bold' , Segoe UI, Arial;">Introduction &nbsp;&nbsp;</h2>
                  </div>
                   <!--  *ngIf="isAdmin == true" -->
                  <div *ngIf="isLoggedIn == true && isAdmin == true" (click)="editIntro()" class="edit-wrapper">
                    <div class="cursorClass edit-summ-icon">
                      <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                      </svg-icon>
                    </div>
                    <div class="edit-style cursorClass">Edit</div>
                  </div>
                </div>
                <div *ngIf="isEditClicked.intro" class="edit-card">
                    <div *ngIf="cmsDataIntro?.length && cmsDataIntro[0].body_v2" class="default-cont">
                        <p class="bolderText">Previous Content</p>
                        <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                        <p [innerHTML]="sanitize(decodeHTML(cmsDataIntro[0].body_v2))"></p>
                      </div>
                    <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newIntro"
                      [style]="{'height':'120px'}">
                      <p-header>
                        <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-size">
                            <option value="small">Small</option>
                            <option value="large">Large</option>
                            <option value="huge">Huge</option>
                          </select>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-color"></select>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                          <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                          <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                          <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                          <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                        </span>
                      </p-header>
                    </p-editor>
      
                    <br><br>
      
                    <div class="text-right allbtngrp">
                      <button class="cancel-edit-btn btn" (click)="cancelIntro()">Cancel</button>
                      <button *ngIf="cmsDataIntro?.length && cmsDataIntro[0].body_v2" class="cancel-edit-btn btn" (click)="restoreIntro()">Restore previous content</button>
                      <button class="access-btn-overview btn" [disabled]="!isEditClicked.newIntro"
                        (click)="updateIntro()">Save</button>
                    </div>
                  </div>
            
          </div>
    
          <!--
          <p>{{data.introduction_title}}<br />
            {{data.introduction_para1}}</p> <br />
          <p>{{data.introduction_para2}}</p> <br />
          <p>{{data.introduction_para3}}</p> <br /> -->
          <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
           
            <div *ngIf="!isEditClicked.intro" [innerHTML]="sanitize(decodeHTML(cmsDataIntro && cmsDataIntro?.length ? cmsDataIntro[0].body : ''))"  >
              
            </div>
          </div>
         
          <br>
    
          <div style="width: 100%;" class="started-btn-wrapper">
            <button class="btn-xs1-rt btn applyButton started-exp" (click)="goToApiList()">
              Explore All APIs
            </button>
           
          </div>
    
    
        </div>
      </div>
    
      <br><br>
    
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2">
          <!-- [src]="cmsHowToUse[0].image" -->
          <!--<img class="flowdig" src="../../assets/images/Group 114497.svg" style="margin-bottom: 30px;" />-->
          <!-- cmsDatagetting_started_use_apis[0].file_url -->
          <img class="flowdig" [src]="cmsDatagetting_started_use_apis && cmsDatagetting_started_use_apis.length && cmsDatagetting_started_use_apis[0].file_url" />
          <!-- *ngIf="isAdmin==true" -->
          <div *ngIf="isLoggedIn == true && isAdmin==true" class="edit-img-btn edit-wrapper"  (click)="onClickEditImg('image')">
              <div class="cursorClass edit-summ-icon">
                <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                </svg-icon>
              </div>
              <div class="edit-style cursorClass">Edit</div>
            </div>
        </div>
     
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2">
          <ng-container *ngIf="cmsDataIntro?.length && cmsDataIntro[0].video">
              <video #video class="videoprop" controls>
                  <source [src]="cmsDataIntro[0].video" type="video/mp4">
                </video>
          </ng-container>
          
          <div *ngIf="isLoggedIn == true && isAdmin==true" class="edit-img-btn edit-wrapper"  (click)="onClickEditImg('video')">
              <div class="cursorClass edit-summ-icon">
                <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                </svg-icon>
              </div>
              <div class="edit-style cursorClass">Edit</div>
            </div>
        </div>
    
      </div>
    
    </div>
    
    <br><br>
    
    <div  class="cont1-started custom-container">
      <mat-accordion>
        <mat-expansion-panel style="width: 100%;" [expanded]="true">
          <hr style="margin-top:4px;" />
          <mat-expansion-panel-header>
            <mat-panel-title class="mont-bold acc-header">
              How to use the API Portal
              <div *ngIf="isLoggedIn == true && isAdmin == true"  (click)="$event.stopPropagation();edituseapiportal();" class="edit-wrapper1">
                <div class="cursorClass edit-summ-icon">
                  <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                  </svg-icon>
                </div>
                <div class="edit-style cursorClass">Edit</div>
              </div>
            </mat-panel-title>
        
          </mat-expansion-panel-header>
    
          <div>
            <mat-grid-list class="gridview" [cols]="resp.gridCols" [gutterSize]="resp.gutter" [rowHeight]="resp.height">
              <mat-grid-tile *ngFor="let tile of cmsSteps" 
              [class.mat-grid-tile-link]="(!isLoggedIn && tile.title.toLowerCase().includes('login') && tile.link_url) ||
              (isLoggedIn && roleId =='3' && tile.title.toLowerCase().includes('register') && tile.link_url) ||
               (tile.title && tile.link_url && !tile.title.toLowerCase().includes('login') && !tile.title.toLowerCase().includes('register'))">
                <div class="card-body">
                  <span class="label labelprop">Step {{tile.order}}</span>
    
                  <br><br>
    
                  <div>
                    <!--<p style="font-weight: bold">{{tile.desc}}</p>
    <p class="details">{{tile.descContent}}</p>-->
    
                    <div class="details" >
                        <p><strong>{{tile.heading}}</strong></p>
                        <p>{{tile.body}}</p>
                    </div>
    
                    <div class="card-bottoms">
                    <ng-container *ngIf="(!isLoggedIn && tile.title.toLowerCase().includes('login')) ||
                    (isLoggedIn && roleId =='3' && tile.title.toLowerCase().includes('register')) ||
                     (tile.title && !tile.title.toLowerCase().includes('login') && !tile.title.toLowerCase().includes('register'))">
                    <ng-container *ngIf="tile.link_url && tile.link_url.includes('http')">
                        <mat-icon>arrow_right_alt</mat-icon>
                        <a href="{{tile.link_url?tile.link_url:'javascript:void(0);'}}" target="_blank"  class="loginbutton started-links" >  {{tile.title}}</a>
                    </ng-container>
                    <ng-container  *ngIf="tile.link_url && !tile.link_url.includes('http')">
                        <mat-icon>arrow_right_alt</mat-icon>
                        <a [routerLink]="[tile.link_url]" (click)="checkEvents(tile.title)"  class="loginbutton started-links" >  {{tile.title}}</a>
                    </ng-container>
    
                    </ng-container>
                    </div>
    
                    <!-- <div *ngIf="isLoggedIn" class="card-bottoms">
     
                      <ng-container *ngIf="tile.title!='NA' && tile.title!='' && tile.title!='Register' && tile.title!='Login'">
                          <mat-icon>arrow_right_alt</mat-icon><button class="loginbutton started-links" (click)="goToApiList()">  {{tile.title}}</button>
                      </ng-container>
    
                      <ng-container *ngIf="tile.title=='Register' && roleId =='3'">
                          <mat-icon >arrow_right_alt</mat-icon><button class="loginbutton started-links" (click)="goToRegister()">  {{tile.title}}</button>
                      </ng-container>
    
                    </div>
    
                    <div *ngIf="!isLoggedIn" class="card-bottoms">
                      <div *ngIf="tile.title!='NA'">
                        <ng-container *ngIf="tile.title=='Login' || tile.title=='Register' ;else normal">
                            <mat-icon >arrow_right_alt</mat-icon><button class="loginbutton started-links" (click)="goToLogin(tile.title)"> {{tile.title}}</button>
                        </ng-container>
                        <ng-template #normal>
                          
                            <mat-icon >arrow_right_alt</mat-icon><button class="loginbutton started-links" (click)="goToApiList()">  {{tile.title}}</button>
                        </ng-template>
                      </div>
                    </div> -->
                  </div>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
          <br>
    
        </mat-expansion-panel>
    
        <br>
    
        <mat-expansion-panel style="width: 100%;">
          <hr style="margin-top:4px;" />
          <mat-expansion-panel-header>
            <mat-panel-title class="mont-bold acc-header">
              How to use the APIs
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
              <div class="col-12" >
                  <div *ngIf="isLoggedIn == true && isAdmin == true" style="margin: 10px 0px;" (click)="$event.stopPropagation();editHowToUseApi1()" class="edit-wrapper2">
                      <div class="cursorClass">
                        <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                        </svg-icon>
                      </div>
                      <div class="edit-style cursorClass">&nbsp;Edit</div>
                    </div>
                    <div *ngIf="isEditClicked.howtouse1 && cmsHowToUse.length" class="edit-card">
                        <div *ngIf="cmsHowToUse?.length && cmsHowToUse[0].heading_v2" class="default-cont">
                            <p class="bolderText">Previous Content</p>
                            <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                            <p [innerHTML]="sanitize(decodeHTML(cmsHowToUse[0].heading_v2))"></p>
                          </div>
                        <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newhowtouse1"
                          [style]="{'height':'120px'}">
                          <p-header>
                            <span class="ql-formats">
                              <button class="ql-bold"></button>
                              <button class="ql-italic"></button>
                              <button class="ql-underline"></button>
                            </span>
                            <span class="ql-formats">
                              <select class="ql-size">
                                <option value="small">Small</option>
                                <option value="large">Large</option>
                                <option value="huge">Huge</option>
                              </select>
                            </span>
                            <span class="ql-formats">
                              <select class="ql-color"></select>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                              <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                              <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                              <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                              <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                            </span>
                          </p-header>
                        </p-editor>
          
                        <br><br>
          
                        <div class="text-right allbtngrp">
                          <button class="cancel-edit-btn btn" (click)="cancelHowToUseApi1()">Cancel</button>
                          <button *ngIf="cmsHowToUse?.length && cmsHowToUse[0].heading_v2" class="cancel-edit-btn btn" (click)="restoreHowToUseApi1()">Restore previous content</button>
                          <button class="access-btn-overview btn" [disabled]="!isEditClicked.howtouse1"
                            (click)="updatehowtouse1()">Save</button>
                        </div>
                      </div>
                </div>
          </div>
          
          <div class="row">
            <div class="col-12">
                <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
           
                    <div *ngIf="!isEditClicked.howtouse1"  [innerHTML]="sanitize(decodeHTML(cmsHowToUse?.length ? cmsHowToUse[0].heading : ''))"  >
                      
                    </div>
                  </div>
             
            </div>
          </div>
    
    
          <!-- <div class="demo-tab-group"> -->
    
            <div class="row mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 getSecond">
                <div class="bg-res textbackground" style="position: relative;" >
                    <div class="row" style="padding-top: 15px;">
                        <div class="col-12" >
                            <div *ngIf="isLoggedIn == true && isAdmin == true"  (click)="$event.stopPropagation();editHowToUseApi2()" class="edit-wrapper2 bg-gray-api">
                                <div class="cursorClass">
                                  <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                                  </svg-icon>
                                </div>
                                <div class="edit-style cursorClass">&nbsp;Edit</div>
                              </div>
                              <div *ngIf="isEditClicked.howtouse2 && cmsHowToUse.length" class="edit-card">
                                  <div *ngIf="cmsHowToUse?.length && cmsHowToUse[1].heading_v2" class="default-cont">
                                      <p class="bolderText">Previous Content</p>
                                      <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                                      <p [innerHTML]="sanitize(decodeHTML(cmsHowToUse[1].heading_v2))"></p>
                                    </div>
                                  <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newhowtouse2"
                                    [style]="{'height':'150px'}">
                                    <p-header>
                                      <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                      </span>
                                      <span class="ql-formats">
                                        <select class="ql-size">
                                          <option value="small">Small</option>
                                          <option value="large">Large</option>
                                          <option value="huge">Huge</option>
                                        </select>
                                      </span>
                                      <span class="ql-formats">
                                        <select class="ql-color"></select>
                                      </span>
                                      <span class="ql-formats">
                                        <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                                        <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                                        <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                                        <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                                        <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                                      </span>
                                    </p-header>
                                  </p-editor>
                    
                                  <br><br>
                    
                                  <div class="text-right allbtngrp">
                                    <button class="cancel-edit-btn btn" (click)="cancelHowToUseApi2()">Cancel</button>
                                    <button *ngIf="cmsHowToUse?.length && cmsHowToUse[1].heading_v2" class="cancel-edit-btn btn" (click)="restoreHowToUseApi2()">Restore previous content</button>
                                    <button class="access-btn-overview btn" [disabled]="!isEditClicked.howtouse2"
                                      (click)="updatehowtouse2()">Save</button>
                                  </div>
                                </div>
                          </div>
                    </div>
                         <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
           
                    <div *ngIf="!isEditClicked.howtouse2 && cmsHowToUse.length"  [innerHTML]="sanitize(decodeHTML(cmsHowToUse?.length ? cmsHowToUse[1].heading : ''))"  >
                      
                    </div>
                  </div>
                    
                </div>
                
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 getFirst">
                  <div class="bg-res textbackground" style="position: relative;" >
                  <div class="row" style="padding-top: 15px;">
                      <div class="col-12" >
                          <div *ngIf="isLoggedIn == true && isAdmin == true"  (click)="$event.stopPropagation();editHowToUseApi3()" class="edit-wrapper2 bg-gray-api">
                              <div class="cursorClass">
                                <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                                </svg-icon>
                              </div>
                              <div class="edit-style cursorClass">&nbsp;Edit</div>
                            </div>
                            <div *ngIf="isEditClicked.howtouse3" class="edit-card">
                                <div *ngIf="cmsHowToUse?.length && cmsHowToUse[1].body_v2" class="default-cont">
                                    <p class="bolderText">Previous Content</p>
                                    <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                                    <p [innerHTML]="sanitize(decodeHTML(cmsHowToUse[1].body_v2))"></p>
                                  </div>
                                <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newhowtouse3"
                                  [style]="{'height':'150px'}">
                                  <p-header>
                                    <span class="ql-formats">
                                      <button class="ql-bold"></button>
                                      <button class="ql-italic"></button>
                                      <button class="ql-underline"></button>
                                    </span>
                                    <span class="ql-formats">
                                      <select class="ql-size">
                                        <option value="small">Small</option>
                                        <option value="large">Large</option>
                                        <option value="huge">Huge</option>
                                      </select>
                                    </span>
                                    <span class="ql-formats">
                                      <select class="ql-color"></select>
                                    </span>
                                    <span class="ql-formats">
                                      <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                                      <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                                      <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                                      <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                                      <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                                    </span>
                                  </p-header>
                                </p-editor>
                  
                                <br><br>
                  
                                <div class="text-right allbtngrp">
                                  <button class="cancel-edit-btn btn" (click)="cancelHowToUseApi3()">Cancel</button>
                                  <button *ngIf="cmsHowToUse?.length && cmsHowToUse[1].body_v2" class="cancel-edit-btn btn" (click)="restoreHowToUseApi3()">Restore previous content</button>
                                  <button class="access-btn-overview btn" [disabled]="!isEditClicked.howtouse3"
                                    (click)="updatehowtouse3()">Save</button>
                                </div>
                              </div>
                        </div>
                  </div>
                       <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
         
                  <div *ngIf="!isEditClicked.howtouse3 && cmsHowToUse.length"  [innerHTML]="sanitize(decodeHTML(cmsHowToUse?.length ? cmsHowToUse[1].body : ''))"  >
                    
                  </div>
                </div>
                </div>
              </div>
            </div>
    
            <br>
    
            <div class="row">
                <div class="col-12" >
            <div *ngIf="isLoggedIn == true && isAdmin == true" style="margin: 10px 0px;" (click)="$event.stopPropagation();editHowToUseApi4()" class="edit-wrapper2">
                <div class="cursorClass">
                  <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                  </svg-icon>
                </div>
                <div class="edit-style cursorClass">&nbsp;Edit</div>
              </div>
              <div *ngIf="isEditClicked.howtouse4 && cmsHowToUse.length" class="edit-card">
                  <div *ngIf="cmsHowToUse?.length && cmsHowToUse[0].body_v2" class="default-cont">
                      <p class="bolderText">Previous Content</p>
                      <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                      <p [innerHTML]="sanitize(decodeHTML(cmsHowToUse[0].body_v2))"></p>
                    </div>
                  <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newhowtouse4"
                    [style]="{'height':'120px'}">
                    <p-header>
                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-size">
                          <option value="small">Small</option>
                          <option value="large">Large</option>
                          <option value="huge">Huge</option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color"></select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                        <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                        <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                        <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                        <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                      </span>
                    </p-header>
                  </p-editor>
    
                  <br><br>
    
                  <div class="text-right allbtngrp">
                    <button class="cancel-edit-btn btn" (click)="cancelHowToUseApi4()">Cancel</button>
                    <button *ngIf="cmsHowToUse?.length && cmsHowToUse[0].body_v2" class="cancel-edit-btn btn" (click)="restoreHowToUseApi4()">Restore previous content</button>
                    <button class="access-btn-overview btn" [disabled]="!isEditClicked.howtouse4"
                      (click)="updatehowtouse4()">Save</button>
                  </div>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" >
                <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
             
                    <div *ngIf="!isEditClicked.howtouse4 && cmsHowToUse.length"  [innerHTML]="sanitize(decodeHTML(cmsHowToUse?.length ? cmsHowToUse[0].body : ''))"  >
                      
                    </div>
                  </div>
                  </div>
            </div>
            <!-- <div [innerHTML]="cmsHowToUse[0].body"></div> -->
            
            <br>
    
            <div *ngIf="tableData.length>0">
              <table class="errorTableBorder">
                <tr class="errorTable header-row">
                  <th style="width: 20%;">HTTP Error Code</th>
                  <th style="width:40%" class="errorTable">Message</th>
                  <th>Error Response</th>
                  <th *ngIf="isLoggedIn == true && isAdmin == true">Action</th>
                </tr>
               <ng-container *ngFor="let data of tableData;let i = index">
                  <tr>
                      <td>
                         {{data.error_code}}
                      </td>
                      <td >
                          {{data.message}}
                      </td>
                      <td style="white-space: pre-line;">
                          {{data.error_response}}
                      </td>
                      <td *ngIf="isLoggedIn == true && isAdmin == true">
                         <div class="table-actions" >
                            <mat-icon (click)="errorResEdit(i)">edit</mat-icon>
                          <mat-icon (click)="errorResAdd(i)">add</mat-icon>
                          <mat-icon (click)="errorResDelete(i)">delete</mat-icon>
                         </div>
                      </td>
                    </tr>
               </ng-container>    
              </table>
    
              <table class="getting-started-resp-table">
                  <ng-container *ngFor="let data of tableData;let i = index">
                <tr>
                  <td>
                    <span class="mobile-cols">
                      HTTP Error Code:
                    </span>
                    <span class="mobile-cols-desc">
                        {{data.error_code}}
                    </span>
    
                    <br><br>
    
                    <span class="mobile-cols">
                      Message:
                    </span>
                    <span class="mobile-cols-desc">
                      <span class="mobile-cols-desc">
                          {{data.message}}
                      </span>
                    </span>
    
                    <br><br>
    
                    <span class="mobile-cols">
                      Error Response:
                    </span>
                    <span class="mobile-cols-desc">
                      <div class="jsonstyle" style="white-space: pre-line;">
                          {{data.error_response}}
                        </div>
                    </span>
                    <span *ngIf="isLoggedIn == true && isAdmin == true" class="mobile-cols">
                        Action:
                      </span>
                      <span *ngIf="isLoggedIn == true && isAdmin == true" class="mobile-cols-desc">
                          <div class="table-actions" >
                              <mat-icon (click)="errorResEdit(i)">edit</mat-icon>
                            <mat-icon (click)="errorResAdd(i)">add</mat-icon>
                            <mat-icon (click)="errorResDelete(i)">delete</mat-icon>
                           </div>
                      </span>
    
                  </td>
                </tr>
                  </ng-container>
              </table>
    
              <br>
              <!-- <p>Note: More error codes may be added in future.</p> -->
    
            </div>
  
            <div class="row">
                <div class="col-12" >
            <div *ngIf="isLoggedIn == true && isAdmin == true" style="margin: 10px 0px;" (click)="$event.stopPropagation();editHowToUseApi5()" class="edit-wrapper2">
                <div class="cursorClass">
                  <svg-icon [svgStyle]="{ 'width.px':20}" src="assets/images/edit.svg">
                  </svg-icon>
                </div>
                <div class="edit-style cursorClass">&nbsp;Edit</div>
              </div>
              <div *ngIf="isEditClicked.howtouse5 && cmsHowToUse.length" class="edit-card">
                  <div *ngIf="cmsHowToUse?.length && cmsHowToUse[0].title_v2" class="default-cont">
                      <p class="bolderText">Previous Content</p>
                      <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                      <p [innerHTML]="sanitize(decodeHTML(cmsHowToUse[0].title_v2))"></p>
                    </div>
                  <p-editor name="custom" aria-required="true" [(ngModel)]="isEditClicked.newhowtouse5"
                    [style]="{'height':'120px'}">
                    <p-header>
                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-size">
                          <option value="small">Small</option>
                          <option value="large">Large</option>
                          <option value="huge">Huge</option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color"></select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list q1-box" value="ordered" type="button" title="Number bullets"></button>
                        <button class="ql-list ql-active q1-box" value="bullet" type="button" title="Bullets"></button>
                        <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                        <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                        <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                      </span>
                    </p-header>
                  </p-editor>
    
                  <br><br>
    
                  <div class="text-right allbtngrp">
                    <button class="cancel-edit-btn btn" (click)="cancelHowToUseApi5()">Cancel</button>
                    <button *ngIf="cmsHowToUse?.length && cmsHowToUse[0].title_v2" class="cancel-edit-btn btn" (click)="restoreHowToUseApi5()">Restore previous content</button>
                    <button class="access-btn-overview btn" [disabled]="!isEditClicked.howtouse5"
                      (click)="updatehowtouse5()">Save</button>
                  </div>
                </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12" >
                <div class="ql-editor ql-wrapper" style="height:inherit;padding:0px 0px!important;overflow:hidden;">
             
                    <div *ngIf="!isEditClicked.howtouse5 && cmsHowToUse.length"  [innerHTML]="sanitize(decodeHTML(cmsHowToUse?.length ? cmsHowToUse[0].title : ''))"  >
                      
                    </div>
                  </div>
                  </div>
            </div>
          <!-- </div> -->
        </mat-expansion-panel>
      </mat-accordion>
    <!--  -->
    <div #alert *ngIf="successFlag" class="alert alert-over alert-success alert-dismissible fade show"
    style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
    <mat-icon (click)="closeAlert()" class="closeBtn">cancel</mat-icon>
    {{alertMsg}}
    </div>
    <div #alert *ngIf="errorFlag" class="alert alert-over alert-danger alert-dismissible fade show"
    style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
    <mat-icon (click)="closeAlert()" class="closeBtn">cancel</mat-icon>
    {{alertMsg}}
    </div>
    <!--  -->
      <br><br>
    </div>
</div>
