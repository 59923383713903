import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable(
  { providedIn: 'root' }
)
export class LoaderService {
  private renderer2: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  show() {
    this.renderer2.addClass(document.body, 'show-loadermain');
  }

  hide() {
    this.renderer2.removeClass(document.body, 'show-loadermain');
  }

}
