<div class="wapper-usecasepop {{themeValue}}" >
    <div class="row">
  
      <div class="col-12">
        <div class="text-center title-pop" >
            How to use the API Portal
            <span class="cancel-div float-right">
                <mat-icon mat-dialog-close class="closeBtn">cancel</mat-icon>
              </span>
            </div>
      </div>
  
    </div>
    <div class="row steppercusd" >
      <div class="col-md-12">
        
          <mat-horizontal-stepper linear="true" #stepper>
    
              <!-- <ng-template matStepperIcon="done" let-index="index">
                  {{index + 10}}
              </ng-template>
               -->
            
                <ng-template matStepperIcon="edit">
                <mat-icon>insert_drive_file</mat-icon>
              </ng-template>
            
              <ng-template matStepperIcon="done">
                <mat-icon>done_all</mat-icon>
              </ng-template>
            
              <mat-step [completed]="stepControl('step1')" >
                
                    <ng-template matStepLabel>
                        <div class="step-indicator st1">Step 1</div>
                        <!-- <div class="step-name">Basic Info</div> -->
                    </ng-template>
                    <ng-container *ngIf="loadingStep">
                  <div class="row step-body" >
                      <div class="col-md-12 form-group" >
                            <div class="row">
                                    <div class="col-md-12 py-3 text-right">
                                       <i><b>* Required field</b></i> 
                                      
                                    </div>
                                  </div>
                        <div class="row">
                          <div class="col-md-12">
                            <!-- <input type="text" placeholder="testt"> -->
                             <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                <mat-label>Heading *</mat-label>
                                <input matInput [(ngModel)]="apiinput.heading1">
                            </mat-form-field>
                            
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                               <mat-form-field appearance="outline" style="width: 100%;">
                                  <mat-label>Enter the Summary *</mat-label>
                                  <textarea rows="3" matInput [(ngModel)]="apiinput.summary1"></textarea>
                              </mat-form-field>
                            </div>
                          </div>
                            
                          <div class="row">
                            <div class="col-md-12">
                              <!-- <input type="text" placeholder="testt"> -->
                               <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                  <mat-label>Link Text</mat-label>
                                  <input matInput [(ngModel)]="apiinput.link_text1">
                              </mat-form-field>
                              
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <!-- <input type="text" placeholder="testt"> -->
                               <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                  <mat-label>Link URL</mat-label>
                                  <input matInput [(ngModel)]="apiinput.link_url1">
                              </mat-form-field>
                              
                            </div>
                          </div>
           
                   
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-right" >
                        <a mat-dialog-close #wapper  class="btn btn-outline-dark">Cancel</a>&nbsp;&nbsp;
                        <button  (click)="goForward(stepper,'step1')" [disabled]="apiinput.heading1 == '' || apiinput.summary1 == ''" class="btn btn-act">Next</button>
                      </div>
                    </div>
              </ng-container>
        
              </mat-step>
            
              <mat-step #step2 [completed]="stepControl('step2')" >
                  <ng-template matStepLabel>
                      <div class="step-indicator st2">Step 2</div>
                      <!-- <div class="step-name">Basic Info</div> -->
                  </ng-template>
                  <ng-container *ngIf="loadingStep">
                  <div class="row step-body">
                        <div class="col-md-12 form-group" >
                                <div class="row">
                                        <div class="col-md-12 py-3 text-right">
                                           <i><b>* Required field</b></i> 
                                          
                                        </div>
                                      </div>
                            <div class="row">
                              <div class="col-md-12">
                                <!-- <input type="text" placeholder="testt"> -->
                                 <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                    <mat-label>Heading *</mat-label>
                                    <input matInput [(ngModel)]="apiinput.heading2">
                                </mat-form-field>
                                
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                   <mat-form-field appearance="outline" style="width: 100%;">
                                      <mat-label>Enter the Summary *</mat-label>
                                      <textarea rows="3" matInput [(ngModel)]="apiinput.summary2"></textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                                
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link Text</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_text2">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link URL</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_url2">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
               
                       
                          </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <a mat-dialog-close class="btn btn-outline-dark">Cancel</a>&nbsp;&nbsp;
                      <button  (click)="goForward(stepper,'step2')" [disabled]="apiinput.heading2 == '' || apiinput.summary2 == ''" class="btn btn-act">Next</button>
                    </div>
                  </div>
                </ng-container>
              </mat-step>
              <mat-step #step3 [completed]="stepControl('step3')" >
                <ng-template matStepLabel>
                    <div class="step-indicator st2">Step 3</div>
                    <!-- <div class="step-name">Basic Info</div> -->
                </ng-template>
                <ng-container *ngIf="loadingStep">
                <div class="row step-body">
                        <div class="col-md-12 form-group" >
                                <div class="row">
                                        <div class="col-md-12 py-3 text-right">
                                           <i><b>* Required field</b></i> 
                                          
                                        </div>
                                      </div>
                            <div class="row">
                              <div class="col-md-12">
                                <!-- <input type="text" placeholder="testt"> -->
                                 <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                    <mat-label>Heading *</mat-label>
                                    <input matInput [(ngModel)]="apiinput.heading3">
                                </mat-form-field>
                                
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                   <mat-form-field appearance="outline" style="width: 100%;">
                                      <mat-label>Enter the Summary *</mat-label>
                                      <textarea rows="3" matInput [(ngModel)]="apiinput.summary3"></textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                                
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link Text</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_text3">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link URL</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_url3">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
               
                       
                          </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <a mat-dialog-close  class="btn btn-outline-dark">Cancel</a>&nbsp;&nbsp;
                    <button  (click)="goForward(stepper,'step3')" [disabled]="apiinput.heading3 == '' || apiinput.summary3 == ''" class="btn btn-act">Next</button>
                  </div>
                </div>
            </ng-container>
            </mat-step>
            <mat-step #step4 [completed]="stepControl('step4')">
                <ng-template matStepLabel>
                    <div class="step-indicator st2">Step 4</div>
                    <!-- <div class="step-name">Basic Info</div> -->
                </ng-template>
                <ng-container *ngIf="loadingStep">
                <div class="row step-body">
                        <div class="col-md-12 form-group" >
                                <div class="row">
                                        <div class="col-md-12 py-3 text-right">
                                           <i><b>* Required field</b></i> 
                                          
                                        </div>
                                      </div>
                            <div class="row">
                              <div class="col-md-12">
                                <!-- <input type="text" placeholder="testt"> -->
                                 <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                    <mat-label>Heading *</mat-label>
                                    <input matInput [(ngModel)]="apiinput.heading4">
                                </mat-form-field>
                                
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                   <mat-form-field appearance="outline" style="width: 100%;">
                                      <mat-label>Enter the Summary *</mat-label>
                                      <textarea rows="3" matInput [(ngModel)]="apiinput.summary4"></textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                                
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link Text</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_text4">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- <input type="text" placeholder="testt"> -->
                                   <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                                      <mat-label>Link URL</mat-label>
                                      <input matInput [(ngModel)]="apiinput.link_url4">
                                  </mat-form-field>
                                  
                                </div>
                              </div>
               
                       
                          </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <a mat-dialog-close class="btn btn-outline-dark">Cancel</a>&nbsp;&nbsp;
                    <button  (click)="goForward(stepper,'step4')" [disabled]="apiinput.heading4 == '' || apiinput.summary4 == ''" class="btn btn-act">Save</button>
                  </div>
                </div>
            </ng-container>
            </mat-step>
            </mat-horizontal-stepper>
      </div>
    </div>
  
  
  
  
  </div>