<div class="wapper-aboutpop {{themeValue}}" >
        <div class="row">
      
                <div class="col-12">
                  <div class="title-pop" >
                     <strong style="font-size: 16px;
                     font-family: 'cmi-bold' , Segoe UI, Arial;">Edit Header Text</strong>
                      <span class="cancel-div float-right">
                          <mat-icon mat-dialog-close class="closeBtn">cancel</mat-icon>
                        </span>
                      </div>
                </div>
            
              </div>
           
              <div class="row pt-3">
                  <div *ngIf="this.data.data.heading_v2" class="col-md-12">
                      <div class="default-cont">
                          <p class="bolderText">Previous Content</p>
                          <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
                          <p [innerHTML]="this.data.data.heading_v2?this.data.data.heading_v2:''"></p>
                        </div>
                  </div>
                    <div class="col-md-12">
                       <mat-form-field appearance="outline" style="width: 100%;">
                          <mat-label>Enter Heading *</mat-label>
                          <textarea matInput [(ngModel)]="header" maxlength="100"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row pt-3">

                        <div class="col-md-12 text-right allbtngrp" >
                         
                          <button mat-dialog-close #wapper  class="btn btn-outline-dark">Cancel</button>
                          <button *ngIf="this.data.data.heading_v2" class="btn btn-outline-dark" (click)="restore()">Restore previous content</button>
                          <button  (click)="Save()" [disabled]="validate()"  class="btn btn-act">Save</button>
                        </div>
                      </div>
</div>