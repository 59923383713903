import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import jwt from 'jwt-decode';
import { BehaviorSubject, throwError } from 'rxjs';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { environment } from 'src/environments/environment';
import { LANG_CODE } from '../../core/constants/Constants';
import { RoleConfig } from '../../core/guards/role.config';
import { Tokens } from '../../core/models/Tokens';
import { TokenUserInfo } from '../../core/models/TokenUserInfo';
import { User } from '../../core/models/User';
import { UserPreference } from '../../core/models/UserPreference';
import { StorageService } from '../../core/services/storage.service';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoginService {

  subscription: Subscription = new Subscription();
  USER_INFO_URL = `${environment.apiHostUrl}/user/info`;
  //  REQ_MANAGEMENT_URL = `${environment.apiHostUrl}/search/request-management`;
  HOME_PAGE_URL = '/web/home';
  SUPPLIER_PAGE_URL = '/web/supplier'
  UNAUTHORIZED_URL = '/unauthorized';
  // REGISTRATION_URL = '/web/api/register';
  REGISTRATION_URL = '/register';


  private isLoggedIn = false;
  private tokenSubject = new BehaviorSubject<Tokens>(null);
  private userSubject = new BehaviorSubject<User>(null);
  private isForRegistration = false;

  // getting valid list of role Id that web application supports
  private validRoleList = Object.keys(RoleConfig);

  ERROR_URL = '/error';
  check_url: string;
  addRequest: any;


  addUser(reqBody: any) {
    return this.http.post(`${environment.apiHostUrl}/search/user-management`, reqBody).pipe(
      map((res: any) => res)
    );
  }
  // getting valid list of role Id that web application supports
  // private validRoleList = Object.keys(RoleConfig);

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    // tslint:disable-next-line: align
    private translocoService: TranslocoService,
    private serv: ApiInformationService,
    private storage: StorageService,
    private dataShare: DataShareService,
    private loader: LoaderService,
    public _cookieService: CookieService
  ) { }
  externaluserAuth: boolean = false;
  public setIsLoggedIn(isLoggedIn: boolean) {
    // this.requestAddUser();
    if (this._cookieService.get('loggedIn')) {
      console.log("A1");
      const d = new Date();
      d.setTime(d.getTime() + (1.20 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = "loggedIn=" + isLoggedIn.toString() + "; " + expires + "; path=/;";

    } else {
      console.log("A2");
      this._cookieService.set('loggedIn', isLoggedIn.toString(), 0.052);
    }

    //localStorage.setItem("loggedIn", isLoggedIn.toString() );
  }

  public setUserInfo(user: User) {
    this.userSubject.next(user);
  }

  public setTokens(tokens: Tokens) {
    console.log("setTokens")
    this.tokenSubject.next(tokens);
  }

  public getToken() {
    console.log("getToken")
    return this.tokenSubject.getValue();
  }

  public setUserPreference(preference: UserPreference) {
    const user: User = this.getUserInfo();
    this.setUserInfo(user);
  }

  public setUserLanguage() {
    const defaultLang = this.translocoService.getDefaultLang();
    this.translocoService.setActiveLang(defaultLang);
    localStorage.setItem(LANG_CODE, defaultLang);
  }

  public getAccessToken() {
    //const tokens = this.tokenSubject.getValue();
    const tokens = this._cookieService.get('accessToken') !== null ? this._cookieService.get('accessToken') : undefined;

    //const tokens = localStorage.getItem("accessToken");
    const accessToken = tokens && tokens != null ? tokens : null;
    return accessToken;
  }

  public getIdToken() {
    console.log("getIdToken")
    const tokens = this.tokenSubject.getValue();
    const idToken = tokens && tokens != null ? tokens.idToken : null;
    return idToken;
  }

  public updateTokens(accessToken: string, idToken: string) {
    console.log("updatedTOekn")
    const tokens: Tokens = this.tokenSubject.getValue();

    if (idToken && idToken != null && idToken.trim().length > 0) {
      tokens.idToken = idToken;
    }

    if (accessToken && accessToken != null && accessToken.trim().length > 0) {
      tokens.accessToken = accessToken;
    }

    this.tokenSubject.next(tokens);
  }

  public getRefreshToken() {
    const tokens = localStorage.getItem("refreshToken");
    //const tokens = this.tokenSubject.getValue();
    const refreshToken = tokens && tokens != null ? tokens : null;
    return refreshToken;
  }

  // fetch access token from refresh token.
  public getTokenFromRefreshToken(refreshToken: string) {

    if (
      refreshToken &&
      refreshToken != null &&
      refreshToken.trim().length > 0
    ) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      console.log("getTokenFromRefreshToken")


      const params: HttpParams = new HttpParams()
        .append('grant_type', 'refresh_token')
        .append('client_id', environment.oauth2.clientId)
        .append('refresh_token', refreshToken);
      console.log("getToken")
      return this.http.post(environment.oauth2.tokenUrl, null, {
        headers,
        params,
      });
    }
    return throwError('auth code not provided');
  }

  public getUserInfo() {
    return this.userSubject.getValue();
  }

  public getUserRoleId() {
    const userObj = this.userSubject.getValue();
    const roleId = userObj && userObj != null ? userObj.roleId : -1;
    return roleId;
  }

  public isAuthenticated() {
    let loggedIn = this._cookieService.get('loggedIn') !== null ? this._cookieService.get('loggedIn') : undefined;
    //localStorage.getItem("loggedIn")
    return loggedIn == "true" ? true : false;
  }

  public isRegistration() {
    return this.isForRegistration;
  }

  public logout() {

    // if(this._cookieService.get('loggedIn'))
    // {
    //   console.log("oktested")
    //   //document.cookie = "loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }
    if (this._cookieService.get('accessToken')) {
      console.log("oktested2")
      document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    this._cookieService.delete('accessToken');
    this._cookieService.delete('loggedIn');
    this._cookieService.deleteAll();
    this.setUserInfo(null);
    this.setTokens(null);
    this.setIsLoggedIn(false);
    this.destoryUserSession();
  }

  public destoryUserSession() {
    this._cookieService.delete('accessToken');
    // localStorage.setItem("accessToken", null);
    localStorage.setItem("idToken", null);
    localStorage.setItem("refreshToken", null);
    localStorage.setItem("expiresIn", null);
    localStorage.setItem("sessionUserInfo", "");
    //localStorage.setItem("loggedIn", "false");

  }

  // Fetch tokens based on code provided in authorization code flow
  public fetchTokensForCode(code: string) {
    if (code && code != null && code.trim().length > 0) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      const params: HttpParams = new HttpParams()
        .append('grant_type', environment.oauth2.grantType)
        .append('client_id', environment.oauth2.clientId)
        .append('redirect_uri', environment.oauth2.redirectUrl)
        .append('code', code);

      return this.http.post(environment.oauth2.tokenUrl, null, {
        headers,
        params,
      });
    }
    return throwError('auth code not provided');
  }

  // fetch user details from backend.
  public fetchUserInfo(WWID) {
    return this.http.post(this.USER_INFO_URL, { WWID });
  }

  // public fetchStatusInfo(request_number) {
  //   return this.http.post(this.REQ_MANAGEMENT_URL, {request_number });
  // }

  // Navigate to home page
  public navigateToHomePage() {
    const redirectUrl = this.storageService.getFromStorage('rd');
    if (redirectUrl && redirectUrl != null && redirectUrl.trim().length > 0) {
      const rd = redirectUrl.slice(0);
      this.storageService.removeFromStorage('rd');
      this.router.navigate([rd]);
    } else {
      console.log("home3 testing..")
      window.location.href = this.HOME_PAGE_URL
    }
  }

  // Navigate to Unauthorized Page
  public navigateToRegistationrPage() {
    this.router.navigate([this.REGISTRATION_URL]);
  }

  // Navigate to Unauthorized Page
  public navigateToUnauthorized() {
    window.location.href = this.UNAUTHORIZED_URL;
    //this.router.navigate([this.UNAUTHORIZED_URL]);
  }


  public navigateToError() {
    this.router.navigate([this.ERROR_URL]);
  }

  public navigateToRegistration() {
    this.router.navigate([this.REGISTRATION_URL]);
  }

  // Authorization logic
  public authorize(tokens) {
    const { id_token } = tokens;
    const decodedToken: any = jwt(id_token);
    localStorage.setItem('idToken', id_token);

    if (decodedToken && decodedToken != null) {
      // check if the user was authenticated from authorized source
      // If no, then redirect to unauthorized page

      if (decodedToken.aud !== environment.oauth2.clientId) {
        this.navigateToUnauthorized();
        return;
      }
      console.log("p1", decodedToken)
      if (decodedToken.hasOwnProperty('custom:roles') && decodedToken["custom:roles"] !== null && decodedToken["custom:roles"] !== '') {
        this.externaluserAuth = true
      } else {
        console.log("falsee")
        this.externaluserAuth = false;
      }
      // create token object and set the extracted information
      console.log("print data,,,,,ui")
      if (this._cookieService.get('accessToken')) {
        const d = new Date();
        d.setTime(d.getTime() + (1.20 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "accessToken=" + tokens.access_token + "; " + expires + "; path=/;";

      } else {
        this._cookieService.set('accessToken', tokens.access_token, 0.052);
      }



      const verifiedTokens: Tokens = new Tokens(tokens);
      const tokenUserInfo = new TokenUserInfo(decodedToken);
      console.log("print data", tokenUserInfo)
      if (this._cookieService.get('loggedIn')) {
        console.log("ck1")
        const d = new Date();
        d.setTime(d.getTime() + (1.20 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "loggedIn=true; " + expires + "; path=/;";
      } else {
        console.log("ck2", this._cookieService.get('loggedIn'))
        this._cookieService.set('loggedIn', "true", 0.052);
      }
      this.setIsLoggedIn(true);

      // localStorage.setItem("loggedIn", "true");

      console.log("p2")
      var user: User = new User({
        id: "",
        wwid: "",
        name: "",
        email: "",
        roleId: "",
        role: "",
      });
      console.log("p3")
      user.setTokenUserInfo(tokenUserInfo);
      this.setUserInfo(user);

      localStorage.setItem("sessionUserInfo", JSON.stringify(user));
      this.check_url = localStorage.getItem("navigation_url");


      if (
        tokenUserInfo &&
        tokenUserInfo.nickname &&
        tokenUserInfo.nickname != null
      ) {
        console.log("AA1");
        let obj = {
          "loggedIn": true,
          "wwid": user['tokenUserInfo']['nickname']
        }

        this.serv.getUserDetails(obj).subscribe((userRes) => {
          localStorage.setItem("user_det", JSON.stringify(userRes))
          localStorage.setItem("user_id", userRes["logged_in_user_id"])
          const tokenInfo = verifiedTokens.tokenUserInfo;
          console.log("AA2");
          if (localStorage.getItem('requestedRegister') == 'true') {
            if (localStorage.getItem('requestedRegister'))
              localStorage.setItem('requestedRegister', 'false');
            this.navigateToRegistationrPage();
            return;
          }
          if (userRes.user_status_id == 1 && localStorage.getItem('details') == 'true' && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            localStorage.setItem('details', 'false');
            window.location.href = this.storage.getFromStorage("navigateDetailsUrl");
            return;
          }
          else if (localStorage.getItem('downloadSdk') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('getaccess') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('support') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateSupportUrl");
          }
          else if (localStorage.getItem('getauthreturn') == "true") {
            window.location.href = this.storage.getFromStorage("navigateUrl");
            localStorage.removeItem("getauthreturn");
            localStorage.removeItem("navigateUrl");
          }
          else if (localStorage.getItem('isusecasedetail') == "true") {
            window.location.href = this.storage.getFromStorage("navigateUrl") + '/details?v=1';
            localStorage.removeItem("isusecasedetail");
            localStorage.removeItem("navigateUrl");
          } else if (localStorage.getItem('globalsearch') == "true") {
            window.location.href = this.storage.getFromStorage("navigateUrl");
            this.dataShare.doGlobalSearchTrigger();
            // localStorage.removeItem("globalsearch");
            localStorage.removeItem("navigateUrl");
            // localStorage.removeItem("globalsearchtxt");
          }
          else {
            let isSupplierPortalRole = userRes?.role_details?.filter((role) => { return role.role_id === 16 || role.role_id === 17 || role.role_id === 18  });
            console.log("home testing......", isSupplierPortalRole, JSON.stringify(userRes?.role_details));
          
            if(userRes?.role_details !== undefined){
            if (isSupplierPortalRole !== undefined && isSupplierPortalRole.length > 0) {
            
              if (this.check_url.includes(this.SUPPLIER_PAGE_URL)) {
              
                window.location.href = this.SUPPLIER_PAGE_URL;
              } else {
              
                window.location.href = this.HOME_PAGE_URL;
              }
            } else {
              if (this.check_url.includes(this.HOME_PAGE_URL)) {
              
                window.location.href = this.HOME_PAGE_URL;
              } else if(this.check_url.includes(this.SUPPLIER_PAGE_URL) && isSupplierPortalRole.length > 0) {
              
                window.location.href = this.SUPPLIER_PAGE_URL;
              }
              else{
                window.location.href = this.HOME_PAGE_URL;
              }
            }
          }else{
            window.location.href = this.HOME_PAGE_URL;
          }


            // window.location.href = this.HOME_PAGE_URL;


          }
          console.log("AA2kl");

        });

      } else if (tokenUserInfo &&
        tokenUserInfo.userName &&
        tokenUserInfo.userName != null && this.externaluserAuth) {
        console.log("AA3");
        tokenUserInfo.nickname = tokenUserInfo.userName
        user.setTokenUserInfo(tokenUserInfo);
        this.setUserInfo(user);
        debugger;
 
        localStorage.setItem("sessionUserInfo", JSON.stringify(user))
        let roleArray = user['tokenUserInfo']['roleName'].split(',')
        console.log("role array", roleArray)
        let obj = {
          "loggedIn": true,
          "action": "external",
          "userName": user['tokenUserInfo']['userName'],
          "first_name": user['tokenUserInfo']['givenName'],
          "last_name": user['tokenUserInfo']['familyName'],
          "email_id": user['tokenUserInfo']['email'],
          "roleName": roleArray,
 
 
        }
       
        this.serv.getUserDetails(obj).subscribe((userRes) => {
          console.log("userRes",userRes)
          let isSupplier  = roleArray.includes('EDI_Supplier');
          if(userRes?.email_id==undefined && isSupplier){
            let userObj = {
              user_id:  user['tokenUserInfo']['userName'],
              role_list: [16],
              email_id:  user['tokenUserInfo']['email'], 
              action: "add",
              first_name: user['tokenUserInfo']['givenName'], 
              last_name: user['tokenUserInfo']['familyName'],
              wwid:  user['tokenUserInfo']['email'],
              isSupplierPortal: true
            }
            console.log("userObj",userObj)
            // obj.role_list.push(this.userForm.roles == "eCommerce Team" ? 17 : 18);
            this.serv.getUserMgmt(userObj).subscribe((res) => {
              this.serv.getUserDetails(obj).subscribe((response) => {
                userRes = response
                console.log("userRes1",userRes)
          localStorage.setItem("user_det", JSON.stringify(userRes))
          localStorage.setItem("user_id", userRes["logged_in_user_id"])
          const tokenInfo = verifiedTokens.tokenUserInfo;
 
          if (localStorage.getItem('requestedRegister') == 'true') {
            if (localStorage.getItem('requestedRegister'))
              localStorage.setItem('requestedRegister', 'false');
            this.navigateToRegistationrPage();
            return;
          }
          if (userRes.user_status_id == 1 && localStorage.getItem('details') == 'true' && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            localStorage.setItem('details', 'false');
            window.location.href = this.storage.getFromStorage("navigateDetailsUrl");
            return;
          }
          else if (localStorage.getItem('downloadSdk') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('getaccess') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('support') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateSupportUrl");
          }
          else {
            let isSupplierPortalRole = userRes?.role_details?.filter((role) => { return role.role_id === 16 || role.role_id === 17 || role.role_id === 18 || role.role_id === 12 });
            console.log("home testing......", isSupplierPortalRole);
            // if(userRes?.role_details[0].role_id ===  16){
            //  
            // this.router.navigate(['/web/supplier'])
            // }
            // isSupplierPortalRole !== undefined && isSupplierPortalRole.length > 0 ?
            //   window.location.href = this.SUPPLIER_PAGE_URL : window.location.href = this.HOME_PAGE_URL;
            if(userRes?.role_details !== undefined){
            
              if (isSupplierPortalRole !== undefined && isSupplierPortalRole.length > 0) {
              
                if(userRes?.role_details[0].role_id ===  16){
                
                  window.location.href = this.SUPPLIER_PAGE_URL;
                } else if (this.check_url.includes(this.SUPPLIER_PAGE_URL)) {
                  window.location.href = this.SUPPLIER_PAGE_URL;
                } else{
                
                  window.location.href = this.HOME_PAGE_URL;
                }
              } else {
                if (this.check_url.includes(this.HOME_PAGE_URL)) {
                  window.location.href = this.HOME_PAGE_URL;
                } else {
                  window.location.href = this.SUPPLIER_PAGE_URL;
                }
              }
            }else{
              window.location.href = this.HOME_PAGE_URL;
            }
          }
              })
            });
 
          }else{console.log("userRes1",userRes)
          localStorage.setItem("user_det", JSON.stringify(userRes))
          localStorage.setItem("user_id", userRes["logged_in_user_id"])
          const tokenInfo = verifiedTokens.tokenUserInfo;
 
          if (localStorage.getItem('requestedRegister') == 'true') {
            if (localStorage.getItem('requestedRegister'))
              localStorage.setItem('requestedRegister', 'false');
            this.navigateToRegistationrPage();
            return;
          }
          if (userRes.user_status_id == 1 && localStorage.getItem('details') == 'true' && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            localStorage.setItem('details', 'false');
            window.location.href = this.storage.getFromStorage("navigateDetailsUrl");
            return;
          }
          else if (localStorage.getItem('downloadSdk') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('getaccess') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateUrl");
          }
          else if (localStorage.getItem('support') == "true" && (user.email == localStorage.getItem("loggedemail") || !localStorage.getItem("loggedemail"))) {
            window.location.href = this.storage.getFromStorage("navigateSupportUrl");
          }
          else {
            let isSupplierPortalRole = userRes?.role_details?.filter((role) => { return role.role_id === 16 || role.role_id === 17 || role.role_id === 18 || role.role_id === 12 });
            console.log("home testing......", isSupplierPortalRole);
            // if(userRes?.role_details[0].role_id ===  16){
            //  
            // this.router.navigate(['/web/supplier'])
            // }
            // isSupplierPortalRole !== undefined && isSupplierPortalRole.length > 0 ?
            //   window.location.href = this.SUPPLIER_PAGE_URL : window.location.href = this.HOME_PAGE_URL;
            if(userRes?.role_details !== undefined){
            
              if (isSupplierPortalRole !== undefined && isSupplierPortalRole.length > 0) {
              
                if(userRes?.role_details[0].role_id ===  16){
                
                  window.location.href = this.SUPPLIER_PAGE_URL;
                } else if (this.check_url.includes(this.SUPPLIER_PAGE_URL)) {
                  window.location.href = this.SUPPLIER_PAGE_URL;
                } else{
                
                  window.location.href = this.HOME_PAGE_URL;
                }
              } else {
                if (this.check_url.includes(this.HOME_PAGE_URL)) {
                
                  window.location.href = this.HOME_PAGE_URL;
                } else if(this.check_url.includes(this.SUPPLIER_PAGE_URL) && isSupplierPortalRole.length > 0) {
                
                  window.location.href = this.SUPPLIER_PAGE_URL;
                }
                else{
                  window.location.href = this.HOME_PAGE_URL;
                }
              }
            }else{
              window.location.href = this.HOME_PAGE_URL;
            }
          }}
          
        });
      
 
      }else {
        console.error('Invalid User....');
        this.logout();
        localStorage.clear();
        //this.navigateToUnauthorized();
        window.location.href = this.UNAUTHORIZED_URL;
      }
    } else {
      console.error('Invalid Token');
      window.location.href = this.UNAUTHORIZED_URL;
    }
  }

  public authorizeForSessionRefresh(tokens) {
    return new Promise((resolve, reject) => {

      const { id_token } = tokens;
      const decodedToken: any = jwt(id_token);
      localStorage.setItem('idToken', id_token);

      if (decodedToken && decodedToken != null) {
        // check if the user was authenticated from authorized source
        // If no, then redirect to unauthorized page

        if (decodedToken.aud !== environment.oauth2.clientId) {
          this.navigateToUnauthorized();
          return;
        }
        console.log("p1", decodedToken)
        if (decodedToken.hasOwnProperty('custom:roles') && decodedToken["custom:roles"] !== null && decodedToken["custom:roles"] !== '') {
          this.externaluserAuth = true
        } else {
          console.log("falsee")
          this.externaluserAuth = false;
        }
        // create token object and set the extracted information
        if (this._cookieService.get('accessToken')) {
          const d = new Date();
          d.setTime(d.getTime() + (1.20 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = "accessToken=" + tokens.access_token + "; " + expires + "; path=/;";
        } else {
          this._cookieService.set('accessToken', tokens.access_token, 0.052);
        }

        const verifiedTokens: Tokens = new Tokens(tokens);
        const tokenUserInfo = new TokenUserInfo(decodedToken);
        console.log("print data", tokenUserInfo)
        this.setIsLoggedIn(true);
        if (this._cookieService.get('loggedIn')) {
          const d = new Date();
          d.setTime(d.getTime() + (1.20 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = "loggedIn=true; " + expires + "; path=/;";
        } else {
          this._cookieService.set('loggedIn', "true", 0.052);
        }
        //localStorage.setItem("loggedIn", "true");
        console.log("p2")
        var user: User = new User({
          id: "",
          wwid: "",
          name: "",
          email: "",
          roleId: "",
          role: "",
        });
        console.log("p3")
        user.setTokenUserInfo(tokenUserInfo);
        this.setUserInfo(user);

        localStorage.setItem("sessionUserInfo", JSON.stringify(user));



        if (
          tokenUserInfo &&
          tokenUserInfo.nickname &&
          tokenUserInfo.nickname != null
        ) {
          console.log("AA1");
          let obj = {
            "loggedIn": true,
            "wwid": user['tokenUserInfo']['nickname']

          }

          this.serv.getUserDetails(obj).subscribe((userRes) => {
            localStorage.setItem("user_det", JSON.stringify(userRes))
            localStorage.setItem("user_id", userRes["logged_in_user_id"])
            const tokenInfo = verifiedTokens.tokenUserInfo;
            console.log("AA2");
            console.log("AA2kl");
            //this.loader.hide();

            resolve("ok");
          });

        } else if (tokenUserInfo &&
          tokenUserInfo.userName &&
          tokenUserInfo.userName != null && this.externaluserAuth) {
          console.log("AA3");
          tokenUserInfo.nickname = tokenUserInfo.userName
          user.setTokenUserInfo(tokenUserInfo);
          this.setUserInfo(user);

          localStorage.setItem("sessionUserInfo", JSON.stringify(user))
          let roleArray = user['tokenUserInfo']['roleName'].split(',')
          console.log("role array", roleArray)
          let obj = {
            "loggedIn": true,
            "action": "external",
            "userName": user['tokenUserInfo']['userName'],
            "first_name": user['tokenUserInfo']['givenName'],
            "last_name": user['tokenUserInfo']['familyName'],
            "email_id": user['tokenUserInfo']['email'],
            "roleName": roleArray,
          }
          this.serv.getUserDetails(obj).subscribe((userRes) => {
            localStorage.setItem("user_det", JSON.stringify(userRes))
            localStorage.setItem("user_id", userRes["logged_in_user_id"])
            const tokenInfo = verifiedTokens.tokenUserInfo;
            resolve("ok");
          });

        } else {
          this.loader.hide();
          console.error('Invalid User');
          this.logout();
          //this.navigateToUnauthorized();
          window.location.href = this.UNAUTHORIZED_URL;
          resolve("ok");
        }
      } else {
        this.loader.hide();
        console.error('Invalid Token');
        //this.navigateToUnauthorized();
        window.location.href = this.UNAUTHORIZED_URL;
        resolve("ok");
      }
    });

  }

  requestAddUser(){
    var tokens = JSON.parse(localStorage.getItem("sessionUserInfo"));
    console.log('check tokens details', tokens)
    if(tokens['tokenUserInfo']['email'] == ""){
    const reqBody = {
      "user_id": "us404",
      "role_list": [
        16
      ],
      "email_id": tokens['tokenUserInfo']['email'],
      "action": "add",
      "first_name": tokens['tokenUserInfo']['givenName'],
      "last_name": tokens['tokenUserInfo']['familyName'],
      "wwid": tokens['tokenUserInfo']['nickname']
    };
    this.subscription.add(
      this.addUser(reqBody).subscribe({
        next: (res: any) => {
          this.addRequest = res;
          console.log('add Request', this.addRequest);
        }, error: (err) => {
  
        }
      })
    )
    }
  }

}
