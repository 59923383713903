import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { MaterialModule } from 'src/app/core/modules/material.module';
import { NotificationComponent } from './components/notification/notification.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { TranslocoRootModule } from 'src/app/core/transloco/transloco-root.module';
import { PreferenceSettingsComponent } from './components/preference-settings/preference-settings.component';
import { ApiInformationService } from '../api/http/api-information.service';
import { LoginService } from 'src/app/account/http/login.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from 'src/app/core/modules/shared.module';


@NgModule({
  declarations: [ NotificationComponent, PreferenceSettingsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    MaterialModule, SharedModule,
    MatTabsModule, MatDialogModule,
    TranslocoRootModule, AngularSvgIconModule.forRoot()
  ],
  exports: [NotificationComponent],
  providers: [LoaderService, ApiInformationService, LoginService]
})
export class SettingsModule { }
