import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ImgUploadComponent } from 'src/app/core/components/img-upload/img-upload.component';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { CMSService } from 'src/app/core/cms/cms.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-editmediapop',
  templateUrl: './editmediapop.component.html',
  styleUrls: ['./editmediapop.component.scss']
})
export class EditmediapopComponent implements OnInit {
  uploadLable = "";
  imgURL:any;
  fileUrl 
  selectedFile:any = "";
  message = "";

  constructor(private dialogRef: MatDialogRef<ImgUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private loader: LoaderService,
     private cmsService: CMSService,private http: HttpClient) { }

  ngOnInit(): void {
    console.log("pop",this.data)
   if(this.data.type=='image')
   {
    this.fileUrl = this.data.media.file_url
   }else if(this.data.type=='video'){
    this.fileUrl = this.data.media.file_url
   }
  this.uploadLable = "Upload "+this.data.type;
   
  }

  uploadFile(ev){
    console.log(ev)
    this.message = "";
    this.uploadLable = ev.target.files[0].name
    var files = ev.target.files
    this.selectedFile = files[0]

    var size=(this.selectedFile.size)/1000;
    if(this.data.type=='image')
    {
        if(size>100){
                       this.selectedFile = "";
                        this.message = "File should be less than 100KB"; 
                        return
                      }
    }
    if(this.data.type=='video')
    {
      if(size>30000){
        this.selectedFile = "";
         this.message = "File should be less than 30MB"; 
         return
       }
    }

    if(files[0].type.includes(this.data.type))
    {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      this.fileUrl = this.imgURL
    }
    }else{
      this.message = "Please upload "+this.data.type+" file only."
      this.selectedFile = "";
      this.uploadLable = "Upload Media";
      if(this.data.type=='image')
     {
       this.fileUrl = this.data.media.file_url
      }else if(this.data.type=='video'){
       this.fileUrl = this.data.media.file_url
     }
    }
  }

  submitMedia(){

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    
    var index = this.data.media.file_name.lastIndexOf('/')
    // console.log(this.data.media.file_name.substring(0,index))
    var e1 = this.selectedFile.name.lastIndexOf('.')
    var el1 = this.selectedFile.name.length
    var ext = this.selectedFile.name.substring(e1+1,el1)
    var file_name = this.data.media.file_name.substring(0,index) +"/"+this.selectedFile.name.substring(0,e1)
    // var obj = {
    //   action:"editContent",
    //   attachment:"yes",
    //   "file_name": file_name,
    //   "file_type": ext,
    //   "file_data": this.fileUrl,//this.selectedFile,
    //   "attachment_id": this.data.media.attachment_id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }
    // console.log(obj)

    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('attachment', "yes");
    formData.append('file_name', file_name);
    formData.append('file_type', ext);
    formData.append('type_name', this.data.type);
    if(this.selectedFile && this.data.type=='image')
    {
      formData.append('file_data', this.selectedFile);
    }
    formData.append('attachment_id', this.data.media.attachment_id);
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    //console.log(formData)
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result=>{
      console.log("result>>>",result)
      if(result.Message=="Updated successfully")
      {
        this.dialogRef.close('success');
      }else{
        if(result.Message.includes("https")){
          this.onVideoUpload(result.Message);
        }else{
          this.dialogRef.close('error');
        }
      }
      
     })
  }
//presend url s3 bucket
  onVideoUpload(resurl) {   
     this.http.put(resurl, this.selectedFile).subscribe(res=>{
     console.log("res",res);
     localStorage.setItem("upload-mp4-res",JSON.stringify(res));
     this.dialogRef.close('success');
     },error=>{
       console.log("error",error);
       localStorage.setItem("upload-mp4-error",JSON.stringify(error));
       this.dialogRef.close('error');
      }) 
  }

  close() {
    this.dialogRef.close();
  }
}
