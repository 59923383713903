import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StateManageService {
    data: any;

    dataSub = new BehaviorSubject<any>("");
    dataClearSub = new BehaviorSubject<any>("");

    public setState(state: any) {
        console.log("state......",state);
        this.data = state;
        this.dataSub.next(state);
    }

    public getState(): Observable<any> {
        return this.dataSub.asObservable();
    }

    public setClearState() {
        this.dataClearSub.next("true");
    }

    public getClearState(): Observable<any> {
        return this.dataClearSub.asObservable();
    }
}