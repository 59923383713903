import { Injectable, Version } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromptUpdateService {
  constructor(private updates: SwUpdate) {
    this.checkUpdate();
  }

  checkUpdate(){
    this.updates.versionUpdates
    .pipe(
      filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
    )
    .subscribe(event => {
      this.promptUser();
      //alert(event);
    });
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => document.location.reload()); 
  }
}
