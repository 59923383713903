import { AbstractControl } from '@angular/forms';
export class FullnameValidator {
   static validate(control: AbstractControl) : {[key: string]: any} | null {
     ///console.log("control.value..",control.value.split(" ").length)
      if (control.value) {
        let arr = control.value.split(" ");
        if(!(arr.length>=2 && arr[0]!="" && arr[1]!=""))
        {
          return { 'fullNameInvalid': true };
        }
        return null;
      }
      return null;
    }
  }