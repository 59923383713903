import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NotificationComponent } from './components/notification/notification.component';
import { PreferenceSettingsComponent } from './components/preference-settings/preference-settings.component';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,  
    children: [
      {
        path: 'preference', component: PreferenceSettingsComponent,
        canActivate:[AuthGuard],  
        data: {
          title: 'preference',
          breadcrumb: [
            {
              label: 'home',
              url: '/settings/preference'
            },
            {
              label: 'settings',
              url: ''
            }
          ]
        }
      },
      {
        path: 'notification', component: NotificationComponent,
        data: {
          title: 'Settings',
          breadcrumb: [
            {
              label: 'Home',
              url: '/web/home'
            },
            {
              label: 'Settings',
              url: ''
            }
          ]
        }
      }
    ],
    data: {
      title: 'Settings',
      breadcrumb: [
        {
          label: 'Home',
          url: '/web/home'
        },
        {
          label: 'Settings',
          url: ''
        }
      ]
    }
  },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
