import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { Router } from '@angular/router';
import { LoginPopupComponent } from 'src/app/account/components/login-popup/login-popup.component';

@Component({
  selector: 'app-session-pop',
  templateUrl: './session-pop.component.html',
  styleUrls: ['./session-pop.component.scss']
})
export class SessionPopComponent implements OnInit {
  minutes;
  seconds;
  themeValue;
  constructor(public dialogRef: MatDialogRef<SessionPopComponent>,
    private dataShare:DataShareService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
  
    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      if(this.themeValue=='light-theme'){
        document.documentElement.style.setProperty('--darkColor2', '#121212');
      }if(this.themeValue=='default-theme'){
        document.documentElement.style.setProperty('--darkColor2', '#121212');
      }else{
        document.documentElement.style.setProperty('--darkColor2', '#FFFFFF');
      }
    });
  }

  signout(){
    this.dialogRef.close({ action: "signout" });
  }

  continue(){
    this.dialogRef.close({ action: "continue" });
  }

  login() {
    this.dialogRef.close();
    const redirect = window.location.href;
    localStorage.setItem('navigateUrl', redirect);
    localStorage.setItem("getauthreturn", 'true');
    this.router.navigate(['/login']);
    
    this.dialog.open(LoginPopupComponent, {
      panelClass: 'login-dialog',
      disableClose: true
    })    
  }

}
