import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GettingStartedComponent } from './getting-started.component';
import { GettingStartedRoutingModule } from './getting-started-routing.module';
import { MaterialModule } from 'src/app/core/modules/material.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslocoRootModule } from 'src/app/core/transloco/transloco-root.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from 'primeng/editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from 'src/app/account/http/login.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { EditmediapopComponent } from './editmediapop/editmediapop.component';
import { EdituseapiportalpopComponent } from './edituseapiportalpop/edituseapiportalpop.component';
import { UseapitablepopComponent } from './useapitablepop/useapitablepop.component';
import { DeletePopComponent } from './delete-pop/delete-pop.component';

@NgModule({
  declarations: [GettingStartedComponent, EditmediapopComponent, EdituseapiportalpopComponent, UseapitablepopComponent, DeletePopComponent], 
  imports: [
    CommonModule,    
    MaterialModule,
    MatTabsModule, 
    MatDialogModule,
    TranslocoRootModule,
    MatExpansionModule,
    MatGridListModule,
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    GettingStartedRoutingModule,
    EditorModule   
  ],
  exports: [],
  providers: [LoginService, PageHeaderComponent ]
})
export class GettingStartedModule { }
