import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { LoaderService } from '../components/loader/loader.service';
import { LoginService } from '../../account/http/login.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { StorageService } from '../services/storage.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private tokenExceptionList = [
    environment.oauth2.tokenUrl
  ];
  constructor(
    private loadService: LoaderService,
    private loginService: LoginService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private pageheader: PageHeaderComponent,
    private storage: StorageService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //this.loadService.show();
    const newReq = this.getUpdatedRequest(req);
    return next.handle(newReq).pipe(
      catchError((err) => {
        const refreshToken = this.loginService.getRefreshToken();
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          refreshToken != null
        ) {
          return this.manage401ErrorCode(req, next, refreshToken);
        }
        // if (err.status === 0) {
        //   this.errorMessage();
        // }
        else if (
          err instanceof HttpErrorResponse && 
          err.message.startsWith("Http failure during parsing")){
            console.log("Handling the session timeout - Refreshing Token");
            return this.handleSessionTimeout(req, next, refreshToken);
        }

        else {
          return this.handleError(err);
        }
      }),
      // finalize(() => {
      //   this.loadService.hide();
      // })
    );
  }

  // add token to header and clone the request
  getUpdatedRequest(req: HttpRequest<any>) {
    const token = this.loginService.getAccessToken();

    let headers = req.headers;
    if (
      headers &&
      headers != null &&
      token &&
      token != null &&
      !this.isUrlInException(req.url)
    ) {
      if(req.method != "PUT"){
        headers = headers.set('Authorization', token);
      }
      
    }

    let temp = localStorage.getItem('idToken');
    if(temp) headers = headers.set('X-Api-Key', temp);

    return req.clone({ headers });
  }

  // Check if the URL is in Exception list
  isUrlInException(url) {
    let isInException = false;

    this.tokenExceptionList.forEach((e) => {
      if (e && e != null && url.indexOf(e) !== -1) {
        isInException = true;
      }
    });

    return isInException;
  }

  // method responsible to manage 401 Unauthorized error.
  // In case of unauthorized error, this logic makes attempt
  // to get new tokens based on refresh token already available.
  manage401ErrorCode(
    req: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): ObservableInput<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.loginService.getTokenFromRefreshToken(refreshToken).pipe(
        switchMap((newToken: any) => {
          if (newToken) {
            // set token to UserInfo object
            const { id_token, access_token } = newToken;
            this.loginService.updateTokens(access_token, id_token);
            this.tokenSubject.next(id_token);

            // allow original request process again with new Tokens set in header
            return next.handle(this.getUpdatedRequest(req));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      // this logic holds of any request from processing until the refresh token is retrieved.
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.getUpdatedRequest(req));
        })
      );
    }
  }

  // Houses logic to execute to force user to logout.
  logoutUser() {
    // Route to the login page
    // const status = confirm(
    //   'Session is timed out. You are required to login again. Please click OK to navigate to login page'
    // );
    // if (status) {
    //   this.login();
    // }
    return this.handleError('Session Timed Out');
  }

  logout(): void {
    this.loginService.logout();
    // tslint:disable-next-line: max-line-length
    window.location.href =
      `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
  }

  login(){
    localStorage.setItem('requestedLogin', 'true');
    const redirect = window.location.href;
    this.storage.setInStorage('navigateUrl', redirect);
    localStorage.setItem("getaccess", 'true');
    // localStorage.setItem("openGetAccessModal", 'true');
    this.router.navigateByUrl('web/home');
    this.pageheader.doLogin();
    
  }

  // Houses logic to handle http error
  handleError(error: any) {
    console.error(error);
    return throwError(() => error);
  }

  errorMessage() {
    if (this.loginService.isAuthenticated()) {
      localStorage.setItem('requestedRegister', 'false')
      this._snackBar.open("Session timed out. Please login once again!", "Login", {
        duration: undefined,
        panelClass: ['cummins-snackbar']
      }).onAction()
        .subscribe(() => window.location.href = `${environment.oauth2.loginUrl}?response_type=${environment.oauth2.responseType}&client_id=${environment.oauth2.clientId}&redirect_uri=${environment.oauth2.redirectUrl}&state=1234&scope=${environment.oauth2.scope}&idp_identifier=AzureAD`);
    } else {
      this._snackBar.open("Internal Server error, please contact the administrator.", "", {
        duration: 7000,
        panelClass: ['cummins-snackbar']
      });
    }
  }

  handleSessionTimeout(
    req: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): Observable<HttpEvent<any>> {
    console.log("this.isRefreshingToken: ", this.isRefreshingToken);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null); //this will trigger emission of 

      return this.loginService.getTokenFromRefreshToken(refreshToken).pipe(
        switchMap((newToken: any) => {
          if (newToken) {
            console.log("New Token: ", newToken);
            // set token to UserInfo object
            const { id_token, access_token } = newToken;
            // this.loginService.updateTokens(access_token, id_token);
            newToken["refresh_token"] = refreshToken;
            this.loginService.authorize(newToken);
            this.tokenSubject.next(id_token);

            // allow original request process again with new Tokens set in header
            return next.handle(this.getUpdatedRequest(req));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      // this logic holds of any request from processing until the refresh token is retrieved.
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.getUpdatedRequest(req));
        })
      );
    }
  }

  // handleSessionTimeoutOld(
  //   req: HttpRequest<any>,
  //   next: HttpHandler,
  //   refreshToken: string){
  //   //5 second duration to accept refreshing of token


  //   //else redirect to login page
  //   let refreshFlag = false;

  //   // if (this.loginService.isAuthenticated()) {
  //   //   localStorage.setItem('requestedRegister', 'false')
  //     this._snackBar.open("Session timed out. Do you want to Continue ?", "Continue", {
  //     // this._snackBar.openFromComponent(ShowMessageComponent, {
  //       duration: 5000, //5 seconds
  //       panelClass: ['cummins-snackbar'],
  //       horizontalPosition: 'center',
  //       verticalPosition: 'top',
  //     }).onAction()
  //       .subscribe(() => {
  //         refreshFlag = true;
  //       });
  //   // }

  //   setTimeout(()=>{
  //     if(refreshFlag){
  //       this.manage401ErrorCode(req, next, refreshToken);
  //     }
  //     else{
  //       this.login();
  //     }
      
  //   }, 5100);
  // }


}
