<div class="{{themeValue}}">
  <div class="topheaderdiv">
    <div class="custom-container">
      <mat-toolbar class="header mat-elevation-z6 " style="z-index: 1000;">
        <div style="display: flex; align-items: center; gap: 24px">
          <div class="hamburger-nav">
            <mat-icon *ngIf="!showMenu" class="nav-menu-icon" (click)="showMenu = !showMenu;">menu</mat-icon>
            <mat-icon *ngIf="showMenu" class="nav-menu-icon" (click)="showMenu = !showMenu;">close</mat-icon>
          </div>
          <!-- logo-light.svg / cummins-logo.png-->
          <span><img src="assets/images/{{(themeValue=='light-theme')?'cummins-logo-light.png':'cummins-logo.png'}}"
              (click)="goToHome()" class="logo-header"></span>
          <a *ngIf="!isSupplierPortal" [routerLink]="['/web/home']" (click)="goToHome()" class="home-link-btn full-text">{{'appName' | transloco}}</a>
          <a *ngIf="!isSupplierPortal" [routerLink]="['/web/home']" (click)="goToHome()" class="home-link-btn short-text">{{'appNameAbbr' | transloco}}</a>
          <a *ngIf="isSupplierPortal" [routerLink]="['/web/supplier']" (click)="goToHome()" class="home-link-btn full-text">{{'suplierPortalName' | transloco}}</a>
          <a *ngIf="isSupplierPortal" [routerLink]="['/web/supplier']" (click)="goToHome()" class="home-link-btn short-text">{{'suplierPortalNameAbbr' | transloco}}</a>
        </div>

        <div>
          <nav mat-tab-nav-bar class="nav-bar">
            <div class="desk-header" *ngFor="let tabItem of navConfigData">

              <a class="link-btn" mat-tab-link [routerLink]="tabItem.route" *ngIf="!tabItem.dropdown"
                routerLinkActive="active-link link-outer" #rla="routerLinkActive" [active]="rla.isActive">
                {{tabItem.label}} 
              </a>

              <button class="link-btn" mat-tab-link *ngIf="tabItem.dropdown && tabItem.label=='APIs'"
                [matMenuTriggerFor]="apisDropdown" #navD1="matMenuTrigger" [ngClass]="{'link-outer': navD1.menuOpen}"
                [matMenuTriggerData]="{data: tabItem.children}">
                {{tabItem.label}}
                <mat-icon>expand_more</mat-icon>
              </button>

              <button class="link-btn" mat-tab-link *ngIf="tabItem.dropdown && tabItem.label=='My Dashboard'"
                [matMenuTriggerFor]="dashboardDropdown" [matMenuTriggerData]="{data: tabItem.children}"
                #navD2="matMenuTrigger" [ngClass]="{'link-outer': navD2.menuOpen}">
                {{tabItem.label}}
                <mat-icon>expand_more</mat-icon>
              </button>

              <button class="link-btn" mat-tab-link *ngIf="tabItem.dropdown && tabItem.label=='Digital Hub'"
                [matMenuTriggerFor]="digitalHubDropdown" [matMenuTriggerData]="{data: tabItem.children}"
                #navD2="matMenuTrigger" [ngClass]="{'link-outer': navD2.menuOpen}">
                {{tabItem.label}}
                <mat-icon>expand_more</mat-icon>
            </button>
            </div>
          </nav>

          <mat-menu #apisDropdown="matMenu" backdropClass="api-nav-menu">
            <div class="api-inner-menu">

              <div class="api-links-menu">
                <ng-template let-data="data" matMenuContent>
                  <div *ngFor="let item of data">
                    <a mat-menu-item class="menu-item-labels" [routerLink]="item.route">
                      {{item.label}} 
                    </a>
                  </div>
                </ng-template>

                <a class="btn cumminsWhite" [routerLink]="['/web/api/api-list']" (click)="redirectallapi();">Browse All
                  APIs
                </a>
              </div>

              <div class="api-cat-menu">
                <div class="browse-heading">Browse by API Category</div>

                <div class="cat-flex">
                  <div *ngFor="let cat of categoryList">
                    <p (click)="goToCat(cat.name)">{{cat.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>

          <mat-menu #dashboardDropdown="matMenu" matMenuContent backdropClass="nav-menu">
            <ng-template let-data="data" matMenuContent>
              <div *ngFor="let item of data">
                <a mat-menu-item class="menu-item-labels" [routerLink]="item.route">
                  {{item.label}} 
                </a>
              </div>
            </ng-template>
          </mat-menu>

          <mat-menu #digitalHubDropdown="matMenu" matMenuContent backdropClass="nav-menu">
            <ng-template let-data="data" matMenuContent>
              <div *ngFor="let item of data">
                <a mat-menu-item class="menu-item-labels" [routerLink]="item.route">
                  {{item.label}} 
                </a>
              </div>
            </ng-template>
          </mat-menu>
        </div>

        <div>
          <span *ngIf="!((router.url).includes('supplier'))" class="search-icn search-icn-desk desk-header-search">
            <span *ngIf="router.url !== '/web/home'">
              <input type="text" placeholder="Enter the keyword to search" [(ngModel)]="globalsearchtxt"
                (keyup)="checkem($event.target.value)" (keyup.enter)="globalsearch()">
              <a class="searchbtn" *ngIf="!isGlobalSearch || globalsearchtxt == ''"
                (click)="globalsearch()"><mat-icon>search</mat-icon>&nbsp;&nbsp;</a>
              <a class="searchbtn" *ngIf="isGlobalSearch && globalsearchtxt != ''"
                (click)="clearsearch()"><mat-icon>close</mat-icon>&nbsp;&nbsp;</a>
            </span>
          </span>
          <span [ngClass]="{'brisMar': isSupplierPortal && !isSwitchEnable && isLoggedIn,
           'theme-icon' : isLoggedIn
          }">
            <mat-icon  style="padding: 0px 3px; cursor: pointer; transform: translate(8%, 25%); overflow:visible;" 
              [matMenuTriggerFor]="menuTheme" matTooltip="Theme" matTooltipPosition="above">
              brightness_6
            </mat-icon>
            <mat-menu #menuTheme="matMenu">
              <button mat-menu-item (click)="switchTheme('default')" [ngClass]="{'highlight_light': themeValue === 'default-theme'}">Default</button>
              <button mat-menu-item (click)="switchTheme('light')" [ngClass]="{'highlight_light': themeValue === 'light-theme'}">Light</button>
              <button mat-menu-item (click)="switchTheme('dark')" [ngClass]="{'highlight_dark': themeValue === 'dark-theme'}" class="highlight">Dark</button>
            </mat-menu>
          </span>
         
          <span [hidden]="!isLoggedIn" class="notification-icon" *ngIf="!isSupplierPortal">
            <svg-icon src="assets/images/{{(themeValue=='light-theme')?'bellnotbl.svg':'notification.svg'}}"
               [ngClass]="{notiss: isSwitchEnable == false}" *ngIf="notificationStatus=='unread'" [matMenuTriggerFor]="notificationWindow"
              (click)="opendialog();" [svgStyle]="{ 'width.px':24}"  (menuClosed)="menuAction('close')"
              (menuOpened)="menuAction('open')">
            </svg-icon>
            <svg-icon src="assets/images/{{(themeValue=='light-theme')?'bellbl.svg':'bell.svg'}}"
               [ngClass]="{notiss: isSwitchEnable == false}"  *ngIf="notificationStatus=='read'" [matMenuTriggerFor]="notificationWindow"
              (click)="opendialog();" [svgStyle]="{ 'width.px':24 }" (menuClosed)="menuAction('close')"
              (menuOpened)="menuAction('open')">
            </svg-icon>
          </span>
          <span *ngIf="router.url !== '/web/home'" class="mob-header">
            <div class="mob-ser activeser" [style.display]="sblock">
              <div class="ser-block search-icn-mob">
                <a class="mcs" (click)="searchblock()"><mat-icon>close</mat-icon>&nbsp;&nbsp;</a>
                <div class="inp-wap">
                  <input type="text" placeholder="Enter the keyword to search" [(ngModel)]="globalsearchtxt"
                    (keyup)="checkem()" (keyup.enter)="globalsearch()">
                  <a class="searchbtn" *ngIf="!isGlobalSearch || globalsearchtxt == ''"
                    (click)="globalsearch()"><mat-icon>search</mat-icon>&nbsp;&nbsp;</a>
                  <a class="searchbtn" *ngIf="isGlobalSearch && globalsearchtxt != ''"
                    (click)="clearsearch()"><mat-icon>close</mat-icon>&nbsp;&nbsp;</a>
                </div>
              </div>
            </div>
            <mat-icon style="margin-top: 8px" (click)="searchblock()">search</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;
          </span>

          <mat-menu #notificationWindow="matMenu" class="notif-menu" xPosition="after">
            <div class="cls-notification" (click)="$event.stopPropagation();">
              <app-notification (notificationEvent)="getNotificationStatus();"
                [notifications]="notiList"></app-notification>
            </div>
          </mat-menu>

          <a *ngIf="!isLoggedIn" class="btn logBtn cursorClass desk-header" (click)="doLogin()">
            {{'login' | transloco}}
          </a>

          <button *ngIf="isLoggedIn" class="circle-icon desk-header" [matMenuTriggerFor]="auth"> {{initials}}
          </button>

          <svg-icon *ngIf="isLoggedIn" src="assets/images/dropdown-arrow.svg" [matMenuTriggerFor]="auth"
            [svgStyle]="{ 'width.px':14 }" class="cursorClass desk-header"></svg-icon>

          <mat-menu #auth="matMenu" class="user-details-menu">
            <div class="borderMenu">
              <p class="userName bolder-class">{{ userName }}</p>
              <p class="userRole" *ngIf="userRole == 'Default'">
                <span class="mont-semi">
                  {{ userRole}} User
                </span>
              </p>
              <p class="userRole roleItem">
                <span class="mont-med">
                  {{ combinedRole}}
                </span>
              </p>
            </div>
            
            <div class="borderMenu">
              <button mat-menu-item  *ngIf="isSupplierPortal && isSwitchEnable && isValidUser" (click)="switch()"  class="text-align-left bolder-class ">Switch to API Portal</button>
              <button  mat-menu-item   *ngIf= "!isSupplierPortal && isSwitchEnable && isValidUser" (click)="switch()"  class="text-align-left bolder-class ">Switch to Supplier EDI Onboarding</button>
            </div>
           
            <div *ngIf="!isSupplierPortal" class="borderMenu">
              <a mat-menu-item [routerLink]="['/web/settings/preference']" (click)="goToSettings()"
              class="text-align-left bolder-class home-logout preference-setting">
              {{'preference' | transloco}}</a>
            </div>
            
          
            <div><button mat-menu-item (click)="logout()" class="text-align-left bolder-class home-logout">
              {{'logout' | transloco}}</button></div>
          </mat-menu>

        </div>
      </mat-toolbar>
    </div>
  </div>

  <div class="resp-menu" [hidden]="!showMenu">
    <div class="mob-nav-menu" mat-tab-nav-bar *ngFor="let tabItem of navConfigData">

      <button class="link-btn" *ngIf="!tabItem.dropdown" [routerLink]="tabItem.route" (click)="onCloseHamMenu()"
        routerLinkActive="active-link link-outer" #rla="routerLinkActive" [active]="rla.isActive">
        {{tabItem.label}}
      </button>

      <mat-expansion-panel *ngIf="tabItem.dropdown">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{tabItem.label}} 
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="tabItem.label=='My Dashboard'">
          <div *ngFor="let item of tabItem.children">
            <div class="menu-item-labels" [routerLink]="item.route" (click)="onCloseHamMenu()">
              {{item.label}} 
            </div>
          </div>
        </div>

        <div *ngIf="tabItem.label=='Digital Hub'">
          <div *ngFor="let item of tabItem.children">
            <div class="menu-item-labels" [routerLink]="item.route" (click)="onCloseHamMenu()">
              {{item.label}} 
            </div>
          </div>
        </div>

        <div *ngIf="tabItem.label=='APIs'">
          <div class="api-links-menu">
            <div *ngFor="let item of tabItem.children">
              <div class="menu-item-labels" [routerLink]="item.route" (click)="onCloseHamMenu()">
                {{item.label}} 
              </div>
            </div>

            <button class="btn cumminsWhite" (click)="showMenu=!showMenu" [routerLink]="['/web/api/api-list']">Browse
              All APIs</button>
          </div>

          <br>
          <div class="browse-heading">Browse by API Category</div>

          <div *ngFor="let cat of categoryList" class="mob-menu-cat">
            <p (click)="goToCat(cat.name);showMenu=!showMenu">{{cat.name}}</p>
          </div>
        </div>
      </mat-expansion-panel>

    </div>
    <div class="row">
      <div class="col-md-12" style="padding-left: 0px;">
        <a *ngIf="!isLoggedIn" style="padding-left: 0px;" class="btn logBtn cursorClass"
          (click)="onCloseHamMenu();doLogin();">
          {{'login' | transloco}}
        </a>
        <span *ngIf="isLoggedIn">
          <p style="padding-left: 0px;" class=" userName bolder-class">{{ userName }}</p>
          <p style="padding-left: 0px;" class="userRole" *ngIf="userRole == 'Default'">
            <span class="mont-semi">
              {{ userRole}} User
            </span>
          </p>
          <p style="padding-left: 0px;" class="userRole">
            <span class="mont-med">
              {{ combinedRole}}
            </span>
          </p>
        </span>
        <br>
        <hr *ngIf="isLoggedIn" class="auth-hr">
        <mat-icon style="padding: 5px 0px; cursor: pointer; transform: translate(8%, 25%);"
          [matMenuTriggerFor]="menuTheme" matTooltip="Theme" matTooltipPosition="above"> brightness_6</mat-icon>

        <mat-menu #menuTheme="matMenu">
          <button mat-menu-item (click)="switchTheme('default')">Default</button>
          <button mat-menu-item (click)="switchTheme('light')">Light</button>
          <!-- <button mat-menu-item (click)="switchTheme('dark')">Dark</button> -->
        </mat-menu>
        
        
        <hr *ngIf="isLoggedIn" class="auth-hr">
        <button  *ngIf="isLoggedIn && !isSupplierPortal" mat-menu-item (click)="onCloseHamMenu();goToSettings();"
          class="link-btn text-align-left bolder-class home-logout">
          {{'preference' | transloco}}</button>
        <hr *ngIf="isLoggedIn" class="auth-hr">
        <button *ngIf="isLoggedIn" mat-menu-item (click)="onCloseHamMenu();logout();"
          class="link-btn text-align-left bolder-class home-logout">
          {{'logout' | transloco}}</button>
      </div>
    </div>
  </div>
</div>