import { Component, OnInit, Inject } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CMSService } from 'src/app/core/cms/cms.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-edituseapiportalpop',
  templateUrl: './edituseapiportalpop.component.html',
  styleUrls: ['./edituseapiportalpop.component.scss']
})
export class EdituseapiportalpopComponent implements OnInit {
  loadingStep = false;
  themeValue;
  apiinput = { 
    heading1:"",
    summary1:"",
    link_text1:"",
    link_url1:"",
    heading2:"",
    summary2:"",
    link_text2:"",
    link_url2:"",
    heading3:"",
    summary3:"",
    link_text3:"",
    link_url3:"",
    heading4:"",
    summary4:"",
    link_text4:"",
    link_url4:"",
   }
  constructor(private loader: LoaderService,
    private dialogRef: MatDialogRef<EdituseapiportalpopComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cmsService: CMSService,
    private dataShare: DataShareService) { }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    console.log(this.data)
     if(this.data && this.data.length>0){
      this.apiinput.heading1 = this.data[0].heading?this.data[0].heading:'';
      this.apiinput.summary1 = this.data[0].body?this.data[0].body:'';
      this.apiinput.link_text1   = (this.data[0].title && this.data[0].title!='NA')?this.data[0].title:'';
      this.apiinput.link_url1   = this.data[0].link_url?this.data[0].link_url:'';
      this.apiinput.heading2 = this.data[1].heading?this.data[1].heading:'';
      this.apiinput.summary2 = this.data[1].body?this.data[1].body:'';
      this.apiinput.link_text2   = (this.data[1].title  && this.data[1].title!='NA')?this.data[1].title:'';
      this.apiinput.link_url2   = this.data[1].link_url?this.data[1].link_url:'';
      this.apiinput.heading3 = this.data[2].heading?this.data[2].heading:'';
      this.apiinput.summary3 = this.data[2].body?this.data[2].body:'';
      this.apiinput.link_text3   = (this.data[2].title  && this.data[2].title!='NA')?this.data[2].title:'';
      this.apiinput.link_url3   = this.data[2].link_url?this.data[2].link_url:'';
      this.apiinput.heading4 = this.data[3].heading?this.data[3].heading:'';
      this.apiinput.summary4 = this.data[3].body?this.data[3].body:'';
      this.apiinput.link_text4   = (this.data[3].title  && this.data[3].title!='NA')?this.data[3].title:'';
      this.apiinput.link_url4   = this.data[3].link_url?this.data[3].link_url:'';

     }
  }
  ngAfterViewInit(){
   
    setInterval(()=>{
      this.loadingStep = true;
     
    },10);
  
  }

  goForward(stepper: MatStepper,step) {
   // console.log("stepper",stepper);
    //stepper.next();
    if (step == "step1") {
      if (this.apiinput.heading1 == "" || this.apiinput.summary1 == "")
        return false;
      else stepper.next();
    }
    else if (step == "step2") {
      if (this.apiinput.heading2 == "" || this.apiinput.summary2 == "")
        return false;
      else stepper.next();
    }
    else if (step == "step3") {
      if (this.apiinput.heading3 == "" || this.apiinput.summary3 == "")
        return false;
      else stepper.next();
    }
    else if (step == "step4") {
      if (this.apiinput.heading4 == "" || this.apiinput.summary4 == "")
        return false;
      else{
        console.log("api call")
        //
        let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));

        const myPromise1 = new Promise((resolve, reject) => {
          let formData = new FormData(); 
          formData.append('action', "editContent");
          formData.append('body', this.apiinput.summary1);
          formData.append('id', this.data[0].id);
          formData.append('heading', this.apiinput.heading1);
          formData.append('title', this.apiinput.link_text1);
          formData.append('link_url', this.apiinput.link_url1);
          formData.append('wwid', userInfo.tokenUserInfo.nickname);
  
          this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
            if (result.Message == "Updated successfully") {
              console.log("success...");
              resolve("success")
            } 
            else {
             console.log("error...");
             reject("error")
            }
          });
        });


        

        const myPromise2 = new Promise((resolve, reject) => {
          let formData = new FormData(); 
          formData.append('action', "editContent");
          formData.append('body', this.apiinput.summary2);
          formData.append('id', this.data[1].id);
          formData.append('heading', this.apiinput.heading2);
          formData.append('title', this.apiinput.link_text2);
          formData.append('link_url', this.apiinput.link_url2);
          formData.append('wwid', userInfo.tokenUserInfo.nickname);
  
          this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
            if (result.Message == "Updated successfully") {
              console.log("success...");
              resolve("success")
            } 
            else {
             console.log("error...");
             reject("error")
            }
          });
        });

        const myPromise3 = new Promise((resolve, reject) => {
          let formData = new FormData(); 
          formData.append('action', "editContent");
          formData.append('body', this.apiinput.summary3);
          formData.append('id', this.data[2].id);
          formData.append('heading', this.apiinput.heading3);
          formData.append('title', this.apiinput.link_text3);
          formData.append('link_url', this.apiinput.link_url3);
          formData.append('wwid', userInfo.tokenUserInfo.nickname);
  
          this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
            if (result.Message == "Updated successfully") {
              console.log("success...");
              resolve("success")
            } 
            else {
             console.log("error...");
             reject("error")
            }
          });
        });

        const myPromise4 = new Promise((resolve, reject) => {
          let formData = new FormData(); 
          formData.append('action', "editContent");
          formData.append('body', this.apiinput.summary4);
          formData.append('id', this.data[3].id);
          formData.append('heading', this.apiinput.heading4);
          formData.append('title', this.apiinput.link_text4);
          formData.append('link_url', this.apiinput.link_url4);
          formData.append('wwid', userInfo.tokenUserInfo.nickname);
  
          this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  
            if (result.Message == "Updated successfully") {
              console.log("success...");
              resolve("success")
            } 
            else {
             console.log("error...");
             reject("error")
            }
          });
        });
        this.loader.show();
        Promise.all([myPromise1, myPromise2, myPromise3,myPromise4]).then((values) => {
          console.log(values);
          let suc = values.filter(res=>res == 'success');
          if(suc.length>0){
            this.dialogRef.close('success');
          }else{
            this.dialogRef.close('error');
          }
        });

   //
      }
    }
  }

  stepControl(step) {
    //check if all fields in a step of the publish api form are valid and non empty
    if (step == "step1") {
      if (this.apiinput.heading1 == "" || this.apiinput.summary1 == "")
        return false;
      else return true;
    }
    else if (step == "step2") {
      if (this.apiinput.heading2 == "" || this.apiinput.summary2 == "")
        return false;
      else return true;
    }
    else if (step == "step3") {
      if (this.apiinput.heading3 == "" || this.apiinput.summary3 == "")
        return false;
      else return true;
    }
    else if (step == "step4") {
      if (this.apiinput.heading4 == "" || this.apiinput.summary4 == "")
        return false;
      else return true;
    }
  }

}
