<div class="row my-2">
    <div class="col-lg-8 col-xl-8 col-md-8 col-sm-8 col-8 img-title"> Edit {{data.type}}</div>
    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-4">
        <div class="text-right">
            <mat-icon (click)="close()" role="img" class="mat-icon notranslate closeBtn material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">cancel</mat-icon>
        </div>
    </div>
</div>
<div class="row bimg my-2">
        <ng-container *ngIf="data.type == 'image'">
    <div class="col-md-4 ">
            <div class="img-placeholder">
                <ng-container *ngIf="data.type=='image'">
                        <img width="200" [src]="fileUrl">
                </ng-container>
                <ng-container *ngIf="data.type=='video'">
                        
                            <video width="200" class="videoprop" controls>
                                    <source [src]="fileUrl" type="video/mp4">
                                  </video>
                </ng-container>
            </div>
    </div>
    <div class="col-md-8 center">
            <div class="file-up w-100" (click)="file.click()">
                    <span>{{uploadLable}}</span>
                    
                   
                            <input type="file" hidden accept="image/*" #file (change)="uploadFile($event)">
    
                    <mat-icon class="cursorClass gray-for-icons up-icon" (click)="file.click()"
                        matTooltip="Upload Media" matTooltipPosition="above">
                        file_upload
                    </mat-icon>
                </div>
    </div>
        </ng-container>
    <ng-container *ngIf="data.type == 'video'">
            <div class="col-md-12 center">
                    <div class="file-up w-100" (click)="file.click()">
                            <span>{{uploadLable}}</span>
                            
                           
                                    <input type="file" hidden accept="video/*" #file (change)="uploadFile($event)">
            
                            <mat-icon class="cursorClass gray-for-icons up-icon" (click)="file.click()"
                                matTooltip="Upload Media" matTooltipPosition="above">
                                file_upload
                            </mat-icon>
                        </div>
            </div>
    </ng-container>
</div>
<div class="row">
    <div class="col-lg-12">
            <p class="text-center" style="color:red">{{message}}</p>
    </div>
    <div class="col-md-12 text-right mt-2">
            
        <button [disabled]="this.selectedFile==''" (click)="submitMedia()" class="btn btn-danger">Save</button>
    </div>
</div>
