<div class="wapper-usecasepop" >
<div class="row">
  
        <div class="col-12">
          <div class="title-pop" >
              <strong>Error Handling</strong>
              <span class="cancel-div float-right">
                  <mat-icon mat-dialog-close class="closeBtn">cancel</mat-icon>
                </span>
              </div>

              <div class="row mt-3">
                    <div class="col-md-12">
                       <mat-form-field id="wapperg" appearance="outline" style="width: 100%;">
                          <mat-label>HTTP Error Code</mat-label>
                          <input matInput [(ngModel)]="apiinput.code">
                      </mat-form-field>
                      
                    </div>
                  </div>
                  <div class="row">
                        <div class="col-md-12">
                           <mat-form-field appearance="outline" style="width: 100%;">
                              <mat-label>Message</mat-label>
                              <textarea rows="4" matInput [(ngModel)]="apiinput.message"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                            <div class="col-md-12">
                               <mat-form-field appearance="outline" style="width: 100%;">
                                  <mat-label>Error Response</mat-label>
                                  <textarea rows="4" matInput [(ngModel)]="apiinput.response"></textarea>
                              </mat-form-field>
                            </div>
                          </div>
        </div>
    
      </div>

      <div class="row">
            <div class="col-md-12 text-right">
              <p *ngIf="errmsg!=''">{{errmsg}}</p>
              <a mat-dialog-close class="btn btn-outline-dark">Cancel</a>&nbsp;&nbsp;
              <button *ngIf="this.data.type=='edit'" [disabled]="checkDisabled()" (click)="update()"   class="btn btn-act">Update</button>
              <button *ngIf="this.data.type=='add'" [disabled]="checkDisabled()" (click)="add()"   class="btn btn-act">Add</button>
            </div>
          </div>
</div>

