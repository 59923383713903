import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddEditPopComponent } from './components/add-edit-pop/add-edit-pop.component';
import { LoginService } from 'src/app/account/http/login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AboutApiService } from './http/about-api.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { EditHeaderPopComponent } from './components/edit-header-pop/edit-header-pop.component';
import { DataShareService } from 'src/app/core/services/data-share.service';
const moment = require('moment-timezone');
moment.tz.setDefault("America/New_York");
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutContent: any;
  isLoggedIn: boolean;
  readMoreIndex;
  isReadMore = false;
  isAdmin: any = false;
  successFlag = false;
  errorFlag = false;
  alertMsg = "";
  defaultimages = [];
  @ViewChild('alert', { static: false }) alert: ElementRef;
  roleId;
  contents0;
  contents1;
  contents2;
  contents3;
  contents3short = [];
  contents3loop = [];
  isShowMore = false;
  isEditClicked = {sec1:false,newData:""}
  userInfo;
  themeValue;
  constructor(public dialog: MatDialog,
    private login: LoginService,
    private sanitizer: DomSanitizer,
    private apiService: AboutApiService,
    private loader: LoaderService,
    private dataShare: DataShareService) { }
  
  ngOnInit(): void {
    
    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.isLoggedIn = this.login.isAuthenticated();
    if (localStorage.getItem("sessionUserInfo") != null) {
      
      this.userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
      this.roleId = this.userInfo.roleId;
      //console.log("sessionUserInfo",userInfo)
      if (this.userInfo.role.indexOf('API Portal Admin') > -1) this.isAdmin = true;
    }
    console.log("hi...abot");
    window.scrollTo(0, 0);
    this.getContent()
  }

  backToTop() {
    window.scrollTo(0, 0);
  }
  getContent(){
    this.loader.show();
    let obj = {"action":"get_data"}
    this.apiService.getAboutCMS(obj).subscribe(res=>{
      console.log(res);
      "placeholder_name"
      this.contents2 = [];
      this.contents3 = [];
      this.contents3short = [];
      this.defaultimages = res.default_Img?res.default_Img:[]
       res.about_us_data.forEach(element => {
        if(element.placeholder_name == "about_us_intro"){
          this.contents1 = element;
          console.log("this.contents1.body",this.contents1.body)
          console.log(this.isBase64(this.contents1.body))
          if(this.isBase64(this.contents1.body))
          {
            let hasError = false;
            try {
             decodeURIComponent(escape(window.atob(this.contents1.body)))
           } catch(e) {
             hasError = true
             console.error(e);
           }
           if(!hasError){
            this.contents1.body = decodeURIComponent(escape(window.atob(this.contents1.body)));
            console.log("this.contents1.body...",this.contents1.body)
           }
          }
          if(this.isBase64(this.contents1.body_v2))
          {
            let hasError = false;
            try {
             decodeURIComponent(escape(window.atob(this.contents1.body_v2)))
           } catch(e) {
             hasError = true
             console.error(e);
           }
           if(!hasError){
            this.contents1.body_v2 = decodeURIComponent(escape(window.atob(this.contents1.body_v2?this.contents1.body_v2:'')));
           // console.log("this.contents1.body...",this.contents1.body)
           }
          }else{
            this.contents1.body_v2 = this.contents1.body_v2?this.contents1.body_v2:'';
          }
        }
        if(element.placeholder_name == "about_us_feature"){
  
          if(this.isBase64(element.heading))
          {
            let hasError = false;
            try {
             decodeURIComponent(escape(window.atob(element.heading)))
           } catch(e) {
             hasError = true
             console.error(e);
           }
           if(!hasError){
            element.heading = decodeURIComponent(escape(window.atob(element.heading)));
            //console.log("this.contents1.body...",this.contents1.body)
           }
          }
          if(this.isBase64(element.heading_v2))
          {
            let hasError = false;
            try {
             decodeURIComponent(escape(window.atob(element.heading_v2)))
           } catch(e) {
             hasError = true
             console.error(e);
           }
           if(!hasError){
            element.heading_v2 = decodeURIComponent(escape(window.atob(element.heading_v2)));
            //console.log("this.contents1.body...",this.contents1.body)
           }
          }
          this.contents2.push(element);
        }
        if(element.placeholder_name == "about_us_api_release"){
          let date = new Date(element.updated_date);
          element.updated_date = moment(date).format('DD.MM.yyyy');
          this.contents3.push(element);
          if(this.contents3short.length<3)
          {
            this.contents3short.push(element);
          }
          
        }
        if(element.placeholder_name == "about_us_header_subtitle"){
          this.contents0 = element;
        }
       });
       this.loader.hide();
       this.contents2 = this.contents2.sort((a, b) => a.order - b.order)
       this.contents3loop = this.contents3short;
     },error=>{
      this.loader.hide();
      this.errorFlag = true;
      this.alertMsg = error.message;
      console.log(error);
     })
  }


  isBase64(base64Data) {
    var str = base64Data;
    
    try {
      if (str ==='' || str.trim() ===''){ return false; }

      var base64Rejex = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
      var isBase64Valid = base64Rejex.test(base64Data); // base64Data is the base64 string
      if(isBase64Valid){
        return btoa(atob(str)) == str;
      }else{
        return false;
      }
        
    } catch (err) {
        return false;
    }
  }
  
  openHeaderDialog(data){
    const dialogRef = this.dialog.open(EditHeaderPopComponent,{
     // width: '40vw',
      panelClass: 'custom-modalbox-abouth',
      data: {wwid:this.userInfo.tokenUserInfo.nickname,data:data},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result.status == 'success'){
        this.getContent();
        this.successFlag = true;
        this.alertMsg = result.message;
      }else if(result && result.status == 'error'){
        this.loader.hide();
        this.errorFlag = true;
        this.alertMsg = result.message;
      }
    });
  }

  openAddEditDialog(action,data){
    const dialogRef = this.dialog.open(AddEditPopComponent,{
      panelClass: 'custom-modalbox-about',
      data: {action:action,section:2,wwid:this.userInfo.tokenUserInfo.nickname,data:data,defaultImages:this.defaultimages},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result.status == 'success'){
        this.getContent();
        this.successFlag = true;
        this.alertMsg = result.message;
        this.isShowMore = false;
      }else if(result && result.status == 'error'){
        this.loader.hide();
        this.errorFlag = true;
        this.alertMsg = result.message;
      }
    });
  }



  openAddEditDialogSec2(action,data){
    const dialogRef = this.dialog.open(AddEditPopComponent,{
      panelClass: 'custom-modalbox-about',
      data: {action:action,section:1,wwid:this.userInfo.tokenUserInfo.nickname,data:data},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result.status == 'success'){
        this.getContent();
        this.successFlag = true;
        this.alertMsg = result.message;
      }else if(result && result.status == 'error'){
        this.loader.hide();
        this.errorFlag = true;
        this.alertMsg = result.message;
      }
    });
  }

  decodeHTML(value) {
    if (!!value) {
      let res = value.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"').replace(/&apos;/g, "'");
      return res;
    } else {
      return '';
    }
  }

  encodeHTML(value) {
    if (!!value) {
      return value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");
    } else {
      return '';
    }
  }

  sanitize(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  editSection1(){
    this.isEditClicked.sec1 = !this.isEditClicked.sec1;
    this.isEditClicked.newData = this.contents1.body!=""?this.decodeHTML(this.contents1.body):""; //this.cmsDataIntro[0].body != "" ? this.decodeHTML(this.cmsDataIntro[0].body) : "";
  }
  cancelSection(i) {
    if(i==1)
    {
      this.isEditClicked.newData = "";
      this.isEditClicked.sec1 = false;
    }
  }
  restoreIntro(i){
    if(i==1)
    {
      this.isEditClicked.newData = this.contents1.body_v2!=""?this.decodeHTML(this.contents1.body_v2):"";
      this.isEditClicked.sec1 = false;
      this.updateSection(1);
    }
  }
  updateSection(i){
     if(i==1){
      this.loader.show();
      var str = this.encodeHTML(this.isEditClicked.newData);
      var b64 = btoa(unescape(encodeURIComponent(str)));
     let obj = {"action":"update",
    "placeholder_name":"about_us_intro",
    "title":"Get to know us",
    "heading":"heading",
    "body":b64,
    "order":1,
    "id":this.contents1.id,
    "wwid":this.userInfo.tokenUserInfo.nickname
    }
    this.apiService.getAboutCMS(obj).subscribe(res=>{
     console.log(res);
     this.getContent();
     this.isEditClicked.sec1 = false;
     this.successFlag = true;
     this.alertMsg = "Updated Successfully";
    },error=>{
      this.loader.hide();
      this.errorFlag = true;
      this.alertMsg = error.message;
     console.log(error);
    })
     }
  }

  closeAlert() {
    this.successFlag = false;
    this.errorFlag = false;
    this.alertMsg = "";
    this.alert.nativeElement.classList.remove('show');
  }

  readMore(i){
    this.readMoreIndex = i;
    this.isReadMore = true;
  }
  readLess(i){
    this.readMoreIndex = i;
    this.isReadMore = false;
  }
  showMore(){
    this.contents3loop = this.contents3
    this.isShowMore = true;
  }

}
