import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  @Input() responsiveOptions: any;
  @Input() opts: any;

  carouselData: any;
  dataSource;
  themeValue;
  constructor(private dataShare: DataShareService) { }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.carouselData = [];

    if(this.opts.screen == "carousel"){
      this.carouselData = Array.from(Array(4).keys());
    }
    else if(this.opts.screen == "grids"){
      this.carouselData = Array.from(Array(this.responsiveOptions.columns * 2).keys());
    }
    else if(this.opts.screen == "api-list"){
      this.carouselData = Array.from(Array(8).keys());
    }
    else if(this.opts.screen == "table"){
      this.carouselData = Array.from(Array(15).keys());
      this.dataSource = new MatTableDataSource(this.carouselData);
    }
    else if(this.opts.screen == "notification"){
      this.carouselData = Array.from(Array(8).keys());
    }

  }

}

export enum TableHeaders{
  api_req_number= "Request ID",
  assetId= "Asset ID",
  valueStream= "API Category",
  businessUnit= "Business Unit",
  environment= "Environment",
  status_id= "Access Status",
  actions= "Actions",
};
