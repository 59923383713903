import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisplayAppService {

  constructor(private http: HttpClient, private handler: HttpBackend) { }

  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

  // exchange services

  // table data
  getExchangeList(): Observable<any> {
    let url = environment.apiHostUrl + '/get_exchange_applications';
    return this.http.post(url, { "action": "get_exc_app" })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          }
        )
      )
  }
  
  getLeanIXBaseUrl() : Observable<any> {
    let url = environment.apiHostUrl + '/search/master-data';
    return this.http.post(url, { action: "get_config_list" })
    .pipe(
      map(response => {
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        }
      )
    )
  }

  // Exchange api & env details
  getExchangeAppDetails(body): Observable<any> {
    let url = environment.apiHostUrl + '/get_exchange_applications';
    return this.http.post(url, body)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          }
        )
      )
  }
  
  // Exchange owner details
  getExchangeOwners(body): Observable<any> {
    let url = environment.apiHostUrl + "/get_exchange_applications";

    return this.http.post(url, body)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  // get excel data for better performance
  getExchangeExcelData() : Observable<any> {
    let url = environment.apiHostUrl + '/get_exchange_applications';
    return this.http.post(url, { "action": "get_exc_env_api_excel" })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          }
        )
      )
  }

  // being used from admin section
  
  // mapping services table
  getMappingList() : Observable<any> {
    let url = environment.apiHostUrl + '/get_exchange_applications';
    return this.http.post(url, { "action": "get_exc_app" })
    .pipe(
      map(response => {
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        }
      )
    )
  }

  getAppDevelopers() : Observable<any> {
    let url = environment.apiHostUrl + '/search/master-data';
    return this.http.post(url, { action: "get_config_list" })
    .pipe(
      map(response => {
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        }
      )
    )
  }

  //mapping api service
  getAppList() : Observable<any> {
    return this.http.get(environment.apiHostUrl + "/search/get-application-list")
    .pipe(
      map(response => {
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        }
      )
    )
  }

  //submit services
  submit(reqBody) : Observable<any> {
    let url = environment.apiHostUrl + '/get_exchange_applications';
    return this.http.post(url, reqBody)
    .pipe(
      map(response => {
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        })
    );
  }


  
}
