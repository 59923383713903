import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Inject } from '@angular/core';
// import { ReviewSubmitComponent } from '../review-submit/review-submit.component';
import { SupportModel } from '../../models/support.model';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { NgModel, UntypedFormControl } from '@angular/forms';
import { LoginService } from 'src/app/account/http/login.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SupportDialogComponent implements OnInit {
  //isCaptchaValid:boolean = false;
  errmsg = "";
  supportModel: SupportModel = new SupportModel()
  appName: string = "";
  isLoggedIn;
  appId: string = "";
  qTitle: string = "";
  qName: string = "";
  errmss = "";
  showApiList = false;
  action1: string = "";
  action2: string = "";
  action3: string = "";
  bugTitle: string = "";
  desc: string = "";
  questype: string = "";
  related: string = ""; 
  apiname: string = "";
  supportTitle: string = "";
  supportDesc: string = "";
  submittedf1 = false;
  successFlag: boolean = false;
  alertMsg: string = "";
  errorFlag: boolean = false;
  cumminsLoader: boolean = false;
  attachedFileb64;
  user;
  externalUser: boolean = false; 
  action: string = 'step1';
  subAction: string = 'ask_question';
  apiList = [];
  attachmentFile;
  //subAction: string = 'report_bug';
  //subAction: string = 'tehnical_support';
  respMessage: string = "Request is not submitted, something went wrong";
  resStatus: boolean = false;
  flagSuccess: boolean = false;
  flagError: boolean = false;
  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl('');
  @ViewChild('alert', { static: false }) alert: ElementRef;
  themeValue;
  quest = { type: "", quest: "", desc: "" };
  questionOptions = ["API Portal", "API Usage"];

  constructor(public dialogRef: MatDialogRef<SupportDialogComponent>,
    private dataShare: DataShareService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiInfoService: ApiInformationService,
     private login: LoginService,
     private loader: LoaderService) {
   // this.action1 = "ask_question";
    //this.action2 = "report_bug";
    this.action3 = "technical_support";

  }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.onClear();
    this.user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    if(this.user.tokenUserInfo['nickname'].includes("@")){
      this.externalUser= true;
      console.log("external userinside if  ",this.externalUser)
    }
    if (this.data.action == "edit-faq") {
      this.quest.type = this.data.type == "portal" ? "API Portal" : "API Usage";
      this.quest.quest = this.data.faq.heading;
      this.quest.desc = this.data.faq.body.replace(/<\/p>/g, "").replace(/<p>/g, "");
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
       );
console.log("data....",this.data)
  }

  approve(message) {
    this.dialogRef.close({
      datamsg: message, message: "", flagSuccess: true, flagerror: false
    });
  }

  reject(message) {
    this.dialogRef.close({
      datamsg: message, message: "", flagSuccess: false, flagerror: true
    });
  }

  close(message) {
    this.dialogRef.close({
      datamsg: message, message: "close", flagSuccess: false, flagerror: false
    });
  }

  goNext(action) {
    
    this.submittedf1 = true;
      this.action = 'step2';
      this.subAction = action;
  }

  goBack(action: string) {
    this.action = 'step1';
    //this.isCaptchaValid = false;
  }


  onClear() {
    this.supportModel.Description = '';
    this.supportModel.Title = '';
    this.related = '';
    this.apiname = '';
    this.attachmentFile = null;
  }

  reviewSubmit(subAction) {
    //debugger;
    var reqBody = {}
    if(this.externalUser && this.questype=='technical_support'){
       reqBody = {
        "action": this.questype,
        "initiator_application": "",
        "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
        "wwid": "SH157",
        "initiator_wwid": "SH157",
        "related_support": this.related,
        "description": this.supportModel.Description+" Name: "+this.user.name+" Email: "+this.user.email,
        "short_description": this.supportModel.Title,
        "api_name": this.apiname,
        "file_details": this.attachmentFile?{
          "base": this.attachedFileb64?this.attachedFileb64:"",
          "file_name": this.attachmentFile.name,
          "file_type": this.attachmentFile.type,
        }:{},
        "contact_wwid": "SH157",
        "preferred_contact_number": "",
        "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
        "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
        "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
      }
    }
    else if(this.questype=='technical_support'){
      reqBody = {
        "action": this.questype,
        "initiator_application": "",
        "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
        "wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "initiator_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "related_support": this.related,
        "description": this.supportModel.Description,
        "short_description": this.supportModel.Title,
        "api_name": this.apiname,
        "file_details": this.attachmentFile?{
          "base": this.attachedFileb64?this.attachedFileb64:"",
          "file_name": this.attachmentFile.name,
          "file_type": this.attachmentFile.type,
        }:{},
        "contact_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "preferred_contact_number": "",
        "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
        "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
        "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
      }
    }
    else{
       reqBody = {
        "action": this.questype,
        "initiator_application": "",
        "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
        "wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "initiator_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "related_support": this.related,
        "description": this.supportModel.Description,
        "short_description": this.supportModel.Title,
        "api_name": this.apiname,
        "file_details": this.attachmentFile?{
          "base": this.attachedFileb64?this.attachedFileb64:"",
          "file_name": this.attachmentFile.name,
          "file_type": this.attachmentFile.type,
        }:{},
        "contact_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
        "preferred_contact_number": "",
        "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
        "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
        "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
      }
    }
    
    this.saveSupport(reqBody);

  }

  public saveSupport(reqBody) {
    this.cumminsLoader = true;
    this.apiInfoService.getIncidentMgmt(reqBody).subscribe(res => {
      this.cumminsLoader = true;

      if (res.Message) {
        if (res.Message.includes('email sent successfully')) {
          this.approve("Your question is submitted successfully. We will get back to you at the earliest.");
        } else if (res.Message.includes('Incident has been created successfully')) {

          if (this.subAction == 'report_bug') {
            this.approve("Incident: " + res.IncidentNumber + " has been created for the reported issue. Kindly note down the incident number for future correspondence.");
          }
          else {
            this.approve("Incident: " + res.IncidentNumber + " has been created for technical assistance. Kindly note down the incident number for future correspondence.")
          }
        } else {
          this.reject("Something went wrong...!")
        }
      }
      else {
        this.reject(res.message);
      }
      this.cumminsLoader = false;
    },
    );
  }

  closeAlert() {
    this.flagSuccess = false;
    this.flagError = false;
    this.data = "";
    this.alert.nativeElement.classList.remove('show');
  }

  clearFaq() {
    this.quest = { type: "", desc: "", quest: "" };
  }

  checkFaq() {
    if (this.quest.type == "" || this.quest.desc == "" || this.quest.quest == "" 
    || this.quest.quest.trim() == "" || this.quest.desc.trim() == ""  )
      return true;
    else return false;
  }

  deleteFaq() {
    // if(this.isCaptchaValid == true)
    // {
      this.dialogRef.close({
        action: "deleted"
      });
    // }else{
    //   this.errmsg = "Please enter valid captcha."
    // }

  }

  deleteFaqwc() {
      this.dialogRef.close({
        action: "deleted"
      });
  }
  restoreFaq(){
    this.quest.type = (this.data.faq.title_v2 && this.data.faq.title_v2=='Portal FAQs')?"API Portal":"API Usage"
    this.quest.quest = this.data.faq.heading_v2?this.data.faq.heading_v2:'';
    this.quest.desc = this.data.faq.body_v2?this.data.faq.body_v2:'';
    this.submitFaq();
  }


  submitFaq() {
    
    if(!this.checkFaq())
    {
      this.errmsg = "";
      this.cumminsLoader = true;
      let req;
      if (this.data.action == "new-faq") {
        req = {
          action: "addFAQ",
          screen: "support",
          link_url:"",
          placeholder_name: this.quest.type == "API Portal" ? "support_portalfaqs" : "support_usagefaqs",
          title: this.quest.type == "API Portal" ? "Portal FAQs" : "Usage FAQs",
          heading: this.quest.quest,
          body: this.quest.desc,
          order: this.quest.type == "API Portal" ? this.data.portalQuests + 1 : this.data.usageQuests + 1,
          wwid: JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname
        }
      }
      else if (this.data.action == "edit-faq") {
        req = {
          action: "editFAQ",
          screen: "support",
          link_url:"",
          placeHolder: this.quest.type == "API Portal" ? "support_portalfaqs" : "support_usagefaqs",
          title : this.quest.type == "API Portal" ? "Portal FAQs" : "Usage FAQs",
          heading: this.quest.quest,
          body: this.quest.desc,
          wwid: JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
          id: this.data.faq.id
        }
      }
  
      this.apiInfoService.getFaqData(req).subscribe(res => {
        //this.loader.hide();
       // this.dataShare.doCaptchaRefresh();
        if (res.Message && res.Message.includes("success"))
          this.dialogRef.close({ action: "success" });
  
        else this.dialogRef.close({ action: "failed" });
      });
    }else{
      this.errmsg = "Something went wrong."
    }
  }

  // isCaptchaValidFun(ev){
  //   if(ev == "true"){ 
  //     console.log(ev)
  //     this.isCaptchaValid = true;
  //   }else{
  //     this.isCaptchaValid = false;
  //   }
  //   //console.log(ev)
  // }

  switchCat(){
    if(this.related == "API related"){
     this.showApiList = true;
     this.getAllAPIs();
    }
  }

  switchQuesType(){
   //this.questype;
  }

  _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.apiList.filter(option => option.api_name.toLowerCase().includes(filterValue));
  
}

  getAllAPIs(){
    this.loader.show();
    this.isLoggedIn = this.login.isAuthenticated();

    let req = { loggedIn: this.isLoggedIn }

    if (this.isLoggedIn == true) {
      let rawUser = localStorage.getItem("sessionUserInfo");
      this.user = JSON.parse(rawUser);

      if(this.user.tokenUserInfo['nickname'].includes("@")){
        this.externalUser= true;
       // console.log("external userinside if  ",this.externalUser)
      }
      req["external"] = this.externalUser;
      req["wwid"] = this.user.tokenUserInfo['nickname'];
      req["email_id"] = JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email;
    }

    this.apiInfoService.getApiList(req).subscribe(res => {
      this.apiList = res.api_list;
      this.loader.hide();
     // console.log(this.apiList[0])
    },err=>{
      this.loader.hide();
    });

  }

  
  
  uploadfile(ev) {
    this.errmss = "";
    console.log(ev.target.files[0]);
    let file = ev.target.files[0];
    if (file.size > 2097152) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "File size must under 2MB!";
      return;
    }
    if (!(file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('pdf') || file.type.includes('msword') || file.type.includes('openxmlformats') || file.type.includes('text'))) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "Only .png .jpg .pdf .doc .xlsx .txt format will be accepted.";
      return;
    }
    this.attachmentFile = file;
    this.getBase64(file).then(
      data => {
        //console.log(data);
        this.attachedFileb64 = data;
      }
    );

  }

   getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
