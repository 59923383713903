import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSpace'
})
export class ReplaceSpacePipe implements PipeTransform {

  transform(value: any): any {
    //console.log("hello........",value)
    if(!value || value == "")
      return value;
    
      let val = value.split(/\s/g).join(" ");
     // console.log("val........",val)
      return val;
  }

}
