import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/components/login/login.component';
import { LogoutComponent } from './account/components/logout/logout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ShellComponent } from './main/shell.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { PreloadAllModules } from '@angular/router';
import { FaqComponent } from './main/support/components/faq/faq.component';
import { RegisterUserComponent } from './account/components/register-user/register-user.component';
import { BrowserMessageComponent } from './core/components/browser-message/browser-message.component';
import { ComingSoonComponent } from './core/components/coming-soon/coming-soon.component';
// import { AboutUsComponent } from './main/about-us/about-us.component';
// import { CaptchaComponent } from './core/components/captcha/captcha.component';
import { GlobalsearchComponent } from './main/globalsearch/globalsearch.component';
import { CustomPreloadingStrategyService } from './core/services/custom-preloading-strategy.service';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { BlankComponent } from './core/components/blank/blank.component';
// import { UserManagementComponent } from './main/supplier/user-management/user-management.component';
import {MyDashboardComponent} from './main/my-dashboard/my-dashboard.component'




const routes: Routes = [
  {
    path: 'web',
    component: ShellComponent,
    children: [
      { path: 'api', loadChildren: () => import('./main/api/api.module').then(m => m.ApiModule) },
      { path: 'api-configuration', loadChildren: () => import('./main/api-config/api-config.module').then(m => m.ApiConfigModule) },
      { path: 'exchange-applications', loadChildren: () => import('./main/display-app/display-app.module').then(m => m.DisplayAppModule) },
      { path: 'is-intake', loadChildren: () => import('./main/isintake/isintake.module').then(m => m.IsIntakeModule) },
      { path: 'is-asset-repo', loadChildren: () => import('./main/is-asset-repo/is-asset-repo.module').then(m => m.IsAssetRepoModule) },
      { path: 'home', data: { preload: true }, loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule) },
      { path: 'settings', loadChildren: () => import('./main/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'support', loadChildren: () => import('./main/support/support.module').then(m => m.SupportModule) },
      { path: 'api-mgmt', loadChildren: () => import('./main/api-management/api-management.module').then(m => m.ApiManagementModule) },
      { path: 'admin', loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule) },
      { path: 'my-dashboard', loadChildren: () => import('./main/my-dashboard/my-dashboard.module').then(m => m.MyDashboardModule) },
      // { path: 'um', loadChildren: () => import('./main/supplier/user-management/user-management.module').then(m => m.UserManagementModule) },      
      { path: 'supplier', data: { preload: true }, loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule) },
      { path: 'supplier-edi', loadChildren: () => import('./main/supplier/admin.module').then(m => m.AdminModule) },
      { path: 'supplier-edi/request-management',  loadChildren: () => import('./main/supplier-management/supplier-management.module').then(m => m.SupplierManagementModule) },
      { path: 'about-us', loadChildren: () => import('./main/about-us/about-us.module').then(m => m.AboutUsModule) },
      { path: 'getting-started', loadChildren: () => import('./main/getting-started/getting-started.module').then(m => m.GettingStartedModule) },
      { path: 'search', component: GlobalsearchComponent },
      { path: 'api-usecases', loadChildren: () => import('./main/usecases/usecases.module').then(m => m.UsecasesModule) },
      { path: 'partner-showcases', loadChildren: () => import('./main/partner-showcases/partner-showcases.module').then(m => m.PartnerShowcasesModule) },
      { path: 'reports', loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'virtual-assistant', loadChildren: () => import('./main/virtual-assistant/virtual-assistant.module').then(m => m.VirtualAssistantModule) },
      { path: 'azure-ad-lookup', loadChildren: () => import('./main/azure-ad-lookup/azure-ad-lookup.module').then(m => m.AzureADLookupModule) },
      { path: 'certificate-dashboard', loadChildren: () => import('./main/certificate-dashboard/certificate-dashboard.module').then(m => m.CertificateDashboardModule) },
      { path: 'utilities', loadChildren: () => import('./main/utilities/utilities.module').then(m => m.UtilitiesModule) },
      { path: 'ops-support', loadChildren: () => import('./main/operational-support/operational-support.module').then(m => m.OperationalSupportModule) },
      { path: 'platform-user-management', loadChildren: () => import('./main/user-management/user-management.module').then(m => m.UserManagementModule) },
      { path: 'self-service', loadChildren: () => import('./main/self-service/self-service.module').then(m => m.SelfServiceModule) },
      { path: 'release-management', loadChildren: () => import('./main/release-management/release-management.module').then(m => m.ReleaseManagementModule) },
      { path: 'ops-data-management', loadChildren: () => import('./main/master-data-configuration/master-data-config.module').then(m => m.MasterDataConfigModule) },
      { path: 'ops-user-management', loadChildren: () => import('./main/ops-user-management/ops-user-management.module').then(m => m.OpsUserManagementModule) },
      { path: 'port-check', loadChildren: () => import('./main/port-check/port-check.module').then(m => m.PortCheckModule) },
      { path: 'coming-soon', component: ComingSoonComponent },
      { path: '**', redirectTo: '/404' }
    ]
  },
  {
    path: 'register',
    component: RegisterUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'browser-not-supported',
    component: BrowserMessageComponent
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '404', component: NotFoundComponent },
  { path: '', component: BlankComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
