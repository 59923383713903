<app-page-header></app-page-header>
<div class="container themeValue" style="min-height: 70vh;">
        <div class="row  py-3">
                <div class="col-md-12 text-center">
                        <br><br><br><br><br><br><br><br>
                        <img src="assets/images/Group 116526.svg" alt="">
                </div>
            </div> 
            <div class="row py-3">
                    <div class="col-md-12 message-container text-center">
                            {{'message.unauthorized' | transloco}} <a [routerLink]="['/web/support/faq']" >API Portal Administrator.</a>
                    </div>
                </div>
</div>

