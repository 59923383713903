<div class="my-2">
        <div class="row"> 
                <div class="col-md-12">
                    <p class="cptcode"><b>{{mainCaptcha}}</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-refresh" aria-hidden="true" (click)="Captcha();"></i>
                    </p> 
                </div>
            </div>
            <div class="row">
                    <div class="col-md-12">
                            <p>
                        <input type="text" class="captcha-inp" [(ngModel)]="txtInput" placeholder="Enter Captcha"  (keyup)="ValidCaptcha();"/>    
                        </p>
                    </div>
            </div>
</div>


   
    
               