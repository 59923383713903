<div  class="newFaqCont">

    <div class="disp-title">
      {{text.mss}}
    </div>
  
    <br>
  
    <div class="btn-wrapper" *ngIf="text.screen == 'a'">
      <button class="btn cumminsRed" (click)="close()">
        OK
      </button>
    </div>
    <div class="btn-wrapper" *ngIf="text.screen == 'b'">
        <button class="btn cumminsWhite" (click)="close()">
          No
        </button>
    
        <button class="btn cumminsRed" (click)="delete()">
         Yes
        </button>
      </div>
  
  </div>
