import { Component, OnInit, Injectable, ElementRef, ViewChild, HostListener, Renderer2, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoginService } from 'src/app/account/http/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';
import { StorageService } from 'src/app/core/services/storage.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { CMSService } from 'src/app/core/cms/cms.service';
import { CMSDataEnum } from 'src/app/core/constants/Constants';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { FullnameValidator } from 'src/app/core/validator/fullname.validator';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SupportModel } from '../../models/support.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
@Injectable()
export class FaqComponent implements OnInit {

  constructor(private serv: ApiInformationService, public dialog: MatDialog,
    private login: LoginService, private router: Router,
    private pageheader: PageHeaderComponent,
    private dataShare: DataShareService,
    private storage: StorageService, private cmsService: CMSService,
    private activatedroute: ActivatedRoute, private loader: LoaderService,
    private renderer: Renderer2) {
    this.resp = { screen: "", cols: "" }
  }

  @ViewChild('alert', { static: false }) alert: ElementRef;
  errmsg:any= "";
  resp;
  data: any = [];
  supportModel: SupportModel = new SupportModel()
  alertRes: string;
  isLoggedIn: boolean;
  cumminsLoader: boolean = false;
  flagSuccess: boolean = false;
  flagError: boolean = false;
  isCaptchaValid: boolean = false;
  isAdmin: boolean = false;
  submittedcontactForm = false;
  showApiList = false;
  questype: string = "";
  related: string = ""; 
  apiname: string = "";
  attachmentFile;
  themeValue;
  supportTitle: string = "";
  supportDesc: string = "";
  user;
  @ViewChild('file') myInputfile: ElementRef;
  externalUser: boolean = false; 
  apiList = [];
  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl('');
  errmss = "";
  attachedFileb64;
  // {
  //   "image": "../assets/images/megaphone.svg",
  //   "description": "I want to report a bug"
  // },
  // {
  //   "image": "../assets/images/administrator.svg",
  //   "description": "I need technical support"
  // }
  images = [
    {
      "image": "../assets/images/faq.svg",
      "description": "I have a question"
    },
   
  ]

  portalQuestions = [];
  usageQuestions = [];

  purposeOptions = [
    "I want to get access to the APIs", "I want to ask a technical question",
    "I want to ask a general question", "I want to report a bug"
  ]

  interestOptions = [];
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    purpose: new UntypedFormControl('', [Validators.required]),
    interest: new UntypedFormControl('', [Validators.required]),
    fullName: new UntypedFormControl('', [Validators.required,FullnameValidator.validate, Validators.pattern(/^[a-zA-Z ]+$/)]),
    emailId: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    companyName: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    description: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    tnC: new UntypedFormControl(false, [Validators.required])
  });

  searchParam: string = "";

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.initCMSData();
    this.onResize();
    this.isLoggedIn = this.login.isAuthenticated();
    this.loader.show();
    this.cmsService.getCMSDataByScreenName('support').then(()=>{
      this.initCMSData();
      this.loader.hide();
    })

    if (this.isLoggedIn) {
      let user = JSON.parse(localStorage.getItem("sessionUserInfo"));
      if (user.role.indexOf('API Portal Admin') > -1)
        this.isAdmin = true;

        this.user = user
        if(this.user.tokenUserInfo['nickname'].includes("@")){
          this.externalUser= true;
          console.log("external userinside if  ",this.externalUser)
        }
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
       );

    this.serv.getCarouselData().subscribe(res => {
      if (res.api_category_list) this.interestOptions = res.api_category_list;
    });

    // setTimeout(() => {
    //   this.loader.hide()
    // }, 1000)

    //open popup if tile was clicked on before login
    if (this.activatedroute.snapshot.queryParamMap.get('pop-up') || localStorage.getItem('support') == 'true') {
      localStorage.setItem('support', 'false');
      let desc = localStorage.getItem("supportDescription");
      this.getAskQuestion(desc);
    }

  }



  closeAlert() {
    this.flagSuccess = false;
    this.flagError = false;
    this.alertRes = "";
    this.alert.nativeElement.classList.remove('show');
  }

  getHome() {
    this.router.navigate(['/web/home']);
  }

  backToTop() {
    window.scrollTo(0, 0);
  }

  goToLogin() {
    this.pageheader.doLogin();
  }

  getAskQuestion(description): void {
    if (this.login.isAuthenticated()) {
      let dialogRef = this.dialog.open(SupportDialogComponent, {
        data: { desc: description }, backdropClass: 'modal-bg',panelClass: 'ask-question-faq',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(req => {
        this.flagSuccess = req.flagSuccess;
        this.flagError = req.flagerror;
        this.alertRes = req.datamsg;
        localStorage.setItem("supportDescription", "");
      });
    } else {
      const redirect = window.location.href;
      this.storage.setInStorage('navigateSupportUrl', redirect);
      localStorage.setItem("support", 'true');
      localStorage.setItem("supportDescription", description);
      localStorage.setItem("openGetAccessModal", 'true');
      this.pageheader.doLogin();
    }
  }

  checkSubmit() {
    if (!this.contactForm.valid || this.contactForm.get('tnC').value == false || !this.isCaptchaValid)
      return true;
    else return false;
  }

  clearContactForm() {
    this.contactForm.setValue({
      fullName: "", companyName: "",
      interest: "", purpose: "",
      emailId: "", description: "",
      tnC: false
    })
  }

  get contactFormControl() {
    return this.contactForm.controls;
  }

  onClickSubmit() {
    
   console.log(this.contactForm)
    if(!this.checkSubmit())
    {
      this.errmsg = "";
      let req = {
        action: "contact_us", purpose: this.contactForm.get("purpose").value,
        interest: this.contactForm.get("interest").value.name, description: this.contactForm.get("description").value,
        email_id: this.contactForm.get("emailId").value, full_name: this.contactForm.get("fullName").value,
        company_name: this.contactForm.get("companyName").value
      }
      
      this.loader.show();
  
      this.serv.getCmsDbData(req).subscribe(res => {
        this.loader.hide();
        this.contactForm.reset();
        this.submittedcontactForm = false;
        this.dataShare.doCaptchaRefresh();
        if (res && res.Message.indexOf("succes")) {
          this.clearContactForm();
          this.flagSuccess = true;
          this.alertRes = "Your query is submitted successfully";
        }
      });
    }else{
      this.submittedcontactForm = true;
      this.contactForm.markAllAsTouched();
    
      
    }
   
  }

  addFaq() {
    let ref = this.dialog.open(SupportDialogComponent, {
      panelClass: 'new-faq', maxHeight: '80vh',
      data: {
        action: "new-faq", portalQuests: this.portalQuestions.length,
        usageQuests: this.usageQuestions.length,
      },
      disableClose: true
    });

    ref.afterClosed().subscribe(res => {
      if (res && res.action == "success") {
        this.alertRes = "FAQ added successfully";
        this.flagSuccess = true;
        this.cmsService.getCMSDataByScreenName('support').then(()=>{
          this.initCMSData();
          //this.loader.hide();
        })
      }
      else if (res && res.action == "failed") {
        this.alertRes = "Failed adding FAQ";
        this.flagError = true;
      }
    });
  }

  editFaq(event, faq, type) {
    event.stopPropagation();

    let ref = this.dialog.open(SupportDialogComponent, {
      panelClass: 'new-faq', maxHeight: '80vh',
      data: { action: "edit-faq", faq: faq, type: type },
      disableClose: true
    });

    ref.afterClosed().subscribe(res => {
      if (res && res.action == "success") {
        this.alertRes = "FAQ edited successfully";
        this.flagSuccess = true;

        //this.updateCms();
        this.cmsService.getCMSDataByScreenName('support').then(()=>{
          this.initCMSData();
          //this.loader.hide();
        })
      }
      else if (res && res.action == "deleted") {
        let req = {
          action: "deleteFAQ", id: faq.id
        }
        this.loader.show();

        this.serv.getFaqData(req).subscribe(res => {
          this.loader.hide();
          if (res && res.Message.includes("success")) {
            this.alertRes = "FAQ deleted successfully";
            this.flagSuccess = true;

            let i = type == "portal" ? this.portalQuestions.findIndex(s => s.id == faq.id) :
            this.usageQuestions.findIndex(s => s.id == faq.id);

            if (type == "usage") this.usageQuestions.splice(i, 1);
            else this.portalQuestions.splice(i, 1);
          }
          else {
            this.alertRes = "FAQ deletion failed";
            this.flagError = true;
          }
        });
      }
      else if (res && res.action == "failed") {
        this.alertRes = "Failed editing FAQ";
        this.flagError = true;
      }
    });
  }

  deleteFaq(event, i, type) {
    event?.stopPropagation();

    let req = {
      action: "deleteFAQ", id: ""
    }

    if (type == "usage") req.id = this.usageQuestions[i].id;
    else req.id = this.portalQuestions[i].id;

    let ref = this.dialog.open(SupportDialogComponent, {
      panelClass: 'new-faq', maxHeight: '80vh',
      data: { action: "delete-faq" },
      disableClose: true
    });

    ref.afterClosed().subscribe(res => {
      

      if (res && res.action == "deleted") {
        this.loader.show();
        this.serv.getFaqData(req).subscribe(res => {
          this.loader.hide();
          if (res && res.Message.includes("success")) {
            this.alertRes = "FAQ deleted successfully";
            this.flagSuccess = true;

            if (type == "usage") this.usageQuestions.splice(i, 1);
            else this.portalQuestions.splice(i, 1);
          }
          else {
            this.alertRes = "FAQ deletion failed";
            this.flagError = true;
          }
        });
      }
    })
  }

  updateCms() {
    this.loader.show();
    this.cmsService.getCms().then(res => {
      this.loader.hide();
      this.initCMSData();
    });
  }

  clearSearch(){ 
    this.searchParam = "";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    ///if (window.innerWidth != this.gridByResolution.screen)
    //  location.reload();
    this.resp.screen = window.innerWidth;
    if (this.resp.screen > '700') {
      this.resp.cols = 1;
    }
    else this.resp.cols = 1;
  }

  private initCMSData() {
    //get faqs from data fetched from db and sort on order
    this.portalQuestions = this.cmsService.getCMSDataByPlaceholderName("support_portalfaqs");
    this.portalQuestions = this.portalQuestions.sort((a, b) => {
      if (a.order > b.order) return 1;
      else if (a.order < b.order) return -1;
      else return 0;
    });

    //clean html tags
    this.portalQuestions.forEach((ele) => {
      ele.heading = this.cmsService.cleanHtmlTag(ele.heading);
    });

    this.usageQuestions = this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.support_usage_faqs);
    this.usageQuestions = this.usageQuestions.sort((a, b) => {
      if (a.order > b.order) return 1;
      else if (a.order < b.order) return -1;
      else return 0;
    });
    this.usageQuestions.forEach((ele) => {
      ele.heading = this.cmsService.cleanHtmlTag(ele.heading);
    });


    this.activatedroute.queryParams.subscribe(params=>{
     if(params && params._id && params._id!=''){
      setTimeout(()=>{
      let sectionYCoord = document.getElementById(params._id).getBoundingClientRect().top + window.scrollY;
      // window.scrollTo(0, sectionYCoord-1);
       window.scrollTo({
        top: sectionYCoord,
        behavior: 'smooth'
      });
       document.getElementById(params._id).click();
      },500)
     }else if(params && params.scto && params.scto!=''){
      setTimeout(()=>{
        let sectionYCoord = document.getElementById(params.scto).getBoundingClientRect().top + window.scrollY;
        // window.scrollTo(0, sectionYCoord-1);
         window.scrollTo({
          top: sectionYCoord,
          behavior: 'smooth'
        });
        },500)
     }
    })
 
  }

  isCaptchaValidFun(ev){
    if(ev == "true"){ 
      console.log(ev)
      this.isCaptchaValid = true;
    }else{
      this.isCaptchaValid = false;
    }
    //console.log(ev)
  }

  switchCat(){
    if(this.related == "API related"){
     this.showApiList = true;
     this.getAllAPIs();
    }else{
      this.showApiList = false;
    }
  }

  _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.apiList.filter(option => option.api_name.toLowerCase().includes(filterValue));
  
}

onClear() {
  this.supportModel.Description = '';
  this.supportModel.Title = '';
  this.related = '';
  this.apiname = '';
  this.questype = '';
  this.showApiList = false;
  this.attachmentFile = null;
  // to clear the file upload cache in case of same file selected in multiple rounds
  $("#fileUpload").val('');
  // $("#fileUpload").replaceWith($("#fileUpload").val('').clone(true));
}

reviewSubmit() {
  //debugger;
  this.loader.show();
  var reqBody = {}
  if(this.externalUser && this.questype=='technical_support'){
   // let des = (this.apiname && this.apiname!='')?this.supportModel.Description+"<br><b>API Name:</b>&nbsp;"+this.apiname+"<br><br> <b>Name:</b>&nbsp;"+this.user.name+"<br> <b>Email:</b>&nbsp;"+this.user.email : this.supportModel.Description+"<br><br> <b>Name:</b>&nbsp;"+this.user.name+"<br> <b>Email:</b>&nbsp;"+this.user.email;
   let des = this.supportModel.Description;
   reqBody = {
      "action": this.questype,
      "initiator_application": "",
      "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
      "wwid": "SH157",
      "initiator_wwid": "SH157",
      "related_support": this.related,
      "description": des,
      "short_description": this.supportModel.Title,
      "api_name": this.apiname,
      "file_details": this.attachmentFile?{
        "base": this.attachedFileb64?this.attachedFileb64:"",
        "file_name": this.attachmentFile.name,
        "file_type": this.attachmentFile.type,
      }:{},
      "contact_wwid": "SH157",
      "preferred_contact_number": "",
      "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
      "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
      "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
    }
  }
  else if(this.questype=='technical_support'){
   // let des = (this.apiname && this.apiname!='')?this.supportModel.Description+"<br><b>API Name:</b>&nbsp;"+this.apiname : this.supportModel.Description;
   let des = this.supportModel.Description;
   reqBody = {
      "action": this.questype,
      "initiator_application": "",
      "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
      "wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "initiator_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "related_support": this.related,
      "description": des,
      "short_description": this.supportModel.Title,
      "api_name": this.apiname,
      "file_details": this.attachmentFile?{
        "base": this.attachedFileb64?this.attachedFileb64:"",
        "file_name": this.attachmentFile.name,
        "file_type": this.attachmentFile.type,
      }:{},
      "contact_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "preferred_contact_number": "",
      "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
      "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
      "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
    }
  }
  else{
     reqBody = {
      "action": this.questype,
      "initiator_application": "",
      "application_email_group": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email,
      "wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "initiator_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "related_support": this.related,
      "description": this.supportModel.Description,
      "short_description": this.supportModel.Title,
      "api_name": this.apiname,
      "file_details": this.attachmentFile?{
        "base": this.attachedFileb64?this.attachedFileb64:"",
        "file_name": this.attachmentFile.name,
        "file_type": this.attachmentFile.type,
      }:{},
      "contact_wwid": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.nickname,
      "preferred_contact_number": "",
      "first_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.familyName,
      "last_name": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.givenName,
      "email_id": JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email
    }
  }
  
  this.saveSupport(reqBody);

}

public saveSupport(reqBody) {
  this.cumminsLoader = true;
  this.myInputfile.nativeElement.value = '';
  this.serv.getIncidentMgmt(reqBody).subscribe(res => {
    this.cumminsLoader = true;
   console.log("res...",res)
   console.log("res...a",res["incident Number"])
    if (res.Message || res["incident Number"]) {
      console.log("op1")
      if (res.Message && res.Message.includes('email sent successfully')) {
        this.flagSuccess = true;
        this.flagError = false;
        this.onClear();
        this.alertRes = "Your question is submitted successfully. We will get back to you at the earliest.";
        localStorage.setItem("supportDescription", "");
        this.loader.hide();
      } else if (res && res["incident Number"]) {
        console.log('pp')
        this.flagSuccess = true;
        this.flagError = false;
        this.onClear();
        this.alertRes = "Incident: " + res["incident Number"] + " has been created for technical assistance. Kindly note down the incident number for future correspondence.";
        this.loader.hide();
      } else {
        this.flagSuccess = false;
        this.flagError = true;
        this.onClear();
        this.alertRes = "Something went wrong...!";
        this.loader.hide();
      }
    }
    else {
      console.log("op2")
      this.flagSuccess = false;
      this.flagError = true;
      this.onClear();
      this.alertRes = res.message;
      this.loader.hide();
    }
    this.cumminsLoader = false;
  },
  );
}

  getAllAPIs(){
    this.loader.show();
    this.isLoggedIn = this.login.isAuthenticated();

    let req = { loggedIn: this.isLoggedIn }

    if (this.isLoggedIn == true) {
      let rawUser = localStorage.getItem("sessionUserInfo");
      this.user = JSON.parse(rawUser);

      if(this.user.tokenUserInfo['nickname'].includes("@")){
        this.externalUser= true;
       // console.log("external userinside if  ",this.externalUser)
      }
      req["external"] = this.externalUser;
      req["wwid"] = this.user.tokenUserInfo['nickname'];
      req["email_id"] = JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email;
    }

    this.serv.getApiList(req).subscribe(res => {
      this.apiList = res.api_list;
      this.loader.hide();
     // console.log(this.apiList[0])
    },err=>{
      this.loader.hide();
    });

  }

  switchQuesType(){
    //this.questype;
   }

   uploadfile(ev) {
    this.errmss = "";
    console.log(ev.target.files[0]);
    let file = ev.target.files[0];
    if (file.size > 2097152) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "File size must under 2MB!";
      return;
    }
    if (!(file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg') || file.type.includes('pdf') || file.type.includes('msword') || file.type.includes('openxmlformats') || file.type.includes('text'))) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "Only .png .jpg .pdf .doc .xlsx .txt format will be accepted.";
      return;
    }
    this.attachmentFile = file;
    this.getBase64(file).then(
      (data:any) => {
        //console.log(data);
        let str = data;
        str = str.replace("data:" + file.type + ";base64,", "");
        this.attachedFileb64 = str;
      }
    );

  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


}