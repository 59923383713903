import { NgModule } from '@angular/core';
import { AboutUsComponent } from './about-us.component';
import { AboutUsRoutingModule } from './about-us-routing.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MaterialModule } from 'src/app/core/modules/material.module';
import { TranslocoRootModule } from 'src/app/core/transloco/transloco-root.module';
import { AddEditPopComponent } from './components/add-edit-pop/add-edit-pop.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { EditorModule } from 'primeng/editor';
import { EditHeaderPopComponent } from './components/edit-header-pop/edit-header-pop.component';



@NgModule({
  declarations: [AboutUsComponent, AddEditPopComponent, EditHeaderPopComponent],
  imports: [
    CommonModule,
    SharedModule,
    AboutUsRoutingModule,
     MaterialModule,
    TranslocoRootModule,
    MatDialogModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    EditorModule
  ]
})
export class AboutUsModule { constructor(){console.log("called.....")} }
