<div class="cls-faq {{themeValue}}">

    <div class="faq-upper-cont">
        <div class="container-support ">
            <svg-icon [svgStyle]="{ 'width.px':70, 'height.px': 70}" class="support-baner-svg"
                src="assets/images/support-main.svg">
            </svg-icon><br><br>
            <span class="title-banner custom-container">
                {{'faq.title' | transloco}}
            </span>

        </div>

        <div class="contact-tickets custom-container">

            <div class="breadcrumb-list">
                <a class="breadcrumb-item" [routerLink]="['/web/home']">Home</a>
                <div class="breadcrumb-connector">></div>
                <div class="breadcrumb-last">Support</div>
            </div>

            <div class="contact-container" *ngIf="!isLoggedIn">

                <div class="faq-title mont-bold"> Contact us </div>

                <div class="row" [formGroup]="contactForm">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 contact-form">

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.purpose' | transloco}}</mat-label>

                            <mat-select name="role" formControlName="purpose">
                                <mat-option *ngFor="let purp of purposeOptions" [value]="purp">
                                    {{purp}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="(contactFormControl.purpose.touched || submittedcontactForm) && contactFormControl.purpose.errors?.required">
                                This field is required.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.interests' | transloco}}</mat-label>

                            <mat-select name="role" formControlName="interest">
                                <mat-option *ngFor="let int of interestOptions" [value]="int">
                                    {{int.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="(contactFormControl.interest.touched || submittedcontactForm) && contactFormControl.interest.errors?.required">
                                This field is required.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.fullName' | transloco}}</mat-label>
                            <input matInput formControlName="fullName">

                            <mat-error
                                *ngIf="(contactFormControl.fullName.touched || submittedcontactForm) && contactFormControl.fullName.errors?.required">
                                This field is required.</mat-error>
                            <mat-error
                                *ngIf="(contactFormControl.fullName.touched || submittedcontactForm) && contactFormControl.fullName.errors?.pattern">
                                Enter valid value.</mat-error>
                            <mat-error
                                *ngIf="(contactFormControl.fullName.touched || submittedcontactForm) && contactFormControl.fullName.errors?.fullNameInvalid && !contactFormControl.fullName.errors?.pattern">
                                Enter your fullname.</mat-error>

                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.email' | transloco}}</mat-label>
                            <input matInput formControlName="emailId">
                            <mat-error
                                *ngIf="(contactFormControl.emailId.touched || submittedcontactForm) && contactFormControl.emailId.errors?.required">
                                This field is required.</mat-error>
                            <mat-error
                                *ngIf="(contactFormControl.emailId.touched || submittedcontactForm) && contactFormControl.emailId.errors?.pattern">
                                Enter valid value.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.companyName' | transloco}}</mat-label>
                            <input matInput formControlName="companyName">
                            <mat-error
                                *ngIf="(contactFormControl.companyName.touched || submittedcontactForm) && contactFormControl.companyName.errors?.required">
                                This field is required.</mat-error>
                            <mat-error
                                *ngIf="(contactFormControl.companyName.touched || submittedcontactForm) && contactFormControl.companyName.errors?.pattern">
                                Enter valid value.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ 'faq.concern' | transloco}}</mat-label>
                            <textarea matInput required rows="4" style="resize: none;" maxlength="150"
                                formControlName="description"></textarea>
                            <mat-error
                                *ngIf="(contactFormControl.description.touched || submittedcontactForm) && contactFormControl.description.errors?.required">
                                This field is required.</mat-error>
                            <mat-error
                                *ngIf="(contactFormControl.description.touched || submittedcontactForm) && contactFormControl.description.errors?.pattern">
                                Enter valid value.</mat-error>
                        </mat-form-field>

                        <app-captcha (onValidCaptcha)="isCaptchaValidFun($event)"></app-captcha>
                        <mat-error style="margin-top: -10px;" *ngIf="!isCaptchaValid && submittedcontactForm">
                            Please enter valid captcha</mat-error>
                        <mat-checkbox formControlName="tnC">
                            I agree to the
                            <a href="https://www.cummins.com/privacy-and-legal" target="_blank">Terms & Condition</a>
                            and
                            <a href="https://www.cummins.com/privacy-and-legal" target="_blank">Privacy Policy</a>
                        </mat-checkbox>
                        <mat-error
                            *ngIf="(contactFormControl.tnC.touched || submittedcontactForm) && contactFormControl.tnC.value == false">
                            This field is required.</mat-error>
                        <!-- <p style="color:#DA291C" *ngIf="errmsg!=''">{{errmsg}}</p> -->
                        <br><br>
                        <div class="btns-wrapper">
                            <button class="btn cumminsWhite" (click)="clearContactForm()"> Clear </button>
                            <button class="btn cumminsRed" (click)="onClickSubmit()"> Submit
                            </button>
                            <!-- [disabled]="checkSubmit()" -->
                        </div>

                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 svg-contact">
                    </div>

                </div>
            </div>
            <div class="contact-container" *ngIf="isLoggedIn">
                <div class="faq-title mont-bold"> Contact us </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 contact-form">
                        <!--  -->
                        <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
                            <mat-label>Category *</mat-label>
                            <mat-select (selectionChange)="switchCat()" [(ngModel)]="related">
                                <mat-option value="Portal related">Portal related</mat-option>
                                <mat-option value="API related">API related</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="showApiList" appearance="outline" style="width: 100%; margin-top: 1%;">
                            <mat-label>API Name *</mat-label>
                            <input type="text" matInput [formControl]="myControl" [(ngModel)]="apiname"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.api_name">
                                    {{option.api_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
                            <mat-label>Request type *</mat-label>
                            <mat-select (selectionChange)="switchQuesType()" [(ngModel)]="questype">
                                <mat-option value="ask_question">I have a question</mat-option>
                                <mat-option value="technical_support">I want to report a bug</mat-option>
                            </mat-select>
                        </mat-form-field>


                        <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
                            <mat-label>Enter summary *</mat-label>
                            <input matInput [(ngModel)]="supportModel.Title" maxlength="100">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Enter description *</mat-label>
                            <textarea matInput [(ngModel)]="supportModel.Description" rows="4" cols="50" maxlength="300"
                                style="resize: none;">
                            </textarea>
                        </mat-form-field>
                        <p class="notmsg">File should be within 2MB, Only accepts .png .jpg .pdf .doc .xlsx .txt</p>
                        <mat-form-field appearance="outline" class="imgwap" style="width: 100%;">
                            <label class="upload-label cursorClass"
                                (click)="file.click()">{{attachmentFile?attachmentFile.name:'Attach File'}}</label>
                            <input matInput class="cursorClass" (click)="file.click()">
                            <input #file id="fileUpload" (change)="uploadfile($event)" style="display: none;"
                                type="file" class="w-100">
                            <mat-icon (click)="file.click()" matSuffix class="upicn">file_upload</mat-icon>
                        </mat-form-field>
                        <p *ngIf="this.errmss!=''" class="errmsg">{{this.errmss}}</p>
                        <!--  -->
                        <br><br>
                        <div class="btns-wrapper">
                            <button class="btn cumminsWhite" (click)="onClear()"> Clear </button>
                            <button class="btn cumminsRed" [disabled]="!supportModel.Description || !supportModel.Title || supportModel.Description === '' ||
                           supportModel.Title === '' ||
                            related === '' || questype === '' ||
                            (related == 'API related' && apiname=='')
                            " (click)="reviewSubmit()"> Submit
                            </button>
                            <!-- [disabled]="checkSubmit()" -->
                        </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 svg-contact">
                    </div>
                </div>
            </div>

            <!-- <mat-grid-list class="faqgrid" [cols]="resp.cols" [gutterSize]=" '2.5rem' " rowHeight="200px"
                *ngIf="isLoggedIn">
                <mat-grid-tile class="grid-tile cursorClass"  *ngFor="let tile of images">

                    <div class="card-body">

        
                        <div (click)="getAskQuestion(tile.description)">
                            <svg-icon src="{{tile.image}}">
                            </svg-icon>
                            <div>
                                <button class="faqbtn">
                                    <p class="faq-desc">{{tile.description}}</p>
                                </button>
                            </div>
                        </div>

                    </div>
                </mat-grid-tile>

            </mat-grid-list> -->

        </div>

    </div>

    <div class="faq-container custom-container" id="faq-containerid">

        <div #alert *ngIf="flagSuccess" class="alert-supp alert alert-success alert-dismissible fade show"
            style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
            <mat-icon (click)="closeAlert()" style="float:right" class="closeBtn">cancel</mat-icon>
            {{alertRes}}
        </div>
        <div #alert *ngIf="flagError" class="alert-supp alert alert-danger alert-dismissible fade show"
            style="padding-right: 20px;" [style.width.px]="container.offsetWidth" role="alert">
            <mat-icon (click)="closeAlert()" style="float:right" class="closeBtn">cancel</mat-icon>
            {{alertRes}}
        </div>

        <div class="faq-title mont-bold" #container>
            <div>{{'faq.faq' | transloco}}</div>

            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'faq.search' | transloco}}</mat-label>
                    <input matInput [(ngModel)]="searchParam">

                    <span matSuffix>
                        <mat-icon *ngIf="!searchParam" style="transform: translateY(4px); cursor: pointer;">
                            search
                        </mat-icon>
                        <span (click)="clearSearch()" *ngIf="searchParam">
                            <svg-icon [svgStyle]="{'width.px':20}" src="assets/images/Clear icon1.svg"></svg-icon>
                        </span>
                    </span>

                </mat-form-field>

                <button class="btn cumminsWhite" (click)="addFaq()" *ngIf="isAdmin">Add New FAQ</button>
            </div>
        </div>

        <div class="sub-title mont-semi my-3 faqportal" *ngIf="(portalQuestions|search:searchParam:'faq').length > 0">
            {{'faq.portal' | transloco}}
        </div>



        <mat-accordion>
            <mat-expansion-panel *ngFor="let rowData of portalQuestions|search:searchParam:'faq'; let i=index"
                (opened)="panelOpenState=true" (closed)="panelOpenState=false" style="margin-bottom: 1px;">
                <mat-expansion-panel-header>

                    <mat-panel-title id="faq{{rowData.id}}" class="mont-semi"> {{rowData.heading}} </mat-panel-title>

                    <mat-panel-description *ngIf="isAdmin">
                        <mat-icon (click)="editFaq($event, rowData, 'portal')">edit</mat-icon>
                        <mat-icon (click)="deleteFaq($event, i, 'portal')">delete</mat-icon>
                    </mat-panel-description>

                </mat-expansion-panel-header>

                <div style="white-space: pre-wrap;" [innerHTML]="rowData.body"></div>

            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <div class="sub-title mont-semi my-3" style="margin-top: 8vh;"
            *ngIf="(usageQuestions|search:searchParam:'faq').length > 0">
            {{'faq.usage' | transloco}}
        </div>



        <mat-accordion>
            <mat-expansion-panel *ngFor="let rowData of usageQuestions|search:searchParam:'faq'; let i=index"
                style="margin-bottom: 1px;" (opened)="panelOpenState=true" (closed)="panelOpenState=false">

                <mat-expansion-panel-header>
                    <mat-panel-title id="faq{{rowData.id}}" class="mont-semi"> {{rowData.heading}} </mat-panel-title>

                    <mat-panel-description *ngIf="isAdmin">
                        <mat-icon (click)="editFaq($event, rowData, 'usage')">edit</mat-icon>
                        <mat-icon (click)="deleteFaq($event, i, 'usage')">delete</mat-icon>
                    </mat-panel-description>

                </mat-expansion-panel-header>

                <div style="white-space: pre-wrap;" [innerHTML]="rowData.body"></div>

            </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="(portalQuestions|search:searchParam:'faq').length==0 && (usageQuestions|search:searchParam:'faq').length==0"
            class="err-cont">

            <div style="text-align: center;">
                <br><br>
                <svg-icon [svgStyle]="{ 'width.px':120, 'height.px':120}" src="assets/images/no-data-found.svg">
                </svg-icon>

                <br>
                <p>No FAQs found for "{{searchParam}}"</p>
            </div>
        </div>

    </div>
</div>