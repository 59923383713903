import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {

  constructor(private router: Router, private serv: ApiInformationService, private store: StorageService,
    private loader: LoaderService) {

    this.roles = [];
    this.bu = [];
  }

  roles = [];
  bu = [];
  selectedRole;
  userDetails;
  selectedSingleBu;
  selectedMultipleBu = [];
  errorFlag = false;
  successFlag = false;
  displayRegister = false;
  currentStatus;
  enteredJustification;
  selectedRoleId = 1;

  closeAlert() {
    this.errorFlag = false;
  }

  formCheck(){
    if(!this.selectedRoleId) return true;
    else{
      // if(this.selectedRole == 2){
      //  if(!this.selectedSingleBu || !this.enteredJustification) return true;
      //  else return false;
      // }
      //else
      if(this.selectedRoleId == 1){
        if(this.selectedMultipleBu.length==0 || !this.enteredJustification) return true;
        else return false;
       }
    }
  }


  register() {
    let bu = [];
    //to check if bu is single or multiple
    if (this.selectedRole == 2)
      bu.push(this.selectedSingleBu);
    else bu = this.selectedMultipleBu;

    let obj = {
      action: "register_user", user_id: this.userDetails['tokenUserInfo']['nickname'],
      email_id: this.userDetails['tokenUserInfo']['email'],
      first_name: this.userDetails['tokenUserInfo']['givenName'],
      last_name: this.userDetails['tokenUserInfo']['familyName'],
      is_internal: 1, role_list: [this.selectedRoleId],
      bu_list: bu,
      bu_justification: this.enteredJustification
    }
    
    this.loader.show();
    this.serv.getRegistrationInfo(obj).subscribe((res) => {
      if (typeof res.message === 'string' && res) {
        if (res.message.includes("success")) {
            this.successFlag = true; 
            this.displayRegister = false;       
        }
        else {
          this.errorFlag = true; 
        }
      }
      else {
        this.errorFlag = true;    
      }
    }).add( () => {
      this.loader.hide();
    });
  }

  ngOnInit(): void {
    console.log('reg');
    //get bu and role list from backend
    this.userDetails = JSON.parse(localStorage.getItem("sessionUserInfo"));
    let statusObj = {
      action: "user_status",
      wwid: this.userDetails['tokenUserInfo']['nickname']
    }

    this.loader.show();
    this.serv.getRegistrationInfo(statusObj).subscribe((res) => {
      if (res) {
        if (res.user_list.length == 0 || res.user_list.slice(-1)[0]["status_id"]==4) {
          //console.log(res.user_list.slice(-1)[0]["status_id"])
          this.displayRegister = true;
          this.errorFlag = false; this.successFlag = false;
          let obj = { action: "get_role_bu" }
          this.serv.getRegistrationInfo(obj).subscribe((res) => {
            if (res) {

              res.business_unit.forEach(bu => {
                //omit NA business unit from DB
                if(bu.group_name!='NA') this.bu.push(bu)
              });
              res.role_list.forEach((role) => {
                //omit default and admin role
                if (role.role_name != "Default" && role.role_name != "API Portal Admin") this.roles.push(role);
                if(role.role_id == 1) this.selectedRoleId = role.role_id;

              });
              console.log(this.selectedRoleId)
              this.bu.sort(function (a, b) {
                return a.group_name.localeCompare(b.group_name);
              });
              this.roles.sort(function (a, b) {
                return a.role_name.toLowerCase().localeCompare(b.role_name);
              });
            }
          });
        }
        else {

          this.currentStatus = res.user_list.slice(-1)[0]["status_id"];
          if(this.currentStatus == 1){
            let link = this.store.getFromStorage('navigateDetailsUrl');
            console.log(link)
            if(link != null) window.location.href = link;
          }
        }
      }
    }).add( () => {
      this.loader.hide();
    })

  }

  goToHome() {
    window.location.href = "web/home"
    // this.router.navigate(['/web/home']);
  }

  toClear(){
    if(this.selectedRole == 2){
      this.selectedMultipleBu = [];
    }
    else if(this.selectedRole == 1){
      this.selectedSingleBu = '';
    }
  }

}
