import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LoginPopupComponent implements OnInit {
  portalName:string= 'API Portal';
  themeValue
  constructor(private dilaogRef: MatDialogRef<LoginPopupComponent>,private dataShare:DataShareService) { }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
     
    });

    (window.location.href).includes('supplier') ? this.portalName = "Supplier EDI Onboarding" : this.portalName = "API Portal"
    // console.log("find me",this.portalName)

  }



  close() {
    this.dilaogRef.close();
  }

  onClickSignIn() {
    localStorage.setItem('requestedLogin', 'true');
    window.location.href =`${environment.oauth2.loginUrl}?response_type=${environment.oauth2.responseType}&client_id=${environment.oauth2.clientId}&redirect_uri=${environment.oauth2.redirectUrl}&state=1234&scope=${environment.oauth2.scope}&idp_identifier=AzureAD`
      
  }
  externalSignIn(){
    localStorage.setItem('requestedLogin', 'true');
    window.location.href =`${environment.oauth2.loginUrl}?response_type=${environment.oauth2.responseType}&client_id=${environment.oauth2.clientId}&redirect_uri=${environment.oauth2.redirectUrl}&state=1234&scope=${environment.oauth2.scope}&idp_identifier=Salesforce`
  }
  register(){
    console.log("re url",`${environment.registrationUrl}` )
    window.location.href = (window.location.href).includes('supplier') ? `${environment.supplierRegistrationUrl}` :`${environment.registrationUrl}`;
    
                 

  }

}
