import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsecaseApiSrviceService {

  constructor(private http: HttpClient) { }

  getUsecaseCMS(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/api_usecases",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getOwnerAPI(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-owner",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }
  
}
