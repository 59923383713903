import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  private sideBarStatus = new BehaviorSubject<boolean>(true);
  private theme$ = new BehaviorSubject<string>('default-theme');//default-theme,light-theme,dark-theme
  private url$ = new BehaviorSubject<string>('');
  private captchaRefresh = new BehaviorSubject<string>('');
  private notificationUpdate$ = new BehaviorSubject<string>('');
  private globalSearchRefresh = new BehaviorSubject<string>('');
  private globalSearchTrigger = new BehaviorSubject<string>('');

  private timerMinutes = new BehaviorSubject<number>(null);
  private timerSeconds = new BehaviorSubject<number>(null);
  
  private islogin = new BehaviorSubject<string>('');


  //api-management my-approvals
  private myApprovalsData: any = null;
  private myApprovalsFilter: any = null;
  private myApprovalsDataReloadReq = true;

  //api-management my-requests
  private myRequestsData: any = null;
  private myRequestsFilter: any = null;
  private myRequestsDataReloadReq = true;

  //api-management api-visibility
  private apiVisibilityData: any = null;
  private apiVisibilityFilter: any = null;
  private apiVisibilityDataReloadReq = true;

  //admin master-data
  //master-data
  private masterData:any = { };
  //manage-api-owners-data
  private manageApiOwnerData: any = { };
  //manage-api-owners-filter
  private manageApiOwnerFilter: any = {};
  //user-management-data
  private userManagementData: any = {};

  private reportUrl = new BehaviorSubject<string>('');

  private filterDetailsArray : any[];
  private newFilterArray: any[];
  constructor() { }

 

  getSideBarStatus(){
    return this.sideBarStatus.asObservable();
  }

  setSideBarStatus(status: boolean){
    this.sideBarStatus.next(status);
  }

  getTheme(): Observable<string> {
    return this.theme$.asObservable();
  }
 
  getThemeValue(): string{
    return this.theme$.getValue();
  }
  setNavigateUrl(url: string) {
    this.url$.next(url);
  }  
  getNavigateUrl(): Observable<string> {
    console.log(this.url$)
    return this.url$.asObservable();

  }
  setTheme(theme: string) {
    this.theme$.next(theme);
  }



  setNotificationUpdate(url: string) {
    this.notificationUpdate$.next(url);
  }  

  doCaptchaRefresh() {
    this.captchaRefresh.next("true");
  }

  getCaptchaRefresh() {
    return this.captchaRefresh.asObservable();
  }
  doGlobalSearchRefresh() {
    this.globalSearchRefresh.next("true");
  }

  getGlobalSearchRefresh() {
    return this.globalSearchRefresh.asObservable();
  }
  doGlobalSearchTrigger() {
    this.globalSearchTrigger.next("true");
  }

  getGlobalSearchTrigger() {
    return this.globalSearchTrigger.asObservable();
  }

  setMinutes(val) {
    this.timerMinutes.next(val);
  }

  getMinutes() {
    return this.timerMinutes.asObservable();
  }

  setSeconds(val) {
    this.timerSeconds.next(val);
  }

  getSeconds() {
    return this.timerSeconds.asObservable();
  }

  doLoginRefresh() {
    this.islogin.next("true");
  }

  getLoginRefresh() {
    return this.islogin.asObservable();
  }
  
  getNotificationUpdate(): Observable<string> {   
    return this.notificationUpdate$.asObservable();
  }

  //api-management my-approvals
  setMyApprovals(data: any){
    this.myApprovalsData = data;
  }

  getMyApprovals(){
    return this.myApprovalsData;
  }

  setMyApprovalsFilter(data: any){
    this.myApprovalsFilter = data;
  }

  getMyApprovalsFilter(){
    return this.myApprovalsFilter;
  }

  setMyApprovalsDataReloadReq(flag: boolean){
    this.myApprovalsDataReloadReq = flag;
  }

  isMyApprovalsDataReloadReq(){
    return this.myApprovalsDataReloadReq;
  }

  //api-management my-requests
  setMyRequests(data: any) {
    this.myRequestsData = data;
  }

  getMyRequests() {
    //return [];
    return this.myRequestsData;
  }

  setMyRequestsFilter(data: any) {
    this.myRequestsFilter = data;
  }

  getMyRequestsFilter() {
    return this.myRequestsFilter;
  }

  setMyRequestsDataReloadReq(flag: boolean){
    this.myRequestsDataReloadReq = flag;
  }

  isMyRequestsDataReloadReq(){
    return this.myRequestsDataReloadReq;
  }

  //api-management api-visibility
  setApiVisibility(data: any) {
    this.apiVisibilityData = data;
  }

  getApiVisibility() {
    return this.apiVisibilityData;
  }

  setApiVisibilityFilter(data: any) {
    this.apiVisibilityFilter = data;
  }

  getApiVisibilityFilter() {
    return this.apiVisibilityFilter;
  }

  setApiVisibilityDataReloadReq(flag: boolean) {
    this.apiVisibilityDataReloadReq = flag;
  }

  isApiVisibilityDataReloadReq() {
    return this.apiVisibilityDataReloadReq;
  }

  //master-data
  getMasterData(action){
    return this.masterData[action];
  }

  setMasterData(action, data){
    this.masterData[action] = data;
  }

  //manage-api-owners-data
  getManageApiOwnerData(action){
    return this.manageApiOwnerData[action];
  }

  setManageApiOwnerData(action, data){
    this.manageApiOwnerData[action] = data;
  }

  //manage-api-owners-filter
  getManageApiOwnerFilter(action) {
    return this.manageApiOwnerFilter[action];
  }

  setManageApiOwnerFilter(action, data) {
    this.manageApiOwnerFilter[action] = data;
  }

  //user-management-data
  getUserManagementData(subaction) {
    return this.userManagementData[subaction];
  }

  setUserManagementData(subaction, data) {
    this.userManagementData[subaction] = data;
  }

  getReportUrl():Observable<string>{
    return this.reportUrl.asObservable();
  }

  setReportUrl(url){
    this.reportUrl.next(url);
  }

  setFilterDetails(data){
    this.filterDetailsArray=data;
  }

  getFilterDetails(){
    return this.filterDetailsArray;
  }

  setFilterData(data){
    this.newFilterArray=data;
  }

  getFilterData(){
    return this.newFilterArray;
  }
  setCssVariables(theme){
    if(theme=='light-theme'){
      document.documentElement.style.setProperty('--darkColor', '#121212');
      document.documentElement.style.setProperty('--darkColor2', '#121212');
      document.documentElement.style.setProperty('--darkColor3', '#515151');
      document.documentElement.style.setProperty('--lightColor', '#FFFFFF');
      document.documentElement.style.setProperty('--lightColor2', '#FFFFFF');
      document.documentElement.style.setProperty('--lightColor3', '#454444');
      document.documentElement.style.setProperty('--cardfootColor', '#FFFFFF');
      document.documentElement.style.setProperty('--cardfootColor2', '#FAFAFA');
      document.documentElement.style.setProperty('--tablehead', 'lightgrey');
      document.documentElement.style.setProperty('--cardColor', '#FFFFFF');
      document.documentElement.style.setProperty('--cardColor2', '#FFFFFF');
    }else if(theme=='default-theme'){
      document.documentElement.style.setProperty('--darkColor', '#FFFFFF');
      document.documentElement.style.setProperty('--darkColor2', '#121212');
      document.documentElement.style.setProperty('--darkColor3', '#515151');
      document.documentElement.style.setProperty('--lightColor', '#121212');
      document.documentElement.style.setProperty('--lightColor2', '#FFFFFF');
      document.documentElement.style.setProperty('--lightColor3', '#F7F1F0');
      document.documentElement.style.setProperty('--cardfootColor', '#FFFFFF');
      document.documentElement.style.setProperty('--cardfootColor2', '#FAFAFA');
      document.documentElement.style.setProperty('--tablehead', 'lightgrey');
      document.documentElement.style.setProperty('--cardColor', '#FFFFFF');
      document.documentElement.style.setProperty('--cardColor2', '#FFFFFF');
    }else{
      document.documentElement.style.setProperty('--darkColor', '#FFFFFF');
      document.documentElement.style.setProperty('--darkColor2', '#FFFFFF');
      document.documentElement.style.setProperty('--darkColor3', '#FFFFFF');
      document.documentElement.style.setProperty('--lightColor', '#121212');
      document.documentElement.style.setProperty('--lightColor2', '#121212');
      document.documentElement.style.setProperty('--lightColor3', '#F7F1F0');
      document.documentElement.style.setProperty('--cardfootColor', '#323131');
      document.documentElement.style.setProperty('--cardfootColor2', '#323131');
      document.documentElement.style.setProperty('--tablehead', '#121212');
      document.documentElement.style.setProperty('--cardColor', '#454444');
      document.documentElement.style.setProperty('--cardColor2', '#3F4146');
      
    }
  }
}

