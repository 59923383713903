import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-globaldeletepop',
  templateUrl: './globaldeletepop.component.html',
  styleUrls: ['./globaldeletepop.component.scss']
})
export class GlobaldeletepopComponent implements OnInit {
  confirmmsg = "";
  themeValue;
  constructor(public dialogRef: MatDialogRef<GlobaldeletepopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataShareService) { }

  ngOnInit(): void {
    this.dataShare.getTheme().subscribe((res:any)=>{
      this.themeValue = res;
    });
    this.confirmmsg = this.data.message;
  }

  close(){
    this.dialogRef.close({
       status: "no"
    });
  }

  ok(){
    this.dialogRef.close({
      status: "yes"
   });
  }

}
