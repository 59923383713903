import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AboutApiService } from '../../http/about-api.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { GlobaldeletepopComponent } from 'src/app/core/components/globaldeletepop/globaldeletepop.component';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-add-edit-pop',
  templateUrl: './add-edit-pop.component.html',
  styleUrls: ['./add-edit-pop.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AddEditPopComponent implements OnInit {
  title = ""
  errmss = ""
  radioimg = "r1";
  imgf1 = "";
  imgf2 = "";
  imgf3 = "";
  defaultImages = [];
  themeValue;
  selectedDefaultImg;
  section2 = { title: "", description: "", order: "" }
  section3 = { title: "", description: "", base: "", file_name: "",file_url: "", file_type: "" }
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: AboutApiService,
    public dialogRef: MatDialogRef<AddEditPopComponent>,
    private dialog: MatDialog,
    private loader: LoaderService,
    private dataShare: DataShareService) {

  }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    console.log("data", this.data)
    if (this.data.section == 1) {
      if (this.data.action == 'edit') {
        this.title = "Edit Feature";
        this.section2.title = this.decodeHTML(this.data.data.heading);
        this.section2.description = this.data.data.body;
        this.section2.order = this.data.data.order;
      } else {
        this.title = "Add New Feature";
      }
    } else if (this.data.section == 2) {
      if (this.data.action == 'edit') {
        this.title = "Edit Update";
        this.section3.title = this.data.data.heading;
        this.section3.description = this.data.data.body;
        this.section3.file_name = this.data.data.file_name;
        this.section3.file_type = this.data.data.file_type;
        this.section3.file_url = this.data.data.file_url;
        this.defaultImages = this.data.defaultImages;
        this.selectedDefaultImg = this.defaultImages[0];

        if(this.data.data.source != "default"){
          this.section3.file_name = this.data.data.file_name;
          this.section3.file_type = this.data.data.file_type;
          this.section3.file_url = this.data.data.file_url;
          setTimeout(()=>{
            this.radioimg = "r2";
            this['imgf1'] = "active";
          },200);
        }else{
          let k = 1;
          this.defaultImages.forEach(res=>{
            if(res.file_name == this.data.data.file_name){
              this['imgf'+k] = "active";
              this.selectedDefaultImg = res;
            }
            k++;
          })
        }

      } else {
        this.title = "Add New Update";
        this.defaultImages = this.data.defaultImages;
        this.selectedDefaultImg = this.defaultImages[0];
        this['imgf1'] = "active";
      }
    }

  }

  decodeHTML(value) {
    let res = value.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    return res;
  }

  encodeHTML(value) {
    return value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");
  }

  restoreCon(i){
    if(i==1)
    {
      this.section2.title =  this.decodeHTML(this.data.data.heading_v2?this.data.data.heading_v2:'');
      this.section2.description = this.data.data.body_v2?this.data.data.body_v2:'';
      this.section2.order = this.data.data.order_v2?this.data.data.order_v2:1;
      this.Save1();
    }
    if(i==2)
    {
      this.section3.title =  this.data.data.heading_v2?this.data.data.heading_v2:''
      this.section3.description = this.data.data.body_v2?this.data.data.body_v2:'';
      this.section3.file_name = this.data.data.file_name_v2?this.data.data.file_name_v2:'';
      this.section3.file_type = this.data.data.file_type_v2?this.data.data.file_type_v2:'';
      this.section3.file_url = this.data.data.file_url_v2?this.data.data.file_url_v2:'';

     this.loader.show();
     let obj = {}
     let base;
   let type;
   let name;
   let source;
     if(this.data.data.source_v2 == 'default')
     {
      base = "";
      type = this.section3.file_type;
      name = this.section3.file_name;
      source = "default";
     }else{
      base = "";
      type = this.section3.file_type;
      name = this.section3.file_name;
      source = "custom";
     }

       obj = {
         "action": "update",
         "placeholder_name": "about_us_api_release",
         "title": "Stay Updated",
         "heading": this.section3.title,
         "body": this.section3.description,
         "order": 1,
         "wwid": this.data.wwid,
         "id": this.data.data.id,
         "source":source,
         "image_details": {
           "attachment_id": this.data.data.attachment_id,
           "base": base,
           "file_name": name,
           "file_type": type,
           "file_url": this.section3.file_url,
           "active": 1,
           "order": 1
         }
       }

    
     this.apiService.getAboutCMS(obj).subscribe(res => {
       console.log(res);
       this.dialogRef.close({ status: "success", message: "Updated Successfully." });
     }, error => {
       this.dialogRef.close({ status: "error", message: error.message });
     })
    }
  }

  uploadfile(ev) {
    this.errmss = "";
    console.log(ev.target.files[0]);
    let file = ev.target.files[0];
    if (file.size > 2097152) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "File size must under 2MB!";
      return;
    }
    if (!(file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg'))) // 2 MiB for bytes.
    {
      //alert("File size must under 2MB!");
      this.errmss = "Only Png/Jpeg will accepted.";
      return;
    }
    let img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
      //img.width === 236 && img.height === 167
      if (img.width === 236 && img.height === 167) {
        this.section3.file_name = file.name
        if (file.type.includes('png')) {
          this.section3.file_type = 'png'
        }
        if (file.type.includes('jpg') || file.type.includes('jpeg')) {
          this.section3.file_type = 'jpeg'
        }

        this.getBase64(file).then(
          (data: any) => {//console.log(data)
            var str = data;
            str = str.replace("data:" + file.type + ";base64,", "");
            this.section3.base = str
          }
        );
      } else {
        //alert("Incorrect size");
        this.errmss = "Incorrect size! Size should be 236 x 167.";
        return true;
      }

    }

  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  validate(i) {
    if (i == 1) {
      if (this.section2.description == "" || this.section2.title == "" || this.section2.order == "" || !parseInt(this.section2.order)) {
        return true;
      } else {
        return false;
      }
    }
    if (i == 2) {
      if (this.section3.description == "" || this.section3.title == "" || (this.radioimg=="r2" && (this.section3.file_name == "" || this.section3.file_name == null) )) {
        return true;
      } else {
        return false;
      }
    }
  }

  numberOnly(event): boolean {
    console.log(event)
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || (charCode == 48 && (this.section2.order=="" || this.section2.order==undefined))) {
      return false;
    }
    return true;

  }

  Save1() {
    this.loader.show();
    var str = this.encodeHTML(this.section2.title);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    let obj
    if (this.data.action == 'edit') {
      obj = {
        "action": "update",
        "placeholder_name": "about_us_feature",
        "title": "Why should you collaborate with us?",
        "heading": b64,
        "body": this.section2.description,
        "order": this.section2.order ? parseInt(this.section2.order) : 0,
        "wwid": this.data.wwid,
        "id": this.data.data.id
      }
    } else {
      obj = {
        "action": "add",
        "placeholder_name": "about_us_feature",
        "title": "Why should you collaborate with us?",
        "heading": b64,
        "body": this.section2.description,
        "order": this.section2.order ? parseInt(this.section2.order) : 0,
        "wwid": this.data.wwid
      }
    }

    this.apiService.getAboutCMS(obj).subscribe(res => {
      console.log(res);
      this.dialogRef.close({ status: "success", message: "Updated Successfully." });
    }, error => {
      this.dialogRef.close({ status: "error", message: error.message });
    })
  }

  Save2() {
    this.loader.show();
    let obj = {}
    let base;
  let type;
  let name;
  let source;
    if(this.radioimg=="r1")
    {
     base = "";
     type = this.selectedDefaultImg.file_type;
     name = this.selectedDefaultImg.file_name;
     source = "default";
    }else{
     base = this.section3.base;
     type = this.section3.file_type;
     name = this.section3.file_name;
     source = "custom";
    }

    if (this.data.action == 'edit') {
      obj = {
        "action": "update",
        "placeholder_name": "about_us_api_release",
        "title": "Stay Updated",
        "heading": this.section3.title,
        "body": this.section3.description,
        "order": 1,
        "wwid": this.data.wwid,
        "id": this.data.data.id,
        "source":source,
        "image_details": {
          "attachment_id": this.data.data.attachment_id,
          "base": base,
          "file_name": name,
          "file_type": type,
          "file_url": this.section3.file_url,
          "active": 1,
          "order": 1
        }
      }
    } else {
      obj = {
        "action": "add",
        "placeholder_name": "about_us_api_release",
        "title": "Stay Updated",
        "heading": this.section3.title,
        "body": this.section3.description,
        "order": 1,
        "wwid": this.data.wwid,
        "source":source,
        "image_details": {
          "base": base,
          "file_name": name,
          "file_type": type,
          "active": 1,
          "order": 1
        }
      }
    }
   
    this.apiService.getAboutCMS(obj).subscribe(res => {
      console.log(res);
      if(res.message=="API Release added succesfully" || res.message=="release updated succesfully"){
        this.dialogRef.close({ status: "success", message: "Updated Successfully." });
      }else{
        this.dialogRef.close({ status: "error", message: res.message });
      }
     
    }, error => {
      this.dialogRef.close({ status: "error", message: error.message });
    })
  }

  

  delete(i) {
    let delmss = "";
    if (i == 1) {
      delmss = "Feature"
    }else{
      delmss = "Update"
    }
    let ref = this.dialog.open(GlobaldeletepopComponent, {
      // autoFocus: false,
      // panelClass: 'code-snippet-cont',
      // width: '50vw',
      data: { message: `Are you sure want to delete this ${delmss} ?` }, backdropClass: 'modal-bg',
      disableClose: true
    });

    //edit specifications
    ref.afterClosed().subscribe(res => {
      if (res && res.status == "yes") {
    this.loader.show();
    if (i == 1) {
      let obj = {
        "action": "delete",
        "placeholder_name": "about_us_feature",
        "id": this.data.data.id,
        "wwid": this.data.wwid
      }
      this.apiService.getAboutCMS(obj).subscribe(res => {
        console.log(res);
        this.dialogRef.close({ status: "success", message: "Deleted Successfully." });
      }, error => {
        this.dialogRef.close({ status: "error", message: error.message });
      })
    } else if (i == 2) {
     let source;
      if(this.radioimg=="r1")
      {
       source = "default";
      }else{
       source = "custom";
      }

      let obj = {
        "action": "delete",
        "placeholder_name": "about_us_api_release",
        "id": this.data.data.id,
        "wwid": this.data.wwid,
        "source":source,
        "image_details": {
          "attachment_id": this.data.data.attachment_id,
          "base": "",
          "file_name": this.data.data.file_name,
          "file_type": this.data.data.file_type,
          "active": 1,
          "order": 1
        }
      }
      this.apiService.getAboutCMS(obj).subscribe(res => {
        console.log(res);
        this.dialogRef.close({ status: "success", message: "Deleted Successfully." });
      }, error => {
        this.dialogRef.close({ status: "error", message: error.message });
      })
    }
  }else{

  } });
  }

  selectDefaultimg(i,imgdata){
    // this.imgf1 = "";
    // this.imgf2 = "";
    // this.imgf3 = "";
    for(let j=1;j<=this.defaultImages.length;j++){
      this['imgf'+j] = "";
    }
    this['imgf'+i] = "active";
    this.selectedDefaultImg = imgdata;
  
   }

}
