const authDomainUrl = 'https://cummins-stg-pool.auth.us-east-1.amazoncognito.com';
const siteDomainUrl = "https://developer-stg.cummins.com";
const vaSiteDomainUrl = 'https://stg.d4jasub2e452d.amplifyapp.com';
const apiHostName ="developer-stg.cummins.com";
const sfRegUrl = 'https://cumminscss--uat.sandbox.my.site.com/cw/IAM_Basic_Registration?appid=a1a4N00000GLiEp'
const externalRegUrl = 'https://cumminscss--uat.sandbox.my.site.com/cw/IAM_NewRegistration?appid=a1aDz000008WJB3'

export const environment = {
  production: true,
  local: false,
  apiHostUrl: `https://developer-stg.cummins.com/api`,
  siteDomain: `${siteDomainUrl}`,
  indexPageUrl: `${siteDomainUrl}`,
  apiHostName:`${apiHostName}`,
  cmsContent: 'db',
  environmentName: 'stage',
  drupalBase: 'https://developer-api-stg.cummins.com',
  registrationUrl:`${sfRegUrl}`,
  supplierRegistrationUrl:`${externalRegUrl}`,
  oauth2: {
    loginUrl: `${authDomainUrl}/oauth2/authorize`,
    redirectUrl: `${siteDomainUrl}`,
    tokenUrl: `${authDomainUrl}/oauth2/token`,
    clientId: '3hn45k2hfenvb9uavgmr81dn0u',
    scope: 'openid+profile+https://developer-stg.cummins.com/api',
    responseType: 'code',
    grantType: 'authorization_code',
    logoutUrl: `${authDomainUrl}/logout`,
    logoutRedirectUrl: `https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0`,
    indexPageUrl: `${siteDomainUrl}/web`
  },
  virtualAssistant: {
    loginUrl: `${authDomainUrl}/oauth2/authorize`,
    clientId: '6ql9jrv7fs6n5he72952jsop8h',
    redirectUrl: `${vaSiteDomainUrl}`,
    scope: 'openid+profile+https://developer-stg.cummins.com/api',
    responseType: 'code'
  }
};
