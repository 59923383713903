import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClientModule } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiInformationService {

  reload: boolean = false;
  reloadString: string;
  categoryCaching:any;
  categoriesData: any = null;
  filterData: any = null;
  userRegistrationData: any = null;
  assetId: any;
  selectedAssetId: any;
  data: any;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.reloadString = Math.random().toString();
  }

  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

  getApiInformation(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-detail",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getApiViewMoreInfo(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-detail",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(() => error);
          })
      );
  }
  
  getApiList(reqBody): Observable<any> {
    let url = environment.apiHostUrl + "/search/get-api-list";
    let params = new HttpParams();
    params = params.append('loggedIn', reqBody.loggedIn ? reqBody.loggedIn : false);
    params = params.append('wwid', reqBody.wwid ? reqBody.wwid : '');
    params = params.append('email_id', reqBody.email_id ? reqBody.email_id : '');
    params = params.append('action', reqBody.action ? reqBody.action : '');
    params = params.append('searchText', reqBody.searchText ? reqBody.searchText : '');
    params = params.append('external', reqBody.external ? reqBody.external : false);
    params = params.append('rload', this.reloadString);

    return this.http.get(url, { params: params })
      .pipe(
        share(),
        shareReplay(),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getApiListBeforeLogin(action): Observable<any> {
    let url = environment.apiHostUrl + "/search/api_catalog";
    let params = new HttpParams();
    params = params.append('action', action);
    params = params.append('rload', this.reloadString);

    return this.http.get(url, { params: params })
      .pipe(
        share(),
        shareReplay(),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getApiSearhInformationBeforeLogin(reqBody): Observable<any> {
    
      let url = environment.apiHostUrl + "/search/api_catalog";

      return this.http.post(url, reqBody)
        .pipe(
          map(response => {
            if (response === undefined) {
              return response;
            }
            return response;
          }),
          catchError(
            error => {
              return throwError(error);
            })
        );
  }

  getApiSearhInformationAfterLogin(reqBody): Observable<any> {

      let url = environment.apiHostUrl + "/search/get-search-filter-data";

      return this.http.post(url, reqBody)
        .pipe(
          map(response => {
            if (response === undefined) {
              return response;
            }
            return response;
          }),
          catchError(
            error => {
              return throwError(error);
            })
        );
  }


  getApiVisibilityList(reqBody): Observable<any> {
    let url = environment.apiHostUrl + "/search/get-api-list";

    return this.http.post(url, reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getExchangeOwners(body): Observable<any> {
    let url = environment.apiHostUrl + "/get_exchange_applications";

    return this.http.post(url, body)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }
  
  getPolicies(obj): Observable<any> {
    let url = environment.apiHostUrl + "/search/master-data";

    return this.http.post(url, obj)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  // Policy name Mapping on Admin
  getPolicyNameMapping(obj): Observable<any> {
    let url = environment.apiHostUrl + "/search/master-data";

    return this.http.post(url, obj)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }
  
  updatePolicyNameMapping(obj): Observable<any> {
    let url = environment.apiHostUrl + "/search/master-data";

    return this.http.post(url, obj)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getConsumers(obj): Observable<any> {
    let url = environment.apiHostUrl + "/search/master-data";

    return this.http.post(url, obj)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getConsumerExcel(obj): Observable<any> {
    let url = environment.apiHostUrl + "/search/master-data";

    return this.http.post(url, obj)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  carousalAnnouncements(reqBody): Observable<any> {
    let url = environment.apiHostUrl + "/announcement_notif_api";

    return this.http.post(url, reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  featurePreference(reqBody): Observable<any> {
    let url = environment.apiHostUrl + "/feature_preference_api";

    return this.http.post(url, reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  publishApi(action, reqBody?, refresh?): Observable<any> {
    this.reloadString = Math.random().toString();
    let url = environment.apiHostUrl + "/publish-api?action=" + action;

    if(refresh && (refresh == 0 || refresh == 1)) url += "&rld=" + this.reloadString;

    let httpClient = new HttpClient(this.handler)
    // let httpheaders = new HttpHeaders();

    // let temp = localStorage.getItem('idToken');
    // if (temp) {
    //   httpheaders = httpheaders.set('X-Api-Key', temp);
    // }

    // //httpheaders = httpheaders.set('Content-Type', 'multipart/form-data;');
    // httpheaders = httpheaders.set('Accept', '*/*');

    return this.http.post(url, reqBody ? reqBody : new FormData()).pipe(
      map(response => {
        if (response === undefined) {
          return response;
        }
        return response;
      }),
      catchError(
        error => {
         // console.log("throw error",error)
          return throwError(error);
        })
    );
  }

  deleteApi(body, action){
    let url = environment.apiHostUrl + "/publish-api?action=" + action;
    return this.http.post(url, body)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }


  meteringGraphApi(body){
    let url = environment.apiHostUrl + "/api_graphs";
    return this.http.post(url, body)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  downloadStaticSpecs(url): Observable<any> {
    let httpClient = new HttpClient(this.handler);
    let httpheaders = new HttpHeaders();
    //httpheaders = httpheaders.set('Content-Type', 'application/octet-stream');

    return httpClient.get(url, {
      observe: 'response',
      responseType: 'text'  // This one here tells HttpClient to parse it as text, not as JSON
    })
  }

  getApiMgmt(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/api-management", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }


  getUserMgmt(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/user-management", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }

  
  checkAPIOwner(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/manageApi-owner", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }

  getApiSummary(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-summary", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getCmsDbData(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-cms-db-data", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }
  getMeteringData(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/api_graphs", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }

  getMonetizationData(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/api_graphs", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

  }

  getAccessRequest(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-access", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getUserDetails(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-user-info", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return error;
          })
      );
  }

  getUserDetails2(reqBody): Promise<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-user-info", reqBody).toPromise();
  }

  setUserRegistrationData(data: any) {
    this.userRegistrationData = data;
  }

  getRegistrationInfo(reqBody): Observable<any> {
    if (this.userRegistrationData != null) {
      return of(this.userRegistrationData);
    }

    return this.http.post(environment.apiHostUrl + "/search/user-registration", reqBody)
      .pipe(
        tap(res => {
          this.userRegistrationData = res;
        }),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getNotifications(reqBody): Observable<any> {

    return this.http.post(environment.apiHostUrl + "/search/get-notification", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getCategoryList(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/master-data", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  setCategoryData(data: any) {
    this.categoriesData = data;
  }

  getApiCategory(reqBody): Observable<any> {
    this.reload = !this.reload;
    this.reloadString = Math.random().toString();
    // return this.http.post(environment.apiHostUrl + "/get-api-categories?get="+this.reloadString, reqBody)
    //   .pipe(

    if (this.categoriesData != null) {
      return of(this.categoriesData);
    }

    return this.http.post(environment.apiHostUrl + "/get-api-categories", reqBody)
      .pipe(
        tap(res => {
          this.categoriesData = res;
        }),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  setFilterData(data: any) {
    this.filterData = data;
  }

  //FilterService
  getFilterDetails(): Observable<any> {
    if (this.filterData != null) {
      return of(this.filterData);
    }
   // return this.http.get(environment.apiHostUrl + "/search/get-search-filter-data")
   return this.http.post(environment.apiHostUrl + "/search/get-search-filter-data",{action : "filter"})
      .pipe(
        tap(res => {
          this.filterData = res;
        }),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getFaqData(requestBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/get-faq", requestBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getCarouselData(): Observable<any> {
    // this.reload = !this.reload;
    this.reloadString = Math.random().toString();

    if (this.categoriesData != null) {
      return of(this.categoriesData);
    }

    // return this.http.get(environment.apiHostUrl + "/get-api-categories?get="+this.reloadString)
    let data = this.http.get(environment.apiHostUrl + "/get-api-categories?get=" + this.reloadString)
      .pipe(
        share(),
        shareReplay(),
        tap(res => {
          this.categoriesData = res;
          res["api_category_list"].forEach(element => {
            if (element.summary == null)
              element.summary = element.description;
            element.image = 'assets/images/categories/' + element.image;
          });
        }),
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );

      this.categoryCaching = data;
      return data
  }

  getCarouselCacheData(): Observable<any>{
    return this.categoryCaching;
  }

  getApplicationDetails(): Observable<any> {
    return this.http.get(environment.apiHostUrl + "/search/get-application-list")

      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getUserData() {
    return this.http.get('../../assets/files/userMgmt/user.json');
  }

  getBookMarkApi(reqBody): Observable<any> {
    this.reload = !this.reload;
    this.reloadString = Math.random().toString();

    return this.http.post(environment.apiHostUrl + "/search/bookmark-api", reqBody)
      .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getStartingPage() {
    return this.http.get('../../assets/files/getting-started/content.json');
  }

  getIncidentMgmt(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/incident-management",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getPreferences(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/user-preference",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getOwnerList(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/manageApi-owner",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getApiOwnerList(reqBody): Observable<any> {
    return this.http.post(environment.apiHostUrl + "/search/get-api-owner",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getDomainList(env?,asset_id?): Observable<any>{
    let url = environment.apiHostUrl + "/search/get-application-list";
    let params = new HttpParams();
    params = params.append("action","domain_list");
    params = params.append("environment",env);
    params = params.append("assetId",asset_id);
    return this.http.get(url, { params : params}).pipe(
      share(),
      shareReplay(),
      map(response => {
        if (response === undefined) {
          return response;
        }
        return response;
      }),
      catchError(
        error => {
          return throwError(error);
        })
    );
  }

  getPortalAdminList(reqBody?): Observable<any>{
    return this.http.post(environment.apiHostUrl + "/search/user-management",
      reqBody).pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  }

  getClientSecret(reqBody): Observable<any>{
    return this.http.post(environment.apiHostUrl + "/search/get-api-detail", reqBody)
    .pipe(
        map(response => {
          if (response === undefined) {
            return response;
          }
          return response;
        }),
        catchError(
          error => {
            return throwError(error);
          })
      );
}

}
