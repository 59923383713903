export const RoleConfig = {
  // Admin Role
  ADMIN: [
    'web/doc/search'
  ],
  Default : [
    '/web/api/api-mgmt',
    '/web/api/api-consumer-mgmt'
  ],
  Owner : [
    '/web/api/api-mgmt',
    '/web/api/api-consumer-mgmt'
  ],
  Consumer : [
    '/web/api/api-mgmt',
    '/web/api/api-consumer-mgmt'
  ]
};
