import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiInformationService } from 'src/app/main/api/http/api-information.service';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/account/http/login.service';
import { StateManageService } from '../../core/services/state-manage.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { environment } from 'src/environments/environment';
import { CMSService } from 'src/app/core/cms/cms.service';
import { CMSDataEnum } from 'src/app/core/constants/Constants';
import { CMSModel } from 'src/app/core/cms/cms.model';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditmediapopComponent } from './editmediapop/editmediapop.component';
import { DomSanitizer } from '@angular/platform-browser';
import { EdituseapiportalpopComponent } from './edituseapiportalpop/edituseapiportalpop.component';
import { UseapitablepopComponent } from './useapitablepop/useapitablepop.component';
import { DeletePopComponent } from './delete-pop/delete-pop.component';
import { DataShareService } from 'src/app/core/services/data-share.service';


@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {

  roleId: any;
  isEditClicked = { intro: false, newIntro: "" ,
  howtouse1: false, newhowtouse1:"",
  howtouse2: false, newhowtouse2:"",
  howtouse3: false, newhowtouse3:"",
  howtouse4: false, newhowtouse4:"",
  howtouse5: false, newhowtouse5:"",
};
  tableData = [];
  errormeaning: string;
  errorjson: { line1: string; line2: string; line3: string; note: string; };
  themeValue //= "default-theme";
  resp = { gridCols: 4, gutter: "1rem", height: "290px" };

  constructor(private serv: ApiInformationService, private router: Router,
    private login: LoginService, private pageheader: PageHeaderComponent, private stateMgmtService: StateManageService,
    private cmsService: CMSService, private dialog: MatDialog, private loader: LoaderService,
    private sanitizer: DomSanitizer,private dataShare:DataShareService
  ) { }

  data: any = [];
  isLoggedIn: boolean;
  displayedColumns: string[] = ['code', 'message', 'response'];
  successFlag = false;
  errorFlag = false;
  cmsDatagetting_started_use_apis;
  cmsDataIntro = []; cmsSteps = [];
  cmsHowToUse = [];
  isOwner: any = false;
  isAdmin: any = false;
  alertMsg = "";
  @ViewChild('alert', { static: false }) alert: ElementRef;
  @ViewChild('video', { static: false }) video: ElementRef;
  //dataSource = ELEMENT_DATA;
  errorResponse1 = {
    "status": "ERROR",
    "correlationId": "1-d5864f60-915c-11eb-a233-022059974d50",
    "message": "HTTP POST on resource 'https://login.microsoftonline.com:443/Cummins365.onmicrosoft.com/oauth2/token' failed: bad request (400)."
  }
  errorResponse2 = {
    "status": "ERROR",
    "correlationId": "1-d5864f60-915c-11eb-a233-022059974d50",
    "message": "HTTP POST on resource 'https://login.microsoftonline.com:443/Cummins365.onmicrosoft.com/oauth2/token' failed: bad request (400)."
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    //console.log("vvbbnn")
    this.loader.show();
    this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
      this.initCMSData();
      //this.loader.hide();
    })


    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });
    
    //console.log("vvbbnn1")
    this.onResize();
    //console.log("vvbbnn2")
    this.isLoggedIn = this.login.isAuthenticated();
    //console.log("sessionUserInfobb",localStorage)
    if (localStorage.getItem("sessionUserInfo") != null) {
      
      let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
      this.roleId = userInfo.roleId;
      //console.log("sessionUserInfo",userInfo)
      if (userInfo.role.indexOf('API Portal Admin') > -1) this.isAdmin = true;
    }


    this.errorjson = {
      "line1": '{"status": "ERROR",',
      "line2": '"correlationId": "1-d5864f60-915c-11eb-a233-022059974d50",',
      "line3": '"message": "HTTP POST on resource \'https://login.microsoftonline.com:443/Cummins365.onmicrosoft.com/oauth2/token\' failed: bad request (400).}"',
      "note": '"Note: More error codes may be added in future."'
    };
    
  }

  checkEvents(name){
    console.log("name",name)
   if(name && name.toLowerCase().includes('login')){
    this.pageheader.doLogin();
   }
  }

  goToLogin(redirectTo: string) {
    if (redirectTo && redirectTo == "Register") {
      this.goToRegister();
    }
    else {
      this.pageheader.doLogin();
    }
  }

  goToRegister(): void {
    localStorage.setItem('requestedLogin', 'true');
    localStorage.setItem('requestedRegister', 'true');
    if (!this.isLoggedIn) {
      window.location.href = `${environment.oauth2.loginUrl}?response_type=${environment.oauth2.responseType}&client_id=${environment.oauth2.clientId}&redirect_uri=${environment.oauth2.redirectUrl}&state=1234&scope=${environment.oauth2.scope}&idp_identifier=AzureAD`;
    }
    else {
      this.router.navigate(['/web/api/register']);
    }
  }


  backToTop() {
    window.scrollTo(0, 0);
  }

  goToApiList() {
    //console.log("state mgmt service")
    //console.log(this.stateMgmtService);
    this.stateMgmtService.setState(null);

    this.stateMgmtService.setState({ action: "all", value: "" });
    this.router.navigate(['/web/api/api-list']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    //get number of cards and respective sizes
    if (window.innerWidth > 1024) {
      this.resp.gridCols = 4; this.resp.gutter = "1rem"; this.resp.height = "290px";
    }
    else if (window.innerWidth <= 1024 && window.innerWidth >= 540) {
      this.resp.gridCols = 2; this.resp.gutter = "2rem"; this.resp.height = "270px";
    }
    else {
      this.resp.gridCols = 1; this.resp.gutter = "1rem"; this.resp.height = "230px";
    }
  }



  private initCMSData() {
    //getting started introduction
    //console.log(this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.home_steps));
    this.cmsDataIntro = this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.getting_started_intro);
    console.log("this.cmsDataIntro.......",this.cmsDataIntro)
    this.cmsDatagetting_started_use_apis = this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.getting_started_use_apis);
    console.log("cmsDatagetting_started_use_apis",this.cmsDatagetting_started_use_apis)
    // if (this.cmsDataIntro[0].video.indexOf("http") == -1 && environment.cmsContent == 'drupal')
    if (this.cmsDataIntro?.length) {
      if (environment.cmsContent == "db") this.cmsDataIntro[0]['video'] = this.cmsDataIntro[0].file_url;
      this.cmsDataIntro[0].body = this.cmsDataIntro && this.cmsDataIntro?.length ? this.cmsDataIntro[0].body.replace(/\u00a0/g, " ") : "" ;
      this.cmsDataIntro[0].body = this.cmsDataIntro && this.cmsDataIntro?.length ?  this.cmsDataIntro[0].body.replace(/<p> <\/p>/g, "") : "";
    }
    //   this.cmsDataIntro[0]['video'] = environment.drupalBase + this.cmsDataIntro[0]['video'];

    //getting started steps
    this.cmsSteps = this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.gettingstarted_steps);
    this.cmsSteps = this.cmsSteps.sort((a, b) => {
      if (a.order > b.order) return 1;
      else if (a.order < b.order) return -1;
      else return 0;
    });

    //adding links
    // this.cmsSteps.forEach(element => {
    //   if (element.order == 1) element["link"] = "API Catalog";
    //   //else if(element.order==2) element["link"] = "Register"; 
    //   else if (element.order == 2) element["link"] = "NA";
    //   else if (element.order == 3) element["link"] = "Login";
    //   else element["link"] = "NA";
    // });

    //getting started how to use apis
    this.cmsHowToUse = this.cmsService.getCMSDataByPlaceholderName(CMSDataEnum.getting_started_use_apis);
    this.cmsHowToUse = this.cmsHowToUse.sort((a, b) => {
      if (a.order > b.order) return 1;
      else if (a.order < b.order) return -1;
      else return 0;
    });

    //adding drupal host url
    // if (this.cmsHowToUse[0].image.indexOf("http") == -1 && environment.cmsContent == 'drupal')
    //   this.cmsHowToUse[0].image = environment.drupalBase + this.cmsHowToUse[0].image;
    if (this.cmsHowToUse.length) {
      if (environment.cmsContent == "db") this.cmsHowToUse[0]['image'] = this.cmsHowToUse[0].file_url;
    }

   //console.log("cmsDatagetting_started_use_apis",this.cmsDatagetting_started_use_apis)
   //console.log("this.cmsDataIntro",this.cmsDataIntro)
   //

    if(this.isBase64(this.cmsDataIntro && this.cmsDataIntro?.length ? this.cmsDataIntro[0].body : ""))
    {
      let hasError = false;
      try {
       decodeURIComponent(escape(window.atob(this.cmsDataIntro && this.cmsDataIntro?.length ? this.cmsDataIntro[0].body : '')))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsDataIntro[0].body = decodeURIComponent(escape(window.atob(this.cmsDataIntro && this.cmsDataIntro?.length ? this.cmsDataIntro[0].body : "")));
     }
    }
    if(this.isBase64(this.cmsDataIntro && this.cmsDataIntro?.length ? this.cmsDataIntro[0].body_v2 : ""))
    {
      let hasError = false;
      try {
       decodeURIComponent(escape(window.atob(this.cmsDataIntro[0].body_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsDataIntro[0].body_v2 = decodeURIComponent(escape(window.atob(this.cmsDataIntro[0].body_v2?this.cmsDataIntro[0].body_v2:'')));
     }
    } else if (this.cmsDataIntro?.length) {
      this.cmsDataIntro[0].body_v2 = this.cmsDataIntro[0].body_v2?this.cmsDataIntro[0].body_v2:"";
    }
    if (this.cmsHowToUse.length) {
      this.isBase64(this.cmsHowToUse[0].heading);
    }
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].heading)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].heading = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].heading)));
     }
   
    }
    if(this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[0].heading_v2 : ''))
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].heading_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].heading_v2 = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].heading_v2)));
     }
   
    }else if (this.cmsHowToUse?.length){
      this.cmsHowToUse[0].heading_v2 = this.cmsHowToUse[0].heading_v2?this.cmsHowToUse[0].heading_v2:'';
    }
    if (this.cmsHowToUse?.length) {
      this.isBase64(this.cmsHowToUse[1].heading)
    }
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].heading)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[1].heading = decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].heading)));
     }
    
    }
    if(this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[1].heading_v2 : ''))
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].heading_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[1].heading_v2 = decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].heading_v2)));
     }
    
    }else if(this.cmsHowToUse?.length) {
      this.cmsHowToUse[1].heading_v2 = this.cmsHowToUse[1].heading_v2?this.cmsHowToUse[1].heading_v2:'';
    }
    this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[1].body : '')
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].body)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[1].body = decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].body)));
     }
    
    }

    if(this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[1].body_v2 : ''))
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].body_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[1].body_v2 = decodeURIComponent(escape(window.atob(this.cmsHowToUse[1].body_v2)));
     }
    
    }else if (this.cmsHowToUse?.length){
      this.cmsHowToUse[1].body_v2 = this.cmsHowToUse[1].body_v2?this.cmsHowToUse[1].body_v2:"";
    }

    this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[0].body : '')
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].body)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].body = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].body)));
     }
    
    }
    if(this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[0].body_v2 : ''))
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].body_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].body_v2 = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].body_v2)));
     }
    
    }else if (this.cmsHowToUse?.length) {
      this.cmsHowToUse[0].body_v2 = this.cmsHowToUse[0].body_v2?this.cmsHowToUse[0].body_v2:'';
    }
    this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[0].title : '')
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].title)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].title = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].title)));
     }
    
    }
    if(this.isBase64(this.cmsHowToUse?.length ? this.cmsHowToUse[0].title_v2 : ''))
    {
      let hasError = false;
      try {
        decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].title_v2)))
     } catch(e) {
       hasError = true
       console.error(e);
     }
     if(!hasError){
      this.cmsHowToUse[0].title_v2 = decodeURIComponent(escape(window.atob(this.cmsHowToUse[0].title_v2)));
     }
    
    }else if (this.cmsHowToUse?.length) {
      this.cmsHowToUse[0].title_v2 = this.cmsHowToUse[0].title_v2?this.cmsHowToUse[0].title_v2:'';
    }

    if(this.cmsHowToUse?.length && this.cmsHowToUse[1].title){
      try {
        this.tableData = JSON.parse(this.cmsHowToUse[1].title);
      } catch (e) {
        console.log("the string does not represent an array")
      }
    }
  
  // this.cmsDataIntro[0].body = decodeURIComponent(escape(window.atob(introtxt)));
   this.loader.hide();
   //console.log("rrrr")
  }


  isBase64(base64Data) {
    var str = base64Data;
    
    try {
      if (str ==='' || str.trim() ===''){ return false; }

      var base64Rejex = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
      var isBase64Valid = base64Rejex.test(base64Data); // base64Data is the base64 string
      if(isBase64Valid){
        return btoa(atob(str)) == str;
      }else{
        return false;
      }
        
    } catch (err) {
        return false;
    }
  }

  editIntro() {
    this.isEditClicked.intro = !this.isEditClicked.intro;
    this.isEditClicked.newIntro = this.cmsDataIntro?.length && this.cmsDataIntro[0].body != "" ? this.decodeHTML(this.cmsDataIntro?.length && this.cmsDataIntro[0].body ? this.cmsDataIntro[0].body : "") : "";
  }

  cancelIntro() {
    this.isEditClicked.newIntro = "";
    this.isEditClicked.intro = false;
  }

  restoreIntro() {
    this.isEditClicked.newIntro = this.cmsDataIntro?.length && this.cmsDataIntro[0].body_v2 != "" ? this.decodeHTML(this.cmsDataIntro?.length && this.cmsDataIntro[0].body_v2 ? this.cmsDataIntro[0].body_v2 : "") : "";
    //this.isEditClicked.intro = false;
    this.updateIntro();
  }

  editHowToUseApi1() {
    this.isEditClicked.howtouse1 = !this.isEditClicked.howtouse1;
    this.isEditClicked.newhowtouse1 = this.cmsHowToUse[0].heading != "" ? this.decodeHTML(this.cmsHowToUse[0].heading) : "";
  }

  cancelHowToUseApi1() {
    this.isEditClicked.newhowtouse1 = "";
    this.isEditClicked.howtouse1 = false;
  }

  restoreHowToUseApi1() {
    this.isEditClicked.newhowtouse1 = this.cmsHowToUse[0].heading_v2 != "" ? this.decodeHTML(this.cmsHowToUse[0].heading_v2) : "";
    this.isEditClicked.howtouse1 = false;
    this.updatehowtouse1();
  }

  editHowToUseApi2() {
    this.isEditClicked.howtouse2 = !this.isEditClicked.howtouse2;
    this.isEditClicked.newhowtouse2 = this.cmsHowToUse[1].heading != "" ? this.decodeHTML(this.cmsHowToUse[1].heading) : "";
  }

  cancelHowToUseApi2() {
    this.isEditClicked.newhowtouse2 = "";
    this.isEditClicked.howtouse2 = false;
  }
  restoreHowToUseApi2() {
    this.isEditClicked.newhowtouse2 = this.cmsHowToUse[1].heading_v2 != "" ? this.decodeHTML(this.cmsHowToUse[1].heading_v2) : "";
    this.isEditClicked.howtouse2 = false;
    this.updatehowtouse2();
  }

  editHowToUseApi3() {
    this.isEditClicked.howtouse3 = !this.isEditClicked.howtouse3;
    this.isEditClicked.newhowtouse3 = this.cmsHowToUse[1].body != "" ? this.decodeHTML(this.cmsHowToUse[1].body) : "";
  }

  cancelHowToUseApi3() {
    this.isEditClicked.newhowtouse3 = "";
    this.isEditClicked.howtouse3 = false;
  }
  restoreHowToUseApi3() {
    this.isEditClicked.newhowtouse3 = this.cmsHowToUse[1].body_v2 != "" ? this.decodeHTML(this.cmsHowToUse[1].body_v2) : "";
    this.isEditClicked.howtouse3 = false;
    this.updatehowtouse3();
  }

  editHowToUseApi4() {
    this.isEditClicked.howtouse4 = !this.isEditClicked.howtouse4;
    this.isEditClicked.newhowtouse4 = this.cmsHowToUse[0].body != "" ? this.decodeHTML(this.cmsHowToUse[0].body) : "";
  }

  cancelHowToUseApi4() {
    this.isEditClicked.newhowtouse4 = "";
    this.isEditClicked.howtouse4 = false;
  }
  restoreHowToUseApi4() {
    this.isEditClicked.newhowtouse4 = this.cmsHowToUse[0].body_v2 != "" ? this.decodeHTML(this.cmsHowToUse[0].body_v2) : "";
    this.isEditClicked.howtouse4 = false;
    this.updatehowtouse4();
  }
  editHowToUseApi5() {
    this.isEditClicked.howtouse5 = !this.isEditClicked.howtouse5;
    this.isEditClicked.newhowtouse5 = this.cmsHowToUse[0].title != "" ? this.decodeHTML(this.cmsHowToUse[0].title) : "";
  }

  cancelHowToUseApi5() {
    this.isEditClicked.newhowtouse5 = "";
    this.isEditClicked.howtouse5 = false;
  }
  restoreHowToUseApi5() {
    this.isEditClicked.newhowtouse5 = this.cmsHowToUse[0].title_v2 != "" ? this.decodeHTML(this.cmsHowToUse[0].title_v2) : "";
    this.isEditClicked.howtouse5 = false;
    this.updatehowtouse5();
  }
  // sanitize(value) {
  //   return this.sanitizer.bypassSecurityTrustHtml(value);
  // }

  decodeHTML(value) {
    let res = value.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    return res;
  }

  encodeHTML(value) {
    return value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");
  }

  sanitize(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  updateIntro() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }
    var str = this.encodeHTML(this.isEditClicked.newIntro);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsDataIntro[0]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', b64);
    formData.append('id', this.cmsDataIntro[0].id);
    formData.append('heading', this.cmsDataIntro[0].heading);
    formData.append('title', this.cmsDataIntro[0].title);
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.intro = false;
          this.successFlag = true;
          this.alertMsg = "Introduction Updated Successfully.";
         // this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }

  updatehowtouse1() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }

    var str = this.encodeHTML(this.isEditClicked.newhowtouse1);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsHowToUse[0]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].body))));
    formData.append('id', this.cmsHowToUse[0].id);
    formData.append('heading', b64);
    formData.append('title', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].title))));
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.howtouse1 = false;
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
          this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }

  updatehowtouse2() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }

    var str = this.encodeHTML(this.isEditClicked.newhowtouse2);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsHowToUse[1]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].body))));
    formData.append('id', this.cmsHowToUse[1].id);
    formData.append('heading', b64);
    formData.append('title', this.cmsHowToUse[1].title);
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.howtouse2 = false;
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
          this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }

  updatehowtouse3() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }
  
    var str = this.encodeHTML(this.isEditClicked.newhowtouse3);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsHowToUse[1]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', b64);
    formData.append('id', this.cmsHowToUse[1].id);
    formData.append('heading', btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].heading))));
    formData.append('title', this.cmsHowToUse[1].title);
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.howtouse3 = false;
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
          this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }

  updatehowtouse4() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }
    var str = this.encodeHTML(this.isEditClicked.newhowtouse4);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsHowToUse[0]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', b64);
    formData.append('id', this.cmsHowToUse[0].id);
    formData.append('heading', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].heading))));
    formData.append('title', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].title))));
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.howtouse4 = false;
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
          this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }
  
  updatehowtouse5() {

    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    // var obj = {
    //   action:"editContent",
    //   body:this.isEditClicked.newIntro,
    //   id:this.cmsDataIntro[0].id,
    //   wwid: userInfo.tokenUserInfo.nickname
    // }
    var str = this.encodeHTML(this.isEditClicked.newhowtouse5);
    var b64 = btoa(unescape(encodeURIComponent(str)));
    console.log("b64,",this.cmsHowToUse[0]);
    var formData = new FormData(); 
    formData.append('action', "editContent");
    formData.append('body', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].body))));
    formData.append('id', this.cmsHowToUse[0].id);
    formData.append('heading', btoa(unescape(encodeURIComponent(this.cmsHowToUse[0].heading))));
    formData.append('title',b64);
    formData.append('wwid', userInfo.tokenUserInfo.nickname);

    this.loader.show();
    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {

      if (result.Message == "Updated successfully") {

        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.isEditClicked.howtouse5 = false;
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
          this.loader.hide();
        })
      } 
      else {
        this.errorFlag = true;
        this.alertMsg = "Something went wrong.";
        this.loader.hide();
      }
    });
  }
  closeAlert() {
    this.successFlag = false;
    this.errorFlag = false;
    this.alertMsg = "";
    this.alert.nativeElement.classList.remove('show');
  }

  onClickEditImg(type) {
    //window.scrollTo({ top: 0, left: 0 });
    let ref = this.dialog.open(EditmediapopComponent, {
      autoFocus: false,
      panelClass: 'code-snippet-cont',
      width: '60vw',
      height: 'auto',
      data: {
        media: (type == 'video') ? this.cmsDataIntro[0] : this.cmsDatagetting_started_use_apis[0],
        type: type
      },
      disableClose: true
    });

    //edit images associated with this API
    ref.afterClosed().subscribe(res => {
      if (res) {
        if (res == "error") {
          this.alertMsg = "Some error occured! Please try again.";
          this.errorFlag = true;
          this.loader.hide();
        }
        else if (res == "success") {

          this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
            this.initCMSData();
            if (type == 'video') {
              this.video.nativeElement.load();
            }
            if(type=="image"){
              this.alertMsg = "Image updated succesfully";
            }
            if(type=="video"){
              this.alertMsg = "Video updated succesfully";
            }
            
            this.successFlag = true;
            //this.loader.hide();
          })

        }
      }
    });
  }

  edituseapiportal(){
    
    const dialogRef = this.dialog.open(EdituseapiportalpopComponent,{
      panelClass: 'custom-modalbox-usecase',
      data :this.cmsSteps,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result == "success")
      {
        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
         // this.loader.hide();
        })
      }else{
        this.loader.hide();
      }
    });
  }

  errorResEdit(i){
    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    const dialogRef = this.dialog.open(UseapitablepopComponent,{
      panelClass: 'custom-modalbox-usecase',
      data :{table:this.tableData,
        index:i,
        type:"edit",
        body: btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].body))),
        heading:btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].heading))),
        id: this.cmsHowToUse[1].id,
        title: this.cmsHowToUse[1].title,
        wwid: userInfo.tokenUserInfo.nickname
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result == "success")
      {
        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
         // this.loader.hide();
        })
      }else{
        this.loader.hide();
      }
    });
  }
  errorResAdd(i){
    let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
    const dialogRef = this.dialog.open(UseapitablepopComponent,{
      panelClass: 'custom-modalbox-usecase',
      data :{table:this.tableData,
        index:i,
        type:"add",
        body: btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].body))),
        heading:btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].heading))),
        id: this.cmsHowToUse[1].id,
        title: this.cmsHowToUse[1].title,
        wwid: userInfo.tokenUserInfo.nickname
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result == "success")
      {
        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
          this.initCMSData();
          this.successFlag = true;
          this.alertMsg = "Updated Successfully.";
         // this.loader.hide();
        })
      }else{
        this.loader.hide();
      }
    });
  }
  errorResDelete(i){
  //   if(this.tableData.length==1){
  //      alert("Table requires more then one data to delete.");
  //      return;
  //   }
  //   if (confirm("Are you sure! You want to delete?") == true) {
  //    // text = "You pressed OK!";
  //    //this.errresponse[this.data.index].error_code = this.apiinput.code
  //   // this.errresponse[this.data.index].message = this.apiinput.message
  //   // this.errresponse[this.data.index].error_response = this.apiinput.response
  //   let delarr = this.tableData;
  //    delarr.splice(i, 1);
  //    console.log("this.tableData",this.tableData);
  //    let title = JSON.stringify(delarr)
  //    let formData = new FormData(); 
  //    formData.append('action', "editContent");
  //    formData.append('body', btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].body))));
  //    formData.append('id', this.cmsHowToUse[1].id,);
  //    formData.append('heading', btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].heading))));
  //    formData.append('title', title);
  //    formData.append('wwid',  this.cmsHowToUse[1].wwid);
  //    this.loader.show();
  //    this.cmsService.updateContentIntroGettingStarted(formData).subscribe(result => {
  //       console.log("result....",result)
  //      if (result.Message == "Updated successfully") {
  //        console.log("success...");
  //        this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
  //         this.initCMSData();
  //         this.successFlag = true;
  //         this.alertMsg = "Updated Successfully.";
  //        // this.loader.hide();
  //       })
  //      } 
  //      else {
  //       console.log("error...");
  //       this.loader.hide();
  //      }
  //    });

  //   } else {
  //    // text = "You canceled!";
  //   }
  let userInfo = JSON.parse(localStorage.getItem("sessionUserInfo"));
  const dialogRef = this.dialog.open(DeletePopComponent,{
    data :{table:this.tableData,
      index:i,
      type:"add",
      body: btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].body))),
      heading:btoa(unescape(encodeURIComponent(this.cmsHowToUse[1].heading))),
      id: this.cmsHowToUse[1].id,
      title: this.cmsHowToUse[1].title,
      wwid: userInfo.tokenUserInfo.nickname
    },
    disableClose: true
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    if(result == "success")
    {
      this.cmsService.getCMSDataByScreenName('gettingstarted').then(()=>{
        this.initCMSData();
        this.successFlag = true;
        this.alertMsg = "Updated Successfully.";
       // this.loader.hide();
      })
    }else{
      this.loader.hide();
    }
  });

   }
   

}

