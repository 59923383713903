<div class="custom-container {{themeValue}}">
  <div class="wapper">
    <div class="row pt-3">
      <div class="col-md-12">
        <span class="list-desc">
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="breadcrumb-list">
                <a class="breadcrumb-item" [routerLink]="['/web/home']">Home</a>
                <div class="breadcrumb-connector">></div>
                <div class="breadcrumb-last">Search</div>
              </div>
            </div>
            <div class="col-md-6 text-right">
              <!-- <button  class="btn btn-outline-dark">Add New Additional Information</button> -->
            </div>

          </div>

        </span>
      </div>

    </div>
    <!-- hasresult -->
    <div class="row" *ngIf="globalSearchResult.length>0;else globalElse">
      <div class="col-md-3">
        <div class="catsearch-title">
          <div>Search</div>
        </div>
        <div class="serch-cat">
          <ng-container *ngFor="let data of globalSearchResult">
            <div class="cat-menu" (click)="selectCat(data)"
              [ngClass]="(selectedObj?.category==data.category)?'serch-cat-active':''">{{data.category}}
              ({{data.data.length}})</div>
          </ng-container>
          <!-- <div class="cat-menu serch-cat-active">API's (20)</div>
            <div class="cat-menu ">API's Usecases (20)</div>
            <div class="cat-menu ">Partner Showcases (20)</div> -->
        </div>
      </div>
      <div class="col-md-9">
        <div class="result-title">{{selectedObj?.data.length}} results for "{{globalsearchtxt}}"</div>
        <div class="sr-result">
          <ng-container *ngIf="selectedObj?.data.length>0;else noresult">
            <ng-container
              *ngFor="let item of selectedObj.data | paginate: { itemsPerPage: 10, currentPage: p };let i = index">
              <div class="result-card">

                <!-- api usecase -->
                <ng-container *ngIf="item.section=='api_usecase';else elsec">
                  <a style="cursor: pointer;" (click)="rdirectResult(item.section,item.data)" routerLink="/web/api-usecases/details"
                    [queryParams]="{ v: 3 }" class="r1" [innerHTML]="item.title"></a>
                </ng-container>

                <!-- api list and others -->
                <ng-template #elsec>
                  <a style="cursor: pointer;" routerLink="/{{item.routerlink}}"
                    [queryParams]="item.query?item.query:''" class="r1" [innerHTML]="item.title"></a>
                </ng-template>

                <!-- <div class="r2">
                  <ng-container *ngIf="item.section=='api_usecase';else elsec">
                    <a style="cursor: pointer;" (click)="rdirectResult(item.section,item.data)" >{{item.routerlink}}</a>
                  </ng-container>
                  <ng-template #elsec>
                    <a routerLink="/{{item.routerlink}}" [queryParams]="item.query?item.query:''" >{{item.routerlink}}</a>
                  </ng-template>
                </div> -->

                <div class="r3" [innerHTML]="sanitize(item.description)">
                </div>
              </div>
              <ng-container *ngIf="item.length != i + 1">
                <hr>
              </ng-container>
            </ng-container>
            <pagination-controls (pageChange)="p = $event" class="float-right"></pagination-controls>
          </ng-container>
          <ng-template #noresult>
            <ng-container *ngIf="isApiListEmpty">
              <div class="text-center mont-bold py-2">
                <svg-icon [svgStyle]="{ 'width.px':150}" class="no-data-found" src="assets/images/no-data-found.svg">
                </svg-icon>
                <br>
                <b class="noresult-title">No Results found</b>
                <br>
              </div>
            </ng-container>
          </ng-template>


          <!-- <div class="result-card">
            <div class="r1">New API Release</div>
            <div class="r2">
              <a href="">https://link</a>
            </div>
            <div class="r3">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee ypesetting industry. Lorem Ipsum has bee Lorem Ipsum has bee
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- has no result -->
    <ng-template #globalElse>
      <div class="row" *ngIf="isApiListEmpty">
        <div class="col-md-12">
          <div class="text-center mont-bold py-5">
            <svg-icon [svgStyle]="{ 'width.px':150}" class="no-data-found" src="assets/images/no-data-found.svg">
            </svg-icon>
            <ng-container *ngIf="!isforcelogin">
              <br>
              <b class="noresult-title">{{noresults}}</b>
              <br><br>
            </ng-container>
            <ng-container *ngIf="isforcelogin">
              <br>
              <b class="noresult-title">You are not logged in</b>
              <br><br>
              <b class="noresult-title">Please </b><a class="loginlink" (click)="forcelogin()">log in</a> <b
                class="noresult-title"> to view the search results.</b>
              <!-- <a class="loginlink" (click)="forcelogin()">Login</a>&nbsp;<b class="noresult-title mt-3">to get a hands on to your favourite API's and their services</b> -->
              <br>
              <!-- <button class="btn cumminsRed"  (click)="forcelogin()">Login</button> -->

              <br>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>