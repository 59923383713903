<div class="{{themeValue}}">
<!-- START STEP1 -->
<div *ngIf="action=='step1'">


  <div [hidden]="data.desc!='I have a question'" class="supportContainer">
    <div class="row">

      <div class="col-9">
        <div class="row">
          <div class="circle">1</div>&nbsp;&nbsp;&nbsp;
          <h3 style="font-weight: bold;padding-top: 1%;" class="title-h3">
            Ask a question
          </h3>
        </div>
      </div>

      <div class="col-3">
        <div class="cancel-div text-right">
          <mat-icon (click)="close()">cancel</mat-icon>
        </div>
      </div>

    </div>
    <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
      <mat-label>Category *</mat-label>
      <mat-select  (selectionChange)="switchCat()" [(ngModel)]="related">
        <mat-option value="Portal related">Portal related</mat-option>
        <mat-option value="API related">API related</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field *ngIf="showApiList" appearance="outline" style="width: 100%; margin-top: 1%;">
      <mat-label>API Name *</mat-label>
      <mat-select [(ngModel)]="related">
        <ng-container *ngFor="let data of apiList;">
            <mat-option value="{{data.api_name}}">{{data.api_name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field *ngIf="showApiList" appearance="outline" style="width: 100%; margin-top: 1%;">
        <mat-label>API Name *</mat-label>
        <input type="text" matInput [formControl]="myControl" [(ngModel)]="apiname" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.api_name">
            {{option.api_name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
          <mat-label>Question type *</mat-label>
          <mat-select  (selectionChange)="switchQuesType()" [(ngModel)]="questype">
            <mat-option value="ask_question">Send Email</mat-option>
            <mat-option value="technical_support">Submit ticket</mat-option>
          </mat-select>
        </mat-form-field>


    <mat-form-field appearance="outline" style="width: 100%; margin-top: 1%;">
      <mat-label>Enter summary *</mat-label>
      <input matInput [(ngModel)]="supportModel.Title" maxlength="100">
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Enter description *</mat-label>
      <textarea matInput [(ngModel)]="supportModel.Description" rows="4" cols="50" maxlength="300"
        style="resize: none;">
      </textarea>
    </mat-form-field>
    <p  class="notmsg">File should be within 2MB, Only accepts .png .jpg .pdf .doc .xlsx .txt</p>
    <mat-form-field appearance="outline" class="imgwap" style="width: 100%;">
      <label class="upload-label" (click)="file.click()" >{{attachmentFile?attachmentFile.name:'Attach File'}}</label>
      <input  matInput  (click)="file.click()">
      <input #file  (change)="uploadfile($event)" style="display: none;"  type="file" class="w-100" >
      <mat-icon (click)="file.click()" matSuffix class="upicn">file_upload</mat-icon>
    </mat-form-field>
    <p *ngIf="this.errmss!=''" class="errmsg">{{this.errmss}}</p>
    <!-- <app-captcha (onValidCaptcha)="isCaptchaValidFun($event)"></app-captcha> -->
    <div>
      <span style="float:right; margin-right: 10px">
        <button class="cuspopbtn" style="margin-top: 12px;width: 110%;"
          [disabled]="supportModel.Description === '' ||
           supportModel.Title === '' ||
            related === '' ||
            (related == 'API related' && apiname=='')
            " 
            class="btn next-btn"
          (click)="goNext(action3)">
          Next
        </button>
      </span> &nbsp;&nbsp;
      <span style="float: right; margin-right:16px;">
        <button style="margin-top: 12px;" class="btn btn-xs mr-1 borderClass cuspopbtn" (click)="onClear(action3)">
          Clear
        </button>
      </span>
    </div>
  </div>
</div>
<!-- END STEP1 -->

<!-- START STEP2 -->
<div *ngIf="action=='step2'">


  <div *ngIf="subAction=='technical_support'" class="reviewContainer">

    <div class="row">

      <div class="col-9">
        <div class="row">
          <div class="circle">2</div>&nbsp;&nbsp;&nbsp;
          <h3 style="font-weight: bold; padding-top: 1%;" class="title-h3">
            Review and Submit
          </h3>
        </div>
      </div>

      <div class="col-3">
        <div class="cancel-div text-right">
          <mat-icon (click)="close()">cancel</mat-icon>
        </div>
      </div>

    </div>
    <br>
    <div  class="row" style="padding-top: 10px;">
      <div class="col-4">
        <strong style="font-size: 14px;">Related support</strong>
      </div>
      <div class="col-8">
        <span style="font-size: 12px;">{{related}}</span>
      </div>
    </div>
    <div  *ngIf="apiname!=''" class="row" style="padding-top: 10px;">
        <div class="col-4">
          <strong style="font-size: 14px;">API Name</strong>
        </div>
        <div class="col-8">
          <span style="font-size: 12px;">{{apiname}}</span>
        </div>
      </div>
    
    <div class="row" style="padding-top: 10px;">
      <div class="col-4">
        <strong style="font-size: 14px;">Summary</strong>
      </div>
      <div class="col-8">
        <span style="font-size: 12px;">{{supportModel.Title}}</span>
      </div>
    </div>

    <div class="row" style="padding-top: 10px;">
      <div class="col-4">
        <strong style="font-size: 14px;">Description</strong>
      </div>
      <div class="col-8">
        <span style="font-size: 12px;">{{supportModel.Description}}</span>
      </div>
    </div>

    <div *ngIf="attachmentFile && attachmentFile.name" class="row" style="padding-top: 10px;">
        <div class="col-4">
          <strong style="font-size: 14px;">File name</strong>
        </div>
        <div class="col-8">
          <span style="font-size: 12px;">{{attachmentFile.name}}</span>
        </div>
      </div>

    <div class="row" style="margin-top:20px">
      <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12">
        <div *ngIf="cumminsLoader" class="cumminsLoader"><img src="assets/images/cum-loader.gif" width="120"></div>
      </div>
      <div class="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12">
        <span class="buttonalign" style="float:right;margin-right:10px;">
          <button style="margin-top:12px;width: 110%;" class="btn next-btn" (click)="reviewSubmit()">
            Submit</button>
        </span>
        <span class="buttonalign back-btn" style="float:right;margin-right:30px;">
          <button style="margin-top:12px;width: 110%" class="btn btn-xs mr-1 borderClass cuspopbtn"
            (click)="goBack('I have a question')">
            Back</button></span>
      </div>
    </div>

  </div>
</div>
<!-- END STEP2 -->

<div *ngIf="data.action=='new-faq' || data.action=='edit-faq'" class="newFaqCont">

  <div class="flex-disp">
    <div class="disp-title" *ngIf="data.action=='new-faq'">Add New FAQ</div>
    <div class="disp-title" *ngIf="data.action=='edit-faq'">Edit FAQ</div>
    <mat-icon (click)="close()" class="closeBtn">cancel</mat-icon>
  </div>

  <br>
  <div *ngIf="data.action=='edit-faq' && this.data.faq.heading_v2" class="default-cont">
      <p class="bolderText">Previous Content</p>
      <!-- <p *ngIf="apiObj.source=='mulesoft'">{{apiObj.apiDescription}}</p> -->
      <div><b>Question: </b> <p>{{this.data.faq.heading_v2?this.data.faq.heading_v2:''}}</p></div>
      <div><b>Category:</b> <p>{{this.data.faq.title_v2?this.data.faq.title_v2:''}}</p></div>
      <div><b>Description:</b> <p>{{this.data.faq.body_v2?this.data.faq.body_v2:''}}</p></div>
    </div>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Enter a Question</mat-label>
    <input matInput [(ngModel)]="quest.quest">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Select a Question category</mat-label>

    <mat-select name="role" [(ngModel)]="quest.type">
      <mat-option *ngFor="let int of questionOptions" [value]="int">
        {{int}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Enter the description</mat-label>
    <textarea matInput required rows="7" style="resize: none;" maxlength="250" [(ngModel)]="quest.desc"></textarea>
  </mat-form-field>
  <!-- <app-captcha (onValidCaptcha)="isCaptchaValidFun($event)"></app-captcha> -->
  <p style="color:#DA291C" *ngIf="errmsg!=''">{{errmsg}}</p>
  <div class="row">
    <div class="col-lg-12 text-center my-2 pb-1">
      <div *ngIf="cumminsLoader"><img src="assets/images/cum-loader.gif" width="120"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="btn-wrapper">
        <button class="btn cumminsWhite popbtn" (click)="clearFaq()" *ngIf="data.action=='new-faq'">
          Clear
        </button>

        <button class="btn cumminsWhite popbtn"  (click)="deleteFaq()" *ngIf="data.action=='edit-faq'">
          Delete
        </button>
        <button *ngIf="data.action=='edit-faq' && this.data.faq.heading_v2" class="popbtn cancel-edit-btn btn" (click)="restoreFaq()" >Restore previous content</button>
        <button class="btn cumminsRed popbtn" [disabled]="checkFaq()" (click)="submitFaq()">
          <span *ngIf="data.action=='new-faq'"> Create </span>
          <span *ngIf="data.action=='edit-faq'"> Save </span>
        </button>
      </div>
    </div>

  </div>

</div>


<div *ngIf="data.action=='delete-faq'" class="newFaqCont">

  <div class="disp-title">
    Are you sure want to delete this Item from FAQs ?
  </div>

  <br>

  <div class="btn-wrapper">
    <button class="btn cumminsWhite" (click)="close()">
      Cancel
    </button>

    <button class="btn cumminsRed" (click)="deleteFaqwc()">
      OK
    </button>
  </div>

</div>
</div>