import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../http/login.service';
import { StorageService } from '../../../core/services/storage.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  themeValue;

  constructor(private loginService: LoginService, private router: Router, private storageService: StorageService,
              private pageheader: PageHeaderComponent, private dataShare: DataShareService) {

    const url = window.location.href;
    const codeIdx = url.indexOf('code=');
    if (codeIdx !== -1) {
      console.log("constructor login")


      const fragment = url.substr(codeIdx);
      const fragmentsMap = this.extractKeyValueBasedOnDelimiter(fragment);
      // tslint:disable-next-line: no-string-literal
      const code = fragmentsMap['code'];

      this.loginService.fetchTokensForCode(code).subscribe({
        next: (tokens) => {
          this.loginService.authorize(tokens);
          
        },
        error: (err) => {
          this.router.navigate(['/unauthorized']);
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.loginService.isAuthenticated() == true) { 
      this.router.navigate(['/web/home']);
    }
    this.dataShare.getTheme().subscribe((res:any)=>{
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });
  }

  extractKeyValueBasedOnDelimiter = (str, delimiter = '&') => {
    const map = {};
    if (str && str != null) {
      const fragArray = str.split(delimiter);
      for (const frag of fragArray) {
        const arr = frag.split('=');
        map[arr[0]] = arr[1];
      }
    }
    return map;
  }

  forcelogin() {
    this.pageheader.doLogin();
  }
}
