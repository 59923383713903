import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginService } from 'src/app/account/http/login.service';
import { DataShareService } from 'src/app/core/services/data-share.service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  currentYear
  externalUser:boolean = false;
  islogin:boolean = false;
  isSupplierPortal = false;
  user;
  themeValue;
  constructor( private login: LoginService,
    private dataShare: DataShareService) {}

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();

    this.dataShare.getTheme().subscribe((res:any)=>{
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
      (window.location.href).includes('supplier') ? this.isSupplierPortal = true : this.isSupplierPortal = false;

    });

    if (this.login.isAuthenticated()) {
      this.user = JSON.parse(localStorage.getItem("sessionUserInfo"));
      //this.user = this.login.getUserInfo();
      if(this.user.tokenUserInfo['nickname'].includes("@")){
        this.externalUser= true;
       // console.log("external userinside if  ",this.externalUser)
      }
      this.islogin = true;
    }
  }

}
