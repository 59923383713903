import { inject } from '@angular/core/testing';
import { TokenUserInfo } from './TokenUserInfo';

export class Tokens {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: string;
    tokenUserInfo: TokenUserInfo;

    constructor(tokens: {access_token: string, id_token: string, refresh_token: string, expires_in: number }) {
        //debugger;
        this.accessToken = tokens.access_token;
        this.idToken = tokens.id_token;
        this.refreshToken = tokens.refresh_token;
        this.expiresIn = tokens.expires_in.toString();

        //localStorage.setItem("accessToken",this.accessToken );
        localStorage.setItem("idToken",this.idToken );
        localStorage.setItem("refreshToken",this.refreshToken );
        localStorage.setItem("expiresIn", this.expiresIn );
        //localStorage.setItem("loggedIn", "true" );
       // window.location.href = "web/api"
        
    }

    setTokenUserInfo(tokenUserInfo: TokenUserInfo) {
        this.tokenUserInfo = tokenUserInfo;
        
    }

    getTokenUserInfo() {
        return this.tokenUserInfo;
2    }

    resetUserToken(): void{
        
        //localStorage.setItem("accessToken",null );
        localStorage.setItem("idToken", null );
        localStorage.setItem("refreshToken",null );
        localStorage.setItem("expiresIn", null );
        //localStorage.setItem("loggedIn", null);
    }
}
