<div class="login-pop-cont">

    <div class="top-cont">
        <div class="logoName">
            <span>
                <!-- <img src="assets/images/cummins-black-logo.svg" alt="cummins-logo" class="logo-header"> -->
                <img src="assets/images/{{(themeValue=='dark-theme')?'cummins-logo.png':'cummins-logo-light.png'}}" alt="cummins-logo" class="logo-header">
            </span>
            <span class="app-title">{{portalName}}</span>
        </div>

        <mat-icon (click)="close()" class="closeBtn">cancel</mat-icon>
    </div>

    <hr>

    <div class="container-fluid">
        <div class="row login-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 log-row">

                <svg-icon src="assets/images/Login 1.svg"></svg-icon>

                <div *ngIf="portalName == 'API Portal'" class="app-title" >Non-Cummins Users</div>
                <div *ngIf="portalName == 'Supplier EDI Onboarding'" class="app-title">Non-Cummins/Intercompany User</div><br>

                <div *ngIf="portalName == 'API Portal'">Non Cummins Users need to Register to {{portalName}} before logging in</div>
                <div  *ngIf="portalName == 'Supplier EDI Onboarding'">Non-Cummins/Intercompany User need to Register to {{portalName}} before logging in</div>
                
                
                
                <br>

                <button *ngIf="portalName == 'API Portal'"  class="btn cumminsWhite" (click)="externalSignIn()">Sign In as Non Cummins User</button>
                <button *ngIf="portalName == 'Supplier EDI Onboarding'" class="btn cumminsWhite" (click)="externalSignIn()">Sign In as Non-Cummins/Intercompany User</button>
                <br><br>

                <div class="register-link" (click)="register()">Register / Create new Account</div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 log-row">

                <svg-icon src="assets/images/loginpopupillustration.svg"></svg-icon>

                <div class="app-title">Cummins Users</div><br>

                <div *ngIf="portalName == 'API Portal'">All Cummins Users by default have access to the {{portalName}} and need not register</div>
                <div *ngIf="portalName == 'Supplier EDI Onboarding'">Cummins Users need to contact Admin to Register to Supplier EDI Onboarding before logging in</div>

                <br>

                <button class="btn cumminsRed" (click)="onClickSignIn()">Sign In As Cummins User</button>

            </div>
        </div>
    </div>

</div>