import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './account/components/login/login.component';
import { ShellComponent } from './main/shell.component';
import { PageFooterComponent } from './layout/page-footer/page-footer.component';
import { ProgressSpinnerComponent } from './core/components/progress-spinner/progress-spinner.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './core/modules/material.module';
import { ApiInterceptor } from './core/interceptor/api.interceptor';
import { LogoutComponent } from './account/components/logout/logout.component';
import { MenuListItemComponent } from './core/components/menu-list-item/menu-list-item.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { NotificationComponent } from './main/settings/components/notification/notification.component';
import { RegisterUserComponent } from './account/components/register-user/register-user.component';
import { BrowserMessageComponent } from './core/components/browser-message/browser-message.component';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { CustomDatePipe } from './core/pipes/custom-date-pipe';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HtmlToTextPipe } from './core/pipes/html-to-text.pipe';
import { CMSService } from './core/cms/cms.service';
import { SettingsModule } from './main/settings/settings.module';
import { GettingStartedModule } from './main/getting-started/getting-started.module';
import { SkeletonComponent } from './core/components/skeleton/skeleton.component';
import { LoginPopupComponent } from './account/components/login-popup/login-popup.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { GlobalsearchComponent } from './main/globalsearch/globalsearch.component';
import { CookieService } from 'ngx-cookie-service';
import { AboutUsModule } from './main/about-us/about-us.module';
import { FormsModule } from '@angular/forms';
import { BlankComponent } from './core/components/blank/blank.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SessionPopComponent } from './layout/page-header/session-pop/session-pop.component';
import { GlobaldeletepopComponent } from './core/components/globaldeletepop/globaldeletepop.component';

@NgModule({
  declarations: [
    AppComponent,
    ProgressSpinnerComponent,
    PageHeaderComponent,
    PageFooterComponent,
    ShellComponent,
    LoginComponent,
    UnauthorizedComponent,
    LogoutComponent,
    MenuListItemComponent,
    NotFoundComponent,
    CustomDatePipe,
    RegisterUserComponent,
    BrowserMessageComponent,
    HtmlToTextPipe,
    LoginPopupComponent,
    //AboutUsComponent,
    GlobalsearchComponent,
    BlankComponent,
    SessionPopComponent,
    GlobaldeletepopComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    TranslocoRootModule,
    MatSnackBarModule,
    AngularSvgIconModule.forRoot(),
    MatTabsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled:  environment.production }),
    SettingsModule, 
    GettingStartedModule,
    AboutUsModule,
    NgxPaginationModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    NotificationComponent, SkeletonComponent,
    CMSService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

